import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { colors } from "../colors";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";
import { accountInfo, companyInfo, login } from "../auth";
import { Link } from "react-router-dom";
import {
    CurrentCompany,
    CurrentUser,
    CurrentTheme,
} from "../context/CurrentUser";
import { useHistory } from "react-router-dom";
import LanguageSelect from "../components/layout/Navbar/LanguageSelect";
import i18n from "../i18n";
import ThemeToggle from "../components/layout/Navbar/ThemeToggle";
import Input from "../components/Input";
import { api } from "../api";

export default function Login() {
    const userContext = useContext(CurrentUser);
    const themeContext = useContext(CurrentTheme);
    const companyContext = useContext(CurrentCompany);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);

    let history = useHistory();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (email && password) {
            await login(email, password)
                .then(async (response) => {
                    if (response.status === 200) {
                        localStorage.setItem(
                            "token",
                            response.data.data.access_token
                        );
                        await accountInfo()
                            .then(async (res) => {
                                userContext.setCurrentUser(res.data.data);
                                history.push("/dashboard");
                                if (
                                    localStorage.getItem("company_uuid") !== "" && localStorage.getItem('company_uuid') !== null
                                ) {
                                    await companyInfo(
                                        localStorage.getItem("company_uuid")
                                    )
                                        .then((response) => {
                                            if (response.status === 200) {
                                                companyContext.setCurrentCompany(
                                                    response.data.data
                                                );
                                            }
                                        })
                                        .catch((error) => {
                                            if (error.response.status === 403) {
                                                localStorage.removeItem("company_uuid");
                                                companyContext.setCurrentCompany(
                                                    null
                                                );
                                                history.push('/company/select')
                                            }
                                        });
                                }
                                else if(localStorage.getItem('company_uuid') === null) {
                                    api.get('/company').then(
                                        response => {
                                            if(response.status === 200) {
                                                if(response.data.data?.length === 1) {
                                                    localStorage.setItem(
                                                        "company_uuid",
                                                        response.data.data[0].uuid
                                                    );
                                                    companyContext.setCurrentCompany(
                                                        response.data.data[0]
                                                    );
                                                } else if(response.data.data?.length > 1) {
                                                    history.push('/company/select')
                                                } else {
                                                    localStorage.removeItem("company_uuid");
                                                    companyContext.setCurrentCompany(
                                                        null
                                                    );
                                                }
                                            }
                                        }
                                    ).catch(
                                        error => {
                                            console.error(error)
                                        }
                                    )
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                })
                .catch((error) => {
                    setErrors(error?.response?.data?.errors ?? []);
                });
        }
    };
    return (
        <Guest>
            <div className="flex flex-col items-center justify-between lg:w-1/4 w-full">
                <img
                    src={themeContext.theme == "dark" ? LogoDark : Logo}
                    className="mb-6 w-1/2"
                    alt="logo"
                />
                <div className="p-8 w-full shadow-lg dark:bg-authenticated-dark bg-white">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex md:flex-row flex-col justify-between items-center">
                            <Text
                                sx={{ fontWeight: 500, fontSize: 28 }}
                                className="dark:text-white text-secondary"
                                translate="Sign in"
                            />
                            <div className="flex flex-col md:items-end items-center">
                                <Text
                                    variant="body2"
                                    translate="Don't have an account?"
                                    className="dark:text-white"
                                />
                                <Link to="/register">
                                    <Text
                                        variant="body2"
                                        className="dark:text-white hover:opacity-90 text-secondary"
                                        translate="Sign up"
                                    />
                                </Link>
                            </div>
                        </div>
                        <form onSubmit={handleLogin}>
                            <div className="flex flex-col w-full gap-5 mt-10">
                                <Input
                                    name="email"
                                    label={i18n.t("Email")}
                                    variant="outlined"
                                    color="primary"
                                    className="autofill-dark"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={errors.email ?? false}
                                    helperText={errors.email ?? false}
                                />
                                <Input
                                    name="password"
                                    label={i18n.t("Password")}
                                    variant="outlined"
                                    type="password"
                                    color="primary"
                                    value={password}
                                    error={errors.password ?? false}
                                    helperText={errors.password ?? false}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <div className="flex md:flex-row flex-col gap-4 justify-between items-center mt-5">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        <Text translate="Sign in" />
                                    </Button>
                                    <Link to="/reset-password">
                                        <Text
                                            variant="body2"
                                            translate="Forgot password?"
                                            className="text-secondary dark:text-white hover:opacity-90"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-4 shadow-lg dark:bg-authenticated-dark">
                    <LanguageSelect color={ themeContext.theme == 'dark' ? "white" : "lightBlue"} disableShadow={true}/>
                    <ThemeToggle color={ themeContext.theme == 'dark' ? "white" : "lightBlue"} disableShadow={true}/>
                </div>
            </div>
        </Guest>
    );
}
