import React, { useContext, useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import { CurrentTheme } from "../../../context/CurrentUser";
import Input from "../../../components/Input";
import { api } from "../../../api";

export default function VmBreadcrumbs({ vm, getVm }) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [vmName, setVmName] = useState("");
    const [errors, setErrors] = useState([]);
    const themeContext = useContext(CurrentTheme);

    useEffect(() => {
        setVmName(vm.name_external ? vm.name_external : vm.name_internal);
    }, []);

    const handleChangeName = async () => {
        setErrors([]);
        await api
            .put(`/vm/${vm.uuid}`, { name_external: vmName })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t("Name changed successfully"));
                    setIsEditMode(false);
                    getVm();
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <div className="flex flex-row items-center">
            {isEditMode ? (
                <div className="flex gap-3">
                    <Input
                        name="name"
                        label={i18n.t("Name")}
                        variant="outlined"
                        type="text"
                        value={vmName}
                        color="primary"
                        onChange={(e) => setVmName(e.target.value)}
                        error={errors.name ?? false}
                        helperText={errors.name ?? false}
                    />
                    <button onClick={handleChangeName} type="submit">
                        <CheckIcon color="powerOn" />
                    </button>
                    <button onClick={() => setIsEditMode(false)}>
                        <ClearIcon color="powerOff" />
                    </button>
                </div>
            ) : (
                <>
                    <div className="text-md lg:text-2xl font-medium flex items-center dark:text-white">
                        <Link to="/vms">
                            <h2 className="p-1">{i18n.t("VPS")}</h2>
                        </Link>
                        <ChevronRight
                            color={
                                themeContext.theme == "dark"
                                    ? "gray"
                                    : "primary"
                            }
                        />
                        {vm.name_external ? (
                            <h2 className="p-1">{vm.name_external + ' (' + vm.name_internal + ')'}</h2>
                        ) : (
                            <h2 className="p-1">{vm.name_internal}</h2>
                        )}
                    </div>
                    <span
                        className="hover:opacity-90 text-md lg:text-lg pl-2 text-secondary dark:text-white flex items-center font-medium cursor-pointer"
                        onClick={() => setIsEditMode(true)}
                    >
                        <CreateIcon />
                        <span className="pl-1">{i18n.t("Change name")}</span>
                    </span>
                </>
            )}
        </div>
    );
}
