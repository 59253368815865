import React from 'react'
import Text from '../../components/Text'

export default function LoginLogListMobileItem({logon, columns}) {
    return (
        <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md rounded-md dark:bg-authenticated-dark dark:text-white font-medium">
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[0].text}/>
                </div>
                <div className="text-right mb-1">
                    {logon.date}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[1].text}/>
                </div>
                <div className="text-right mb-1">
                    {logon.device ?? '-'}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[2].text}/>
                </div>
                <div className="text-right mb-1">
                    {logon.ip}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[3].text}/>
                </div>
                <div className="text-right mb-1">
                    {logon.browser ?? '-'}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[4].text}/>
                </div>
                <div className="text-right mb-1">
                    {logon.version ?? '-'}
                </div>
            </div>
        </div>
    )
}
