import { DateTimePicker } from "@mui/lab";
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { api } from "../../../api";
import dayjs from "dayjs";
import { Loader } from "../../../components/Loader/Loader";
import Text from "../../../components/Text";
import i18n from "../../../i18n";
import formatBytes from "../../../utils/formatBytes";
import Card from "../../../components/Card";
import { useContext } from "react";
import { CurrentTheme } from "../../../context/CurrentUser";
import { colors } from "../../../colors";
import Input from "../../../components/Input";
import EventIcon from "@mui/icons-material/Event";
import { ArrowDropDown } from "@mui/icons-material";
import { withStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((themeContext) => ({
    icon: { color: "white" },
}));

export default function ServerNetworkStats({ server }) {
    const themeContext = useContext(CurrentTheme);
    const classes = useStyles(themeContext);

    const [dataUsage, setDataUsage] = useState({
        labels: [],
        datasets: [
            {
                label: i18n.t("Data in"),
                data: [],
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Data out"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 30, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Packets in"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 00, 00)",
                yAxisID: "B",
            },
            {
                label: i18n.t("Packets out"),
                data: [],
                fill: false,
                borderColor: "rgb(255, 00, 00)",
                yAxisID: "B",
            },
        ],
    });

    const [dataSpeeds, setDataSpeeds] = useState({
        labels: [],
        datasets: [
            {
                label: i18n.t("Speed in"),
                data: [],
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Speed out"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 30, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Speed packets in"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 00, 00)",
                yAxisID: "B",
            },
            {
                label: i18n.t("Speed packets out"),
                data: [],
                fill: false,
                borderColor: "rgb(255, 00, 00)",
                yAxisID: "B",
            },
        ],
    });

    const [labels, setLabels] = useState([]);
    const [bytesIn, setBytesIn] = useState([]);
    const [bytesOut, setBytesOut] = useState([]);
    const [packetsIn, setPacketsIn] = useState([]);
    const [packetsOut, setPacketsOut] = useState([]);

    const [speedbytesIn, setSpeedBytesIn] = useState([]);
    const [speedbytesOut, setSpeedBytesOut] = useState([]);
    const [speedpacketsIn, setSpeedPacketsIn] = useState([]);
    const [speedpacketsOut, setSpeedPacketsOut] = useState([]);

    const [dateFrom, setDateFrom] = useState(dayjs().subtract(1, "day"));
    const [dateTo, setDateTo] = useState(dayjs());
    const [interval, setInterval] = useState(60);
    const [loading, setLoading] = useState(false);

    const options = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            y: {
                beginAtZero: true,
                type: "logarithmic",
                ticks: {
                    callback: function (value, index) {
                        return formatBytes(parseInt(value));
                    },
                },
            },
            B: {
                type: "logarithmic",
                title: "B",
                beginAtZero: true,
                position: "right",
                ticks: {
                    callback: function (value, index) {
                        return value + " " + i18n.t("packets");
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let value = parseInt(tooltipItem.raw);
                        if (
                            tooltipItem.datasetIndex === 0 ||
                            tooltipItem.datasetIndex === 1
                        ) {
                            return `${formatBytes(value)} (${value} B)`;
                        } else {
                            return `${value} ${i18n.t('packets')}`;
                        }
                    },
                },
            },
        },
    };

    const optionsSpeed = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            y: {
                beginAtZero: true,
                type: "logarithmic",
                ticks: {
                    callback: function (value, index) {
                        return formatBytes(parseInt(value / 8)) + "/s";
                    },
                },
            },
            B: {
                type: "logarithmic",
                title: "B",
                beginAtZero: true,
                position: "right",
                ticks: {
                    callback: function (value, index) {
                        return value + " " + i18n.t("packets per second");
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let value = parseInt(tooltipItem.raw);
                        if (
                            tooltipItem.datasetIndex === 0 ||
                            tooltipItem.datasetIndex === 1
                        ) {
                            return `${formatBytes(value)}/s (${value} B/s)`;
                        } else {
                            return `${value} ${i18n.t("packets per second")}`;
                        }
                    },
                },
            },
        },
    };

    useEffect(() => {
        setDataUsage({
            labels: labels,
            datasets: [
                {
                    ...dataUsage.datasets[0],
                    data: bytesIn,
                },
                {
                    ...dataUsage.datasets[1],
                    data: bytesOut,
                },
                {
                    ...dataUsage.datasets[2],
                    data: packetsIn,
                },
                {
                    ...dataUsage.datasets[3],
                    data: packetsOut,
                },
            ],
        });
    }, [labels]);

    useEffect(() => {
        setDataSpeeds({
            labels: labels,
            datasets: [
                {
                    ...dataSpeeds.datasets[0],
                    data: speedbytesIn,
                },
                {
                    ...dataSpeeds.datasets[1],
                    data: speedbytesOut,
                },
                {
                    ...dataSpeeds.datasets[2],
                    data: speedpacketsIn,
                },
                {
                    ...dataSpeeds.datasets[3],
                    data: speedpacketsOut,
                },
            ],
        });
    }, [labels]);

    useEffect(() => {
        setLoading(true);
        api.get(`/server/${server.uuid}/network_stats`, {
            params: {
                date_from: dateFrom.format("YYYY-MM-DD HH:mm:ss"),
                date_to: dateTo.format("YYYY-MM-DD HH:mm:ss"),
                interval: interval,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    const { data } = response.data;
                    var firstElement = null;
                    if (data.length > 1) {
                        firstElement = data.shift();
                    }

                    //Network Usage
                    setBytesIn(
                        data.map((portValue) => parseInt(portValue.in / 8))
                    );
                    setBytesOut(
                        data.map((portValue) => parseInt(portValue.out / 8))
                    );
                    setPacketsIn(data.map((portValue) => portValue.packets_in));
                    setPacketsOut(
                        data.map((portValue) => portValue.packets_out)
                    );

                    //Network Speeds

                    if (firstElement) {
                        var lastInValue = firstElement.in;

                        setSpeedBytesIn(
                            data.map((portValue) => {
                                const speed =
                                    (portValue.in - lastInValue) /
                                    (interval * 60);

                                lastInValue = portValue.in;
                                return speed;
                            })
                        );

                        var lastOutValue = firstElement.out;
                        setSpeedBytesOut(
                            data.map((portValue) => {
                                const speed =
                                    (portValue.out - lastOutValue) /
                                    (interval * 60);
                                lastOutValue = portValue.out;
                                return speed;
                            })
                        );

                        var lastInPackets = firstElement.packets_in;
                        setSpeedPacketsIn(
                            data.map((portValue) => {
                                const speed =
                                    (portValue.packets_in - lastInPackets) /
                                    (interval * 60);
                                lastInPackets = parseInt(portValue.packets_in);
                                return parseInt(speed);
                            })
                        );

                        var lastOutPackets = firstElement.packets_out;
                        setSpeedPacketsOut(
                            data.map((portValue) => {
                                const speed =
                                    (portValue.packets_out - lastOutPackets) /
                                    (interval * 60);
                                lastOutPackets = parseInt(
                                    portValue.packets_out
                                );
                                return parseInt(speed);
                            })
                        );
                    }

                    // Labels
                    setLabels(
                        data.map((portValue) =>
                            dayjs(portValue.created_at).format(
                                "YYYY-MM-DD HH:mm"
                            )
                        )
                    );
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dateFrom, dateTo, interval]);

    return (
        <>
            <Card variant="small" title="Statistics">
                <div className="flex flex-row gap-3 p-4">
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="select-interval-label">
                            <p className="dark:text-white">
                                {i18n.t("Interval")}
                            </p>
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{
                                style: {
                                    color:
                                        themeContext.theme == "dark"
                                            ? colors.white
                                            : "",
                                },
                            }}
                            classes={{
                                icon:
                                    themeContext.theme == "dark"
                                        ? classes.icon
                                        : null,
                            }}
                            labelId="select-interval-label"
                            id="select-interval"
                            value={interval}
                            onChange={(e) => setInterval(e.target.value)}
                        >
                            <MenuItem value={5}>5 {i18n.t("minutes")}</MenuItem>
                            <MenuItem value={15}>15 {i18n.t("minutes")}</MenuItem>
                            <MenuItem value={60}>1 {i18n.t("hour")}</MenuItem>
                            <MenuItem value={180}>3 {i18n.t("hours")}</MenuItem>
                            <MenuItem value={720}>12 {i18n.t("hours")}</MenuItem>
                            <MenuItem value={1440}>24 {i18n.t("hours")}</MenuItem>
                        </Select>
                    </FormControl>
                    <DateTimePicker
                        components={{
                            OpenPickerIcon: () => {
                                return (
                                    <EventIcon className="text-primary dark:text-white" />
                                );
                            },
                        }}
                        renderInput={(props) => <Input {...props} />}
                        label={i18n.t("Date from")}
                        ampm={false}
                        value={dateFrom}
                        onChange={(newValue) => {
                            setDateFrom(newValue);
                        }}
                        maxDate={dateTo}
                    />
                    <DateTimePicker
                        components={{
                            OpenPickerIcon: () => {
                                return (
                                    <EventIcon className="text-primary dark:text-white" />
                                );
                            },
                        }}
                        renderInput={(props) => <Input {...props} />}
                        label={i18n.t("Date to")}
                        ampm={false}
                        value={dateTo}
                        onChange={(newValue) => {
                            setDateTo(newValue);
                        }}
                        minDate={dateFrom}
                    />
                </div>
                <div className="p-3 px-5 flex flex-col justify-between items-center w-100 h-auto">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="h-96 w-100 mb-10">
                            <Text
                                variant="h5"
                                className="text-secondary dark:text-gray-200"
                                translate="Network usage"
                            />
                            <Line data={dataUsage} options={options} />
                        </div>
                    )}
                </div>
                <div className="p-3 px-5 flex flex-col justify-between items-center w-100 h-auto">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="h-96 w-100 mb-10">
                            <Text
                                variant="h5"
                                className="text-secondary dark:text-gray-200"
                                translate="Network speeds"
                            />
                            <Line data={dataSpeeds} options={optionsSpeed} />
                        </div>
                    )}
                </div>
            </Card>
        </>
    );
}
