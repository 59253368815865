import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import SideBar from "./Sidebar/SideBar";

export default function Authenticated({ children, className, ...otherProps }) {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    return (
        <div className={className + ` w-full h-full flex flex-col bg-authenticated dark:bg-authenticated-dark`} {...otherProps}>
            <Navbar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible}/>
            <div className="flex h-full flex-row w-full">
                <SideBar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />
                <div
                    className="px-3 lg:px-10 py-5 w-full bg-background overflow-auto"
                    style={{ height: `calc(100vh - 80px)` }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}
