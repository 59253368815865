import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { api } from "../../api";
import Authenticated from "../../components/layout/Authenticated";
import ContractDocuments from "./components/ContractDocuments";
import ContractGeneralInformation from "./components/ContractGeneralInformation";
import ContractBreadcrumbs from "./components/ContractBreadcrumbs";
import ContractServices from "./components/ContractServices";
import ContractEntrustedPersonalData from "./components/ContractEntrustedPersonalData";

export default function ContractDetails() {
    const { contractUuid } = useParams();
    const [contract, setContract] = useState({});
    const [contractName, setContractName] = useState("");
    const [isFetching, setIsFetching] = useState(true);

    const getContract = async () => {
        await api
            .get(`/contract/${contractUuid}`)
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setContract(response.data.data);
                    setContractName(response.data.data.name);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getContract();
    }, []);

    return (
        <Authenticated>
            <ContractBreadcrumbs
                contract={contract}
                getContract={getContract}
                contractName={contractName}
                setContractName={setContractName}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div>
                    <ContractGeneralInformation contract={contract} />
                    <ContractDocuments contract={contract} />
                    <ContractEntrustedPersonalData contract={contract} />
                </div>
                <ContractServices contract={contract} />
            </div>
        </Authenticated>
    );
}
