import React, { useEffect, useState } from "react";
import SidebarItem from "./SidebarItem";
import StorageIcon from "@mui/icons-material/Storage";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import BackupIcon from "@mui/icons-material/Backup";
import DescriptionIcon from "@mui/icons-material/Description";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ChatIcon from "@mui/icons-material/Chat";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useContext } from "react";
import { CurrentTheme } from "../../../context/CurrentUser";

export default function SideBar({ isSidebarVisible, setIsSidebarVisible }) {
    useEffect(() => {}, [isSidebarVisible]);
    const themeContext = useContext(CurrentTheme);

    return (
        <div
            className={
                `h-full text-white md:pt-2 md:pl-4 w-72 ` +
                (isSidebarVisible ? `block absolute z-10 ` : `md:inline hidden `) + (themeContext.theme == 'dark' ? 'bg-sidebar-dark' : 'bg-sidebar')
            }
            style={{ height: `calc(100vh - 80px)` }}
        >
            <SidebarItem
                text="Dashboard"
                Icon={<DashboardIcon color="white" className="icon-shadow" />}
                url="/dashboard"
            />
            <SidebarItem
                text="Servers"
                Icon={<StorageIcon color="white" className="icon-shadow" />}
                url="/servers"
            />
            <SidebarItem
                text="Cloud.VPS"
                Icon={<CloudQueueIcon color="white" className="icon-shadow" />}
                url="/vps"
            />
            <SidebarItem
                text="BackupFTP"
                Icon={<BackupIcon color="white" className="icon-shadow" />}
                url="/backupftp"
            />
            <SidebarItem
                text="Invoices"
                Icon={<RequestPageIcon color="white" className="icon-shadow" />}
                url="/invoices"
            />
            <SidebarItem
                text="Contracts"
                Icon={<ContactPageIcon color="white" className="icon-shadow" />}
                url="/contracts"
            />
            <SidebarItem
                text="Messages"
                Icon={<ChatIcon color="white" className="icon-shadow" />}
                url="/messages"
            />
            <SidebarItem
                text="Login history"
                Icon={<VpnKeyIcon color="white" className="icon-shadow" />}
                url="/login-history"
            />
        </div>
    );
}
