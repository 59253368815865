import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../api";
import Input from "../../components/Input";
import Text from "../../components/Text";
import { CurrentUser } from "../../context/CurrentUser";
import i18n from "../../i18n";

export default function ChangePassword() {
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [current_password, setCurrentPassword] = useState("");
    const userContext = useContext(CurrentUser)

    const handleChangePassword = async (e) => {
        e.preventDefault();
        await api.post("/account/password/change", {
            current_password,
            password,
            password_confirmation,
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t('Password changed successfully'))
                    localStorage.removeItem("token");
                    userContext.setCurrentUser(null);
                }
                return <Redirect to="/"/>
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <form
            method="POST"
            onSubmit={handleChangePassword}
            className="flex flex-col w-full gap-5 mt-10"
        >
            <Input
                name="current_password"
                label={i18n.t("Current Password")}
                variant="outlined"
                type="password"
                value={current_password}
                color="primary"
                onChange={(e) => setCurrentPassword(e.target.value)}
                error={errors.current_password ?? false}
                helperText={errors.current_password ?? false}
            />
            <Input
                name="password"
                label={i18n.t("New Password")}
                variant="outlined"
                type="password"
                value={password}
                color="primary"
                onChange={(e) => setPassword(e.target.value)}
                error={errors.password ?? false}
                helperText={errors.password ?? false}
            />
            <Input
                name="password_confirmation"
                label={i18n.t("Confirm New Password")}
                variant="outlined"
                type="password"
                color="primary"
                value={password_confirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                error={errors.password_confirmation ?? false}
                helperText={errors.password_confirmation ?? false}
            />
            <div className="flex flex-row justify-between items-center mt-5">
                <Button color="primary" size="large" variant="contained" type="submit">
                    <Text translate="Change password" />
                </Button>
            </div>
        </form>
    );
}
