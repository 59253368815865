import { Button, Card, TextField } from "@mui/material";
import React, { useState } from "react";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { api } from "../api";
import i18n from "../i18n";
import Input from "../components/Input";
import { useContext } from "react";
import { CurrentTheme } from "../context/CurrentUser";
import LanguageSelect from "../components/layout/Navbar/LanguageSelect";
import ThemeToggle from "../components/layout/Navbar/ThemeToggle";
import { toast } from "react-toastify";

export default function PasswordReset() {
    const [email, setEmail] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [errors, setErrors] = useState([]);
    const themeContext = useContext(CurrentTheme)

    const handlePasswordReset = (e) => {
        e.preventDefault();
        api.post("/token/send/password_reset", {
            email,
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsSent(true);
                    toast.success(i18n.t('Password reset link has been sent to your email address'))
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };
    return (
        <Guest>
            <div className="flex flex-col items-center justify-between w-1/4">
                <img src={themeContext.theme == 'dark' ? LogoDark : Logo} className="mb-6 w-1/2" alt="logo" />
                <div className="p-8 w-full shadow-md dark:bg-authenticated-dark">
                    <div className="flex flex-col h-full justify-between">
                        {isSent ? (
                            <div className="flex flex-col items-center gap-6">
                                <CheckCircleOutlineIcon
                                    fontSize="large"
                                    color={themeContext.theme == 'dark' ? 'white' : "primary"}
                                />
                                <Text
                                    variant="body2"
                                    translate="Further instructions have been sent to the email address provided"
                                    textAlign="center"
                                    className="dark:text-gray-200"
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<ChevronLeftIcon />}
                                >
                                    <Link to="/">{i18n.t('Go back')}</Link>
                                </Button>
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-row justify-between">
                                    <Text
                                        sx={{ fontWeight: 500, fontSize: 28 }}
                                        className="text-secondary dark:text-white"
                                        translate="Forgot password?"
                                    />
                                </div>
                                <form onSubmit={handlePasswordReset}>
                                    <div className="flex flex-col w-full gap-5 mt-10">
                                        <Text className="dark:text-white" translate="Enter the email address with which you created the account. You will get instructions on it that will allow you to set a new password" />
                                        <Input
                                            name="email"
                                            label={i18n.t('Email')}
                                            variant="outlined"
                                            color="primary"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            error={errors.email ? true : false}
                                            helperText={errors.email}
                                        />
                                        <div className="flex flex-row justify-between items-center mt-5">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                
                                                <Text translate="Send instructions" /> 
                                            </Button>
                                            <Link to="/">
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    startIcon={<ChevronLeftIcon />}
                                                >
                                                    {i18n.t('Go back')}
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
                <div className="mt-4 shadow-lg dark:bg-authenticated-dark">
                    <LanguageSelect color="lightBlue"/>
                    <ThemeToggle color="lightBlue"/>
                </div>
            </div>
        </Guest>
    );
}
