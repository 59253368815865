import React, { useEffect } from "react";
import { toast } from "react-toastify";
import i18n from "../i18n";

export default function CookieConsent() {
    const toastId = "toast-cookie-consent";

    const notify = () => {
        toast(
            i18n.t(
                "We are using cookies to better your experience with our site, secure users and adapting it to the individual needs of users. By continuing to browse without changing the current settings, you agree to receive all cookies on the website. Remember that you can always change these settings using your internet program."
            ),
            {
                toastId: toastId,
                autoClose: false,
                onClose: () => {
                    localStorage.setItem("cookie_consent", true);
                },
            }
        );
    };

    const dismiss = () => toast.dismiss(toastId);

    const dismissAll = () => toast.dismiss();

    useEffect(() => {
        if (!localStorage.getItem("cookie_consent") === true) {
            notify();
        }
    }, []);
    return <div></div>;
}
