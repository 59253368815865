import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { colors } from "../colors";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";
import { Link, useHistory } from "react-router-dom";
import { api } from "../api";
import i18n from "../i18n";
import Input from "../components/Input";
import { CurrentTheme } from "../context/CurrentUser";
import LanguageSelect from "../components/layout/Navbar/LanguageSelect";
import ThemeToggle from "../components/layout/Navbar/ThemeToggle";
import { toast } from "react-toastify";

export default function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [dialCodes, setDialCodes] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [code, setCode] = useState("+48");
    const [terms, setTerms] = useState(false);
    const [information, setInformation] = useState(false);
    const [errors, setErrors] = useState([]);
    const themeContext = useContext(CurrentTheme);
    const history = useHistory();

    useEffect(() => {
        api.get("/dial-code", {
            params: {
                supported: true,
            },
        })
            .then((response) => {
                setDialCodes(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleRegister = (e) => {
        e.preventDefault();
        const phone = code + " " + phoneNumber;
        const rodo = terms & information;
        api.post("/account/register", {
            email,
            password,
            password_confirmation,
            phone,
            rodo,
        })
            .then((response) => {
                if (response.status === 201) {
                    toast.success(i18n.t("User added"));
                    history.push("/");
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <Guest>
            <div className="flex flex-col items-center justify-between 2xl:w-1/4">
                <img
                    src={themeContext.theme == "dark" ? LogoDark : Logo}
                    className="mb-6 w-1/2"
                    alt="logo"
                />
                <div className="p-8 w-full shadow-lg dark:bg-authenticated-dark bg-white">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex flex-row justify-between">
                            <Text
                                sx={{ fontWeight: 500, fontSize: 28 }}
                                className="text-secondary dark:text-white"
                                translate="Sign up"
                            />
                            <div className="flex flex-col items-end">
                                <Text
                                    translate="Already have an account?"
                                    variant="body2"
                                    className="dark:text-white"
                                />
                                <Link to="/">
                                    <Text
                                        variant="body2"
                                        className="text-secondary dark:text-white hover:opacity-90"
                                        translate="Sign in"
                                    />
                                </Link>
                            </div>
                        </div>
                        <form
                            method="POST"
                            onSubmit={handleRegister}
                            className="flex flex-col w-full gap-5 mt-10"
                        >
                            <Input
                                name="email"
                                label={i18n.t("Email")}
                                variant="outlined"
                                value={email}
                                color="primary"
                                onChange={(e) => setEmail(e.target.value)}
                                error={errors.email ?? false}
                                helperText={errors.email ?? false}
                            />
                            <Input
                                name="password"
                                label={i18n.t("Password")}
                                variant="outlined"
                                type="password"
                                value={password}
                                color="primary"
                                onChange={(e) => setPassword(e.target.value)}
                                error={errors.password ?? false}
                                helperText={errors.password ?? false}
                            />
                            <Input
                                name="password_confirmation"
                                label={i18n.t("Confirm password")}
                                variant="outlined"
                                type="password"
                                color="primary"
                                value={password_confirmation}
                                onChange={(e) =>
                                    setPasswordConfirmation(e.target.value)
                                }
                                error={errors.password_confirmation ?? false}
                                helperText={
                                    errors.password_confirmation ?? false
                                }
                            />
                            <div className="flex justify-between flex-row gap-3">
                                <Input
                                    name="phoneCode"
                                    variant="outlined"
                                    value={code}
                                    select
                                    color="primary"
                                    onChange={(e) => setCode(e.target.value)}
                                    className="w-1/3"
                                >
                                    {dialCodes.map((dialCode) => (
                                        <MenuItem
                                            key={dialCode.code}
                                            value={dialCode.dial_code}
                                        >
                                            {dialCode.code +
                                                " " +
                                                dialCode.dial_code}
                                        </MenuItem>
                                    ))}
                                </Input>
                                <Input
                                    name="phoneNumber"
                                    label={i18n.t("Phone")}
                                    variant="outlined"
                                    color="primary"
                                    className="w-2/3"
                                    value={phoneNumber}
                                    onChange={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                    error={errors.phone ?? false}
                                    helperText={errors.phone ?? false}
                                />
                            </div>
                            <div>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={terms}
                                                onChange={() =>
                                                    setTerms(!terms)
                                                }
                                            />
                                        }
                                        label={
                                            <div className="dark:text-gray-200">
                                                <span>
                                                    {i18n.t("I accept the")}{" "}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    href={
                                                        "https://api.dataspace.pl/regulation/www"
                                                    }
                                                    className="text-secondary dark:text-white hover:opacity-90"
                                                >
                                                    {i18n.t("terms of use")}
                                                </a>
                                                <span>
                                                    {" "}
                                                    {i18n.t("from site")}
                                                </span>
                                            </div>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={information}
                                                onChange={() =>
                                                    setInformation(!information)
                                                }
                                            />
                                        }
                                        label={
                                            <div className="dark:text-gray-200">
                                                <span>
                                                    {i18n.t(
                                                        "I declare that I have read"
                                                    )}{" "}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    href="https://api.dataspace.pl/rodo/information_obligation?mode=view"
                                                    className="text-secondary dark:text-white hover:opacity-90"
                                                >
                                                    {" "}
                                                    {i18n.t(
                                                        "information obligation"
                                                    )}
                                                </a>
                                                <span>
                                                    {" "}
                                                    {i18n.t(
                                                        "regarding the processing of personal data"
                                                    )}
                                                </span>
                                            </div>
                                        }
                                    />
                                </FormGroup>
                            </div>
                            <div className="flex flex-row justify-between items-center mt-5">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    <Text translate="Sign up" />
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-4 shadow-lg dark:bg-authenticated-dark">
                    <LanguageSelect color="lightBlue" />
                    <ThemeToggle color="lightBlue" />
                </div>
            </div>
        </Guest>
    );
}
