import React from "react";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import {Link} from 'react-router-dom'
import Text from "../../components/Text";

export default function VmListItem({ vm }) {
    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {vm.name_external ? (vm.name_external + ' (' + vm.name_internal + ')') : vm.name_internal}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                {vm.power_status === "running" && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        <Text translate="Power on" variant="h8"/>
                    </span>
                )}
                {vm.power_status === "unknown" && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                        <Text translate="Power unknown" variant="h8"/>
                    </span>
                )}
                {(vm.power_status != "running" && vm.power_status != "unknown") && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        <Text translate="Power off" variant="h8"/>
                    </span>
                )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                    <Link
                        to={'contracts/'+vm.contract_uuid}
                        className="text-lightBlue font-medium hover:opacity-90 dark:text-white "
                    >
                            <Text translate="Contract details" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <Link to={'/vps/'+vm.uuid} className="text-lightBlue dark:text-white hover:opacity-90">
                    <Text translate="Details" variant="h7"/>
                    <ChevronRight />
                </Link>
            </td>
        </>
    );
}
