import React from 'react'
import Card from '../../../components/Card'
import BackupFTPAccount from './BackupFTPAccount'

export default function BackupFTPAccounts({ftpAccounts, shellAccounts}) {
    return (
        <div className="grid grid-cols-1">
            <Card variant="small" title="FTP Accounts">
                {ftpAccounts.map(account => {
                    return <BackupFTPAccount key={account.username} username={account.username} password={account.password}/>
                })}
            </Card>
            <Card variant="small" title="Shell Accounts">
                {shellAccounts.map(account => {
                    return <BackupFTPAccount key={account.username} username={account.username} password={account.password}/>
                })}
            </Card>
        </div>
    )
}
