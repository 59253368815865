import React from "react";
import Text from "../../components/Text";
import {
    ChevronRight,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function InvoiceListMobileItem({ invoice, columns }) {
    const handlePay = () => {
        api.get(`/payment/${invoice.access_token}`)
            .then((response) => {
                if (response.status === 200) {
                    window.location.href = response.data.redirect;
                }
            })
            .catch((errors) => console.error(errors));
    };
    return (
        <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md rounded-md dark:bg-authenticated-dark dark:text-white font-medium">
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[0].text} />
                </div>
                <div className="text-right mb-1">
                    <Link
                        to={{
                            pathname:
                                process.env.REACT_APP_API_URL +
                                "/invoice/" +
                                invoice.uuid +
                                "/download",
                        }}
                        target="_blank"
                    >
                        <div className="text-sm font-medium text-secondary dark:text-white">
                            {invoice.number}
                        </div>
                    </Link>
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[1].text} />
                </div>
                <div className="text-right mb-1">
                    {invoice.type == 1 && "Proforma"}
                    {invoice.type == 2 && "VAT"}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[2].text} />
                </div>
                <div className="text-right mb-1">
                    {parseFloat(invoice.brutto).toFixed(2)}{" "}
                    {invoice.currency.short_currency}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[3].text} />
                </div>
                <div className="text-right mb-1">
                    {invoice.period_start} - {invoice.period_end}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[4].text} />
                </div>
                <div className="text-right mb-1">{invoice.issue_date}</div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[5].text} />
                </div>
                <div className="text-right mb-1">{invoice.contract_name}</div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[6].text} />
                </div>
                <div className="text-right mb-1">
                    <Link
                        to={"/contracts/" + invoice.contract_uuid}
                        className="text-secondary dark:text-white"
                    >
                        <Text translate="Details" variant="h7" />
                        <ChevronRight />
                    </Link>
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center">
                <div className="text-right w-full mb-1">
                    {invoice.status == 2 && (
                        <div
                            onClick={handlePay}
                            className="text-secondary dark:text-white cursor-pointer mt-5"
                        >
                            <Text translate="Pay" variant="h7" />
                            <ChevronRight />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
