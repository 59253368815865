import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import Authenticated from "../../components/layout/Authenticated";
import { api } from "../../api";
import Pagination from "../../components/Pagination/Pagination";
import Text from "../../components/Text";
import MessageListItem from "./MessageListItem";
import { Button } from "@mui/material";
import TableSearch from '../../components/TableSearch';
import i18n from "../../i18n";
import MessageListMobileItem from "./MessageListMobileItem";
import Card from "../../components/Card";

export default function MessageList() {
    const [messages, setMessages] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [meta, setMeta] = useState([]);
    const [page, setPage] = useState(1);

    const getMessages = async () => {
        setIsFetching(true)
        await api
            .get("message", {
                params: {
                    page: page,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setMessages(response.data.data);
                    setMeta(response.data.meta);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    //pagination
    useEffect(() => {
        getMessages();
    }, [page]);

    const columns = [
        { field: "title", text: "Message title" },
        { field: "status", text: "Ticket status" },
        { field: "ticketNumber", text: "Ticket number" },
        { field: "details", text: "Details" }
    ];
    
    return (
        <Authenticated>
            <div>
                <div className="text-2xl font-medium flex items-center dark:text-white">
                    <Link to="/messages">
                        <h2 className="p-1">{i18n.t('Messages')}</h2>
                    </Link>
                </div>
                <Card>
                    <div className="flex flex-row justify-start lg:justify-end mb-5">
                        <Link to="/messages/create">
                            <Button color="primary" variant="contained">
                                {i18n.t('Create new message')}
                            </Button>
                        </Link>
                    </div>
                    <Table
                        isFetching={isFetching}
                        columns={columns}
                        rows={messages}
                        keyName="TicketNumber"
                        renderItem={(item) => {
                            return <MessageListItem message={item} />;
                        }}
                        renderMobileItem={(item) => {
                            return <MessageListMobileItem columns={columns} message={item} />;
                        }}
                    />
                    <Pagination meta={meta} setPage={setPage} />
                </Card>
            </div>
        </Authenticated>
    );
}
