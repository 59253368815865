import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../../api";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import Text from "../../../components/Text";
import i18n from "../../../i18n";
import ContractEntrustedPersonalDataFormRow from "./ContractEntrustedPersonalDataFormRow";

export default function ContractEntrustedPersonalDataForm({
    contract,
    isOpen,
    setIsOpen,
}) {
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [newCategory, setNewCategory] = useState("");
    const [categories, setCategories] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let isValid = true;
        Object.entries(categories).forEach(([key, value]) => {
            if (value.length === 0) {
                isValid = false;
            }
        });

        setButtonDisabled(!isValid || Object.keys(categories).length == 0);
    }, [categories]);

    const handleSendEntrustedData = () => {
        api.post(`/contract/${contract.uuid}/rodo`, { options: categories })
            .then((response) => {
                if (response.status === 200) {
                    setIsOpen(false);
                    toast.success(i18n.t("Entrusted data saved"));
                } else {
                    toast.error(i18n.t("Error saving entrusted data"));
                }
            })
            .catch((error) => {
                if(error.response.status === 409) {
                    toast.error(i18n.t("You have active entrusted data contract in place! Contact bok@dataspace.pl to resolve this issue."));
                }
                setErrors(error?.response?.data?.errors ?? []);
                console.error(error);
            });
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="dark:bg-gray-800 bg-gray-50 p-4">
                <Text
                    className="dark:text-gray-200 text-secondary"
                    variant="h5"
                    translate="Entrust Personal Data"
                />

                <div className="flex flex-col gap-3">
                    <div className="w-100 flex flex-col p-4">
                        {Object.entries(categories).map(
                            ([category, entries]) => {
                                return (
                                    <ContractEntrustedPersonalDataFormRow
                                        key={category}
                                        setCategories={setCategories}
                                        categories={categories}
                                        category={category}
                                        entries={entries}
                                    />
                                );
                            }
                        )}
                    </div>

                    <Input
                        className="w-full"
                        label={i18n.t("New category like: Workers or Clients")}
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <Button
                        onClick={() => {
                            if (newCategory.length > 0) {
                                setCategories({
                                    ...categories,
                                    [newCategory]: [],
                                });
                                setNewCategory("");
                                setButtonDisabled(false);
                            }
                        }}
                    >
                        {i18n.t("Add category")}
                    </Button>

                    <Button
                        disabled={buttonDisabled}
                        onClick={() => handleSendEntrustedData()}
                    >
                        {i18n.t("Send")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
