import { Button } from "@mui/material";
import React, { useState } from "react";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../api";
import i18n from "../i18n";
import Input from "../components/Input";
import { useContext } from "react";
import { CurrentTheme } from "../context/CurrentUser";
import LanguageSelect from "../components/layout/Navbar/LanguageSelect";
import ThemeToggle from "../components/layout/Navbar/ThemeToggle";
import { toast } from "react-toastify";

export default function PasswordSet() {
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const { token } = useParams();
    const [errors, setErrors] = useState([]);
    const history = useHistory();
    const themeContext = useContext(CurrentTheme)

    const handlePasswordSet = (e) => {
        e.preventDefault();
        api.post("/account/password/reset", {
            password, password_confirmation: passwordConfirmation, token
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t('New password has been set'));
                    history.push('/');
                }
            })
            .catch((error) => {
                if(error?.response?.data?.errors?.token) {
                    toast.error(error.response.data.errors.token[0]);
                }
                setErrors(error?.response?.data?.errors ?? []);
            });
    };
    return (
        <Guest>
            <div className="flex flex-col items-center justify-between w-1/4">
                <img src={themeContext.theme == 'dark' ? LogoDark : Logo} className="mb-6 w-1/2" alt="logo" />
                <div className="p-8 w-full shadow-md dark:bg-authenticated-dark">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex flex-row justify-between">
                            <Text
                                sx={{ fontWeight: 500, fontSize: 28 }}
                                className="text-secondary dark:text-white"
                                translate="New password"
                            />
                        </div>
                        <form onSubmit={handlePasswordSet}>
                            <div className="flex flex-col w-full gap-5 mt-10">
                                <Input
                                    name="newPassword"
                                    label={i18n.t('Password')}
                                    variant="outlined"
                                    type="password"
                                    color="primary"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <Input
                                    name="password_confirmation"
                                    label={i18n.t('Password Confirmation')}
                                    variant="outlined"
                                    type="password"
                                    color="primary"
                                    value={passwordConfirmation}
                                    onChange={(e) =>
                                        setPasswordConfirmation(e.target.value)
                                    }
                                    error={errors.password ? true : false}
                                    helperText={errors.password}
                                />
                                <div className="flex flex-row justify-between items-center mt-5">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                    >
                                        
                                        <Text translate="Change" /> 
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-4 shadow-lg dark:bg-authenticated-dark">
                    <LanguageSelect color="lightBlue"/>
                    <ThemeToggle color="lightBlue"/>
                </div>
            </div>
        </Guest>
    );
}
