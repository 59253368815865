import React from "react";
import i18n from "../i18n";
import Text from "./Text";

export default function Card({
    className,
    children,
    variant,
    title,
    icon,
    ...otherProps
}) {
    return variant == "small" ? (
        <div
            className={
                "mt-5 rounded-lg shadow-md bg-white dark:bg-navbarDark " +
                className
            }
            data-testid="small-card"
            {...otherProps}
        >
            {title && (
                <div className="flex justify-between items-center w-100 pr-5">
                    <Text
                        variant="h7"
                        className="text-gray-400 p-3 px-5"
                        translate={title}
                    />
                    {icon}
                </div>
            )}
            <div className="px-3 py-5 flex flex-col gap-3 overflow-x-hidden">
                {children}
            </div>
        </div>
    ) : (
        <div
            className={
                "p-5 lg:p-8 shadow-md mt-5 bg-white dark:bg-navbarDark " +
                className
            }
            data-testid="card"
            {...otherProps}
        >
            {title && (
                <h2 className="p-1 text-2xl font-medium dark:text-gray-200 mb-5">
                    {i18n.t(title)}
                </h2>
            )}
            {children}
        </div>
    );
}
