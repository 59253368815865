import React from 'react'
import _ from 'lodash'
import './Pagination.css'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import i18n from '../../i18n'

export default function Pagination({meta, setPage}) {

    if(!meta.total) {
        return null;
    }

    const current_page = meta.current_page
    const first_page = 1
    const last_page = meta.last_page

    const handleClick = page => {
        setPage(page)
    }

    return (
        <div className="flex flex-row justify-end items-center mt-8">
            <ul className="flex flex-row justify-between gap-3 pagination">
                { //prev button
                    current_page > first_page && 
                    <li className="prev" onClick={() => handleClick(current_page - 1)}>
                        <ChevronLeft/>{i18n.t('Prev')}
                    </li>
                }
                { //first page show (1)
                    (first_page !== last_page) && 
                    (first_page !== current_page) && 
                    <li onClick={() => handleClick(1)}>{first_page}</li>
                }
                { //current page +1 and -1 
                    first_page !== last_page && 
                    <>
                        {current_page - 1 > first_page && 
                            <li onClick={() => handleClick(current_page-1)}>{current_page - 1}</li>
                        }

                        <li className="active">{current_page}</li>
                        
                        {current_page + 1 < last_page && 
                            <li onClick={() => handleClick(current_page+1)}>{current_page + 1}</li>
                        }
                    </>
                }
                { // three dots
                    (first_page !== last_page) && 
                    (current_page !== last_page) && 
                    (current_page < last_page - 2) && 
                    <li className="dots">...</li>
                }
                { //last page show
                    (first_page !== last_page) && 
                    (last_page !== current_page) && 
                    <li onClick={() => handleClick(last_page)}>{last_page}</li>
                }
                { //next button
                    current_page < last_page && 
                    <li className="next" onClick={() => handleClick(current_page + 1)}>{i18n.t('Next')}<ChevronRight/></li>
                }
            </ul>
        </div>
    )
}
