import React, { useEffect, useState } from "react";
import { api } from "../../../api";
import Text from "../../../components/Text";
import DownloadIcon from "@mui/icons-material/Download";
import { Loader } from "../../../components/Loader/Loader";
import Download from "../../../components/Download";
import Card from "../../../components/Card";
import InnerCard from "../../../components/InnerCard";

export default function ContractDocuments({ contract }) {
    const [documents, setDocuments] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [meta, setMeta] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const getDocuments = async () => {
        await api
            .get(`/contract/${contract.uuid}/document`, {
                params: {
                    search: search,
                    page: page,
                    limit: 10,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setDocuments(response.data.data);
                    setMeta(response.data.meta);
                    setIsFetching(false);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    useEffect(() => {
        if (contract.uuid) {
            getDocuments();
        }
    }, [contract]);

    return (
        <Card variant="small" title="Contract documents">
            <div className="flex flex-col gap-3">
                {isFetching ? (
                    <Loader />
                ) : (
                    <InnerCard>
                        {documents.length == 0 && (
                            <Text
                                align="center"
                                className="dark:text-white"
                                translate="No records"
                            />
                        )}
                        {documents.map((row) => {
                            return (
                                <div
                                    key={row.uuid}
                                    className="flex flex-col text-gray-400 dark:text-gray-200 gap-3"
                                >
                                    <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200">
                                        <Text className="w-1/3">
                                            {row.name}
                                        </Text>
                                        <Download
                                            className="flex text-lightBlue dark:text-white hover:opacity-90"
                                            url={
                                                "/contract/" +
                                                contract.uuid +
                                                "/document/" +
                                                row.uuid +
                                                "/download"
                                            }
                                        >
                                            <DownloadIcon />
                                            <Text translate="Download" />
                                        </Download>
                                    </div>
                                </div>
                            );
                        })}
                    </InnerCard>
                )}
            </div>
        </Card>
    );
}
