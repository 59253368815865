import React, { useEffect, useState } from "react";
import { api } from "../../../api";
import Card from "../../../components/Card";
import InnerCard from "../../../components/InnerCard";
import { Loader } from "../../../components/Loader/Loader";
import Text from "../../../components/Text";
import ContractServiceItem from "./ContractServiceItem";

export default function ContractServices({ contract }) {
    const [contractServices, setContractServices] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [meta, setMeta] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const getContractServices = async () => {
        await api
            .get(`/contract/${contract.uuid}/service`, {
                params: {
                    search: search,
                    page: page,
                    limit: 10,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setContractServices(response.data.data);
                    setMeta(response.data.meta);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    useEffect(() => {
        if (contract.uuid) {
            getContractServices();
        }
    }, [contract]);

    return (
        <Card variant="small" title="Contract services">
            {isFetching ? (
                <Loader />
            ) : (
                <div className="flex flex-col gap-3">
                    <InnerCard>
                        {contractServices.length == 0 && (
                            <Text
                                align="center"
                                className="dark:text-white"
                                translate="No records"
                            />
                        )}
                        {contractServices.map((contractService, index) => {
                            return (
                                <ContractServiceItem
                                    key={
                                        contractService.uuid
                                            ? contractService.uuid
                                            : contractService.name
                                    }
                                    contractService={contractService}
                                />
                            );
                        })}
                    </InnerCard>
                </div>
            )}
        </Card>
    );
}
