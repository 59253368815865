import React from "react";
import Text from "../../components/Text";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function ContractListMobileItem({contract, columns}) {
    return (
        <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md rounded-md dark:bg-authenticated-dark dark:text-white font-medium">
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[0].text}/>
                </div>
                <div className="text-right mb-1">
                    {contract.name}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[1].text}/>
                </div>
                <div className="text-right mb-1">
                    {contract.agreement_end}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[2].text}/>
                </div>
                <div className="text-right mb-1">
                    {contract.settlement_type == 1 && <Text translate="Daily" />}
                    {contract.settlement_type == 2 && <Text translate="Monthly" />}
                    {contract.settlement_type == 3 && <Text translate="Quaterly" />}
                    {contract.settlement_type == 4 && <Text translate="Semiannually" />}
                    {contract.settlement_type == 5 && <Text translate="Yearly" />}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[3].text}/>
                </div>
                <div className="text-right mb-1">
                    {contract.status == 1 && 
                        <div className="flex flex-row">
                            <LoopIcon color="primary"/>
                            <Text className="pl-2" translate="In progress" />
                        </div>
                    }
                    {contract.status == 2 && 
                        <div className="flex flex-row">
                            <CheckCircleIcon color="warning" />
                            <Text className="pl-2" translate="Payment required" />
                        </div>
                    }
                    {contract.status == 3 && 
                        <div className="flex flex-row">
                            <CheckCircleIcon color="powerOn" />
                            <Text className="pl-2" translate="Active" />
                        </div>
                    }
                    {contract.status == 4 && 
                        <div className="flex flex-row">
                            <CheckCircleIcon color="disabled" />
                            <Text className="pl-2" translate="Inactive" />
                        </div>
                    }
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[4].text}/>
                </div>
                <div className="text-right mb-1">
                    <Link to={'/contracts/'+contract.uuid} className="text-lightBlue dark:text-white">
                        <Text translate="Details" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </div>
        </div>
    )
}
