import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api } from "../api";
import Authenticated from "../components/layout/Authenticated";
import { Loader } from "../components/Loader/Loader";
import Table from "../components/Table";
import i18n from "../i18n";
import InvoiceListItem from "../modules/invoices/InvoiceListItem";
import WarningIcon from "@mui/icons-material/Warning";
import InvoiceListMobileItem from "../modules/invoices/InvoiceListMobileItem";
import { useContext } from "react";
import { CurrentTheme } from "../context/CurrentUser";
import Card from "../components/Card";

export default function Dashboard() {
    const [invoices, setInvoices] = useState([]);
    const [messages, setMessages] = useState([]);
    const [isFetching, setIsFetching] = useState([]);
    const themeContext = useContext(CurrentTheme);

    const getInvoices = async () => {
        setIsFetching(true);
        await api
            .get("/invoice", {
                params: {
                    limit: 10,
                    order: "desc",
                    order_by: "period_start",
                    paid: false,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setInvoices(response.data.data);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    const getMaintenanceMessages = async () => {
        setIsFetching(true);
        await api
            .get("/maintenance-messages")
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setMessages(response.data.data);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    const columns = [
        { field: "invoice", text: "Invoice" },
        { field: "type", text: "Type" },
        { field: "brutto", text: "Amount" },
        { field: "period_start", text: "Period" },
        { field: "issue_date", text: "Issue date" },
        { field: "contract_name", text: "Contract" },
        { field: "details", text: "Contract details" },
    ];

    useEffect(() => {
        getInvoices();
        getMaintenanceMessages();
    }, []);

    return (
        <Authenticated>
            <div>
                <div className="text-2xl font-medium flex items-center">
                    <Link to="/dashboard">
                        <h2 className="p-1 dark:text-gray-200">
                            {i18n.t("Dashboard")}
                        </h2>
                    </Link>
                </div>
                {messages.length > 0 && (
                    <Card title="Maintenance messages">
                        {isFetching ? (
                            <Loader />
                        ) : messages.length ? (
                            messages.map((message) => {
                                return (
                                    <div className="bg-authenticated dark:bg-authenticated-dark my-2 p-5 flex flex-col gap-3 rounded-md font-medium dark:text-gray-200">
                                        <div className="flex items-center gap-3">
                                            <WarningIcon
                                                color={
                                                    themeContext.theme == "dark"
                                                        ? "white"
                                                        : "primary"
                                                }
                                            />
                                            {message.title}
                                        </div>
                                        <div>{message.date_start}</div>
                                        {message.duration  != 0 && <div>
                                            {i18n.t("Duration") +
                                                ": " +
                                                message.duration +
                                                "min"}
                                        </div>}
                                        <div>{message.message}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="flex justify-center">
                                {i18n.t("No messages to show")}
                            </div>
                        )}
                    </Card>
                )}
                <Card title="Payments">
                    {isFetching ? (
                        <Loader />
                    ) : (
                        <Table
                            isFetching={isFetching}
                            columns={columns}
                            rows={invoices}
                            keyName="uuid"
                            renderItem={(item) => (
                                <InvoiceListItem invoice={item} />
                            )}
                            renderMobileItem={(item) => (
                                <InvoiceListMobileItem
                                    columns={columns}
                                    invoice={item}
                                />
                            )}
                            noDataText="All invoices have been paid!"
                        />
                    )}
                </Card>
            </div>
        </Authenticated>
    );
}
