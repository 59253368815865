import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../api";
import Authenticated from "../../../components/layout/Authenticated";
import { Loader } from "../../../components/Loader/Loader";
import Text from "../../../components/Text";
import Message from "./Message";
import MessageBreadcrumbs from "./MessageBreadcrumbs";
import MessageAnswer from "./MessageAnswer";
import Card from "../../../components/Card";

export default function MessageDetails() {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const getTicket = async () => {
    await api
      .get(`/message/${ticketId}`, { params: { limit: 1000 } })
      .then((response) => {
        if (response.status === 200) {
          setIsFetching(false);
          setTicket(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getTicket();
  }, []);

  return (
    <Authenticated>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <MessageBreadcrumbs message={ticket} />
          <div className="grid grid-cols-1">
            <Card variant="small" title="Create message" className="lg:w-1/2">
              <MessageAnswer TicketID={ticketId} getTicket={getTicket} />
            </Card>
            <Card variant="small" title="Messages">
              <div className="px-3 py-5 flex flex-col gap-5">
                {ticket.map((message, index) => (
                  <Message message={message} key={index} />
                ))}
              </div>
            </Card>
          </div>
        </>
      )}
    </Authenticated>
  );
}
