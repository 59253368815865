import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../api";
import Authenticated from "../../../components/layout/Authenticated";
import { Loader } from "../../../components/Loader/Loader";
import { useContext } from "react";
import { CurrentTheme } from "../../../context/CurrentUser";
import BackupFTPBreadcrumbs from "./BackupFTPBreadcrumbs";
import BackupFTPSpecification from "./BackupFTPSpecification";
import Card from "../../../components/Card";
import BackupFTPAccounts from "./BackupFTPAccounts";

export default function BackupFTPDetails() {
    const { backupftpUuid } = useParams();
    const [backupftp, setBackupftp] = useState({});
    const [ftpAccounts, setFtpAccounts] = useState([]);
    const [shellAccounts, setShellAccounts] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [serverName, setServerName] = useState('');
    const [ipv4, setIpv4] = useState([]);
    const [ipv6, setIpv6] = useState([]);
    const themeContext = useContext(CurrentTheme)

    const getServer = async () => {
        await api
            .get(`/backup-ftp/${backupftpUuid}`)
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setBackupftp(response.data.data);
                    setFtpAccounts(response.data.data.ftp_accounts);
                    setShellAccounts(response.data.data.shell_accounts);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        setIsFetching(false)
    };

    useEffect(() => {
        getServer();
    }, []);

    return (
        <Authenticated>
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <BackupFTPBreadcrumbs backupftp={backupftp}/>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                        <div>
                            <Card variant="small" title="Information">
                                <BackupFTPSpecification backupftp={backupftp}/>
                            </Card>
                        </div>
                        <BackupFTPAccounts ftpAccounts={ftpAccounts} shellAccounts={shellAccounts}/>
                    </div>
                </>
            )}
        </Authenticated>
    );
}
