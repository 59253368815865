import React from "react";
import Text from "../../components/Text";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function ServerListMobileItem({ server, columns }) {
    return (
        <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md rounded-md text-secondary dark:bg-authenticated-dark dark:text-white font-medium">
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[0].text}/>
                </div>
                <div className="text-right mb-1">
                    {server.name}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
            <div className="text-secondary dark:text-gray-200 mb-1">
                <Text translate={columns[1].text}/>
            </div>
            <div className="text-right mb-1">
                {server.power_status === "1" && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        <Text translate="Power on" variant="h8"/>
                    </span>
                )}
                {server.power_status === "0" && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        <Text translate="Power off" variant="h8"/>
                    </span>
                )}
                {server.power_status === "unknown" && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-600">
                        <Text translate="Unknown" variant="h8"/>
                    </span>
                )}
            </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[2].text}/>
                </div>
                <div className="text-right mb-1">
                    {server.remote ? (
                        <CheckCircleIcon color="powerOn"/>
                    ) : (
                        <CancelIcon color="powerOff" />
                    )}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[3].text}/>
                </div>
                <div className="text-right mb-1">
                    <Link
                        to={'contracts/'+server.contract_uuid}
                        className="text-secondary dark:text-white"
                    >
                            <Text translate="Contract" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[4].text}/>
                </div>
                <div className="text-right mb-1">
                    <Link to={'/servers/'+server.uuid} className="text-secondary dark:text-white">
                        <Text translate="Details" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </div>
        </div>
    );
}
