import React from 'react'
import { api } from '../../../../api'
import InnerCard from '../../../../components/InnerCard'
import Text from '../../../../components/Text'
import i18n from '../../../../i18n'

export default function VmRemoteAccess({vmUuid}) {

    const handleClick = () => {
        api.get(`/vm/${vmUuid}/link`).then(
            response => {
                window.open(response.data.link, "_blank").focus();
            }
        ).catch(
            error => {
                console.error(error)
            }
        )
    }

    return (
        <InnerCard>
            <div className="flex justify-between items-center">
                <Text
                    variant="h7"
                    className="font-medium dark:text-gray-200"
                    translate="Remote access"
                />
                <button className="text-white w-32 text-xs bg-secondary hover:opacity-90 px-5 py-2 rounded-full font-medium uppercase" onClick={handleClick}>
                    {i18n.t('Get access')}
                </button>
            </div>
        </InnerCard>
    )
}
