import React from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import Authenticated from "../components/layout/Authenticated";
import i18n from "../i18n";
import Change2FA from "../modules/account/Change2FA";
import ChangePassword from "../modules/account/ChangePassword";
import ChangePhone from "../modules/account/ChangePhone";

export default function Account() {
    return (
        <Authenticated>
            <div className="text-2xl font-medium flex items-center justify-center mt-5 mb-8">
                <h2 className="p-1 dark:text-white">
                    {i18n.t("Edit account")}
                </h2>
            </div>
            <div className="w-full flex justify-center items-center">
                <div className="w-full md:w-1/2 ">
                    <Card title="Verification method" className="rounded-lg">
                        <Change2FA />
                    </Card>
                    <Card title="Phone" className="rounded-lg">
                        <ChangePhone />
                    </Card>
                    <Card title="Password" className="rounded-lg">
                        <ChangePassword />
                    </Card>
                </div>
            </div>
        </Authenticated>
    );
}
