import React, { useEffect, useState } from "react";
import { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { api } from "../../api";
import Card from "../../components/Card";
import Authenticated from "../../components/layout/Authenticated";
import Pagination from "../../components/Pagination/Pagination";
import Table from "../../components/Table";
import TableSearch from "../../components/TableSearch";
import Text from "../../components/Text";
import i18n from "../../i18n";
import InvoiceListItem from "./InvoiceListItem";
import InvoiceListMobileItem from "./InvoiceListMobileItem";
import WarningIcon from "@mui/icons-material/Warning";
import { CurrentTheme } from "../../context/CurrentUser";

export default function InvoiceList() {
    const [invoices, setInvoices] = useState([]);
    const [search, setSearch] = useState("");
    const [isFetching, setIsFetching] = useState(true);
    const [meta, setMeta] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("period_start");
    const [order, setOrder] = useState("desc");
    const [paid, setPaid] = useState(false);
    const firstUpdate = useRef(true);
    const themeContext = useContext(CurrentTheme);

    const columns = [
        { field: "invoice", text: "Invoice", filterable: true, filter_name: "number" },
        { field: "type", text: "Type", filterable: true, filter_name: "type" },
        { field: "brutto", text: "Amount", filterable: true, filter_name: "brutto" },
        { field: "period_start", text: "Period", filterable: true, filter_name: "period_start" },
        { field: "issue_date", text: "Issue date", filterable: true, filter_name: "issue_date" },
        { field: "contract_name", text: "Contract" },
        { field: "details", text: "Contract details" },
    ];

    const getInvoices = async () => {
        setIsFetching(true)
        await api
            .get("/invoice", {
                params: {
                    search: search,
                    page: page,
                    limit: 10,
                    paid,
                    sort,
                    order
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setInvoices(response.data.data);
                    setMeta(response.data.meta);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };
    //search
    useEffect(() => {
        if(firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            setPage(1);
            getInvoices();
        }, 500);
        setPage(1);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    useEffect(() => {
        getInvoices();
    }, [page, paid, order, sort]);

    const handlePaidChange = (paid) => {
        setMeta([]);
        setIsFetching(true);
        setPage(1);
        setPaid(paid);
    }

    return (
        <Authenticated>
            <div className="text-2xl font-medium flex items-center">
                <Link to="/invoices">
                    <h2 className="p-1 dark:text-white">{i18n.t('Invoices')}</h2>
                </Link>
            </div>

            <Card title="Invoice notification" className="mt-5">
                <div className="bg-authenticated dark:bg-authenticated-dark my-2 p-5 flex flex-col gap-3 rounded-md font-medium dark:text-gray-200">
                    <div className="flex items-center gap-3">
                        <WarningIcon
                            color={
                                themeContext.theme == "dark"
                                    ? "white"
                                    : "primary"
                            }
                        />
                        <Text translate="We are upgrading our system." />
                        <Text translate="Invoices are sent to the email address you provided." />
                        <Text translate="Invoices below are for archive purposes only." />
                    </div>
                    <div>
                        <Text translate="If you have not received an invoice, please contact us at bok@dataspace.pl" />
                    </div>
                </div>
            </Card>

            <div className="flex flex-row mt-5">
                <button onClick={() => handlePaidChange(false)} className={`justify-center w-64 rounded-lg rounded-b-none p-4 ` + (paid == false ? `dark:bg-navbarDark bg-white` : `dark:bg-authenticated-dark bg-authenticated`)}>
                    <Text className="text-secondary dark:text-white" variant="h6" translate="Invoices to pay" />
                </button>

                <button onClick={() => handlePaidChange(true)} className={`w-64 ml-4 rounded-lg rounded-b-none p-4 ` + (paid == true ? `dark:bg-navbarDark bg-white` : `dark:bg-authenticated-dark bg-authenticated`)}>
                    <Text className="text-secondary dark:text-white" variant="h6" translate="Invoices paid" />
                </button>
            </div>
            <div className="p-5 lg:p-8 shadow-md  bg-white dark:bg-navbarDark">
                <div className="items-center py-2">
                    <TableSearch search={search} setSearch={setSearch} />
                </div>
                <Table
                    isFetching={isFetching}
                    columns={columns}
                    rows={invoices}
                    keyName="uuid"
                    renderItem={(item) => <InvoiceListItem invoice={item} />}
                    renderMobileItem={(item) => <InvoiceListMobileItem invoice={item} columns={columns}/>}
                    setSort={setSort}
                    sort={sort}
                    order={order}
                    setOrder={setOrder}
                />
                {invoices && <Pagination meta={meta} setPage={setPage} />}
            </div>
        </Authenticated>
    );
}
