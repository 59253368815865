import React from "react";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import {Link} from 'react-router-dom'
import Text from "../../components/Text";


export default function MessageListItem({message}) {
    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {message.Title}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                {message.StateID == 1 && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-50 text-yellow-500">
                        <Text translate="New" variant="h8"/>
                    </span>
                )}
                {message.StateID == 2 && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-400">
                        <Text translate="Closed" variant="h8"/>
                    </span>
                )}
                {message.StateID == 4 && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-50 text-yellow-500">
                        <Text translate="Open" variant="h8"/>
                    </span>
                )}
                {(message.StateID != 1 && message.StateID != 2 && message.StateID != 4) && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-50 text-yellow-500">
                        <Text translate="Unknown" variant="h8"/>
                    </span>
                )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {message.TicketNumber}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <Link to={'/messages/'+message.TicketID} className="text-lightBlue dark:text-white hover:opacity-90 hover:text-black">
                    <Text translate="Details" variant="h7"/>
                    <ChevronRight />
                </Link>
            </td>
        </>
    )
}
