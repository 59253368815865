import { TextField } from '@mui/material'
import React, { useContext } from 'react'
import { CurrentTheme } from '../context/CurrentUser'

export default function Input({children, inputProps, InputProps, InputLabelProps, ...otherProps}) {
    const themeContext = useContext(CurrentTheme)

    return (
        <TextField 
            InputLabelProps={{
                style: {
                    color: themeContext.theme == 'dark' ? 'white' : ''
                },
                ...InputLabelProps
            }}
            InputProps={{
                style: {
                    color: themeContext.theme == 'dark' ? 'white' : ''
                },
                ...InputProps
            }}
            inputProps={{
                className: themeContext.theme == 'dark' ? 'autofill-dark' : '',
                ...inputProps
            }}
            {...otherProps}
        >
            {children}
        </TextField>
    )
}
