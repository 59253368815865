export const colors = {
    primary: "#0B2940",
    secondary: "#003AA2",
    background: "#f7f7f7",
    lightBlue: "#003AA2",
    powerOn: '#00a873',
    powerOff: '#d62839',
    cardDark: '#264D60',
    white: '#ffffff',
    gray: '#E5E7EB',
    authenticatedDark: '#22272e'
}