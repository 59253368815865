import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../../api";
import Card from "../../../../components/Card";
import Table from "../../../../components/Table";
import ServerPTRListItem from "./ServerPTRListItem";
import ServerPTRListMobileItem from "./ServerPTRListMobileItem";

function ServerPTRList({ server }) {
    const { serverUuid } = useParams();
    const [PTRs, setPTRs] = useState([]);
    const [isFetching, setIsFetching] = useState(true);

    const getPTRs = async () => {
        setIsFetching(true);
        await api
            .get(`/server/${serverUuid}/ptr`)
            .then((response) => {
                if (response.status === 200) {
                    setPTRs(response.data.data);
                    setIsFetching(false);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    useEffect(() => {
        getPTRs();
    }, []);

    const columns = [
        { field: "name", text: "IP" },
        { field: "value",text: "Value" },
        { field: "ttl", text: "TTL" },
        { field: "", text: "Edit" },
    ];

    return (
        <Card variant="small" title="PTR Records">
            <Table
                barColor="bg-gray-50 dark:bg-authenticated-dark"
                isFetching={isFetching}
                columns={columns}
                rows={PTRs}
                keyName="uuid"
                renderItem={(item) => {
                    return <ServerPTRListItem ptr={item} updateTable={getPTRs}/>;
                }}
                renderMobileItem={(item) => {
                    return <ServerPTRListMobileItem ptr={item} updateTable={getPTRs} columns={columns}/>;
                }}
            />
        </Card>
    );
}

export default ServerPTRList;
