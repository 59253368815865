import React, { useContext, useState } from "react";
import NavbarItem from "./NavbarItem";
import TranslateIcon from "@mui/icons-material/Translate";
import LanguageIcon from '@mui/icons-material/Language';
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import Text from "../../Text";
import { CurrentLanguage } from "../../../context/CurrentUser";
import i18n from '../../../i18n';

export default function LanguageSelect({color, disableShadow}) {
    const languageContext = useContext(CurrentLanguage);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectLanguage = (lang) => {
        setAnchorEl(null);
        languageContext.setLanguage(lang);
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
    }

    return (
        <>
            <NavbarItem
                Icon={<LanguageIcon color={color ? color : "white"} />}
                onClick={handleClick}
                disableShadow={disableShadow}
            >
                <Text className={(color ? `text-${color}` : "text-white text-shadow") + " flex dark:text-white"} translate="Choose language"/>
            </NavbarItem>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem key="en" onClick={() => handleSelectLanguage('en')}>
                    <ListItemIcon>EN</ListItemIcon>
                    <Text translate="English" />
                </MenuItem>
                <MenuItem key="pl" onClick={() => handleSelectLanguage('pl')}>
                    <ListItemIcon>PL</ListItemIcon>
                    <Text translate="Polish" />
                </MenuItem>
            </Menu>
        </>
    );
}
