import React from 'react'
import ServerRemoteAccess from "./ServerRemoteAccess";
import ServerPower from "./ServerPower";
import ServerOperatingSystems from "./ServerOperatingSystems";
import Card from '../../../../components/Card';
import PowerIcon from '../../../../components/PowerIcon';

export default function ServerManagement({server, getServer}) {
    return (
        <Card 
            variant="small" 
            title="Management" 
            icon={<PowerIcon powerStatus={server.power_status}/>}
        >
            <div className="flex flex-col gap-3">
                {/* Remote access */}
                <ServerRemoteAccess server={server}/>
                {/* Power management */}
                <ServerPower server={server} getServer={getServer}/>
                {/* Operating systems */}
                <ServerOperatingSystems server={server}/>
            </div>
        </Card>
    )
}
