import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../api";
import Authenticated from "../../../components/layout/Authenticated";
import { Loader } from "../../../components/Loader/Loader";
import ServerBreadcrumbs from "./ServerBreadcrumbs";
import ServerManagement from "./ServerManagement/ServerManagement";
import ServerNetworks from "./ServerNetworks";
import SettingsIcon from "@mui/icons-material/Settings";
import Text from "../../../components/Text";
import ServerNetworkStats from "./ServerNetworkStats";
import { ThemeContext } from "@emotion/react";
import { useContext } from "react";
import { CurrentTheme } from "../../../context/CurrentUser";
import ServerPTRList from "./ServerPTR/ServerPTRList";
import ServerTransfers from "./ServerTransfers";

export default function ServerDetails() {
    const { serverUuid } = useParams();
    const [server, setServer] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [serverName, setServerName] = useState("");
    const [ipv4, setIpv4] = useState([]);
    const [ipv6, setIpv6] = useState([]);
    const themeContext = useContext(CurrentTheme);

    const getServer = async () => {
        setIsFetching(true);
        await api
            .get(`/server/${serverUuid}`)
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setServer(response.data.data);
                    setServerName(response.data.data.name);
                    setIpv4(response.data.data.network.ipv4);
                    setIpv6(response.data.data.network.ipv6);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    useEffect(() => {
        getServer();
    }, []);

    return (
        <Authenticated>
            {isFetching ? (
                <Loader />
            ) : server.status == "active" ? (
                <>
                    <ServerBreadcrumbs
                        server={server}
                        setServerName={setServerName}
                        serverName={serverName}
                        getServer={getServer}
                    />
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                        <div>
                            <ServerManagement
                                server={server}
                                getServer={getServer}
                            />
                        </div>
                        <ServerPTRList server={server} />
                    </div>
                    <ServerNetworks ipv4={ipv4} ipv6={ipv6} />
                    <ServerTransfers transferUsages={server.transfer_usages} />
                    <ServerNetworkStats server={server} />
                </>
            ) : (
                <div className="h-full w-full flex justify-center items-center">
                    <div className="flex flex-col items-center text-7xl">
                        <SettingsIcon
                            fontSize="inherit"
                            color={
                                themeContext.theme == "dark"
                                    ? "white"
                                    : "primary"
                            }
                        />
                        <Text
                            className="text-black dark:text-gray-200 p-4"
                            variant="h5"
                            translate="Your server is currently unavailable"
                        />
                    </div>
                </div>
            )}
        </Authenticated>
    );
}
