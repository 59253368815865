import React, { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../../components/Text";
import {
    ChevronRight,
    KeyboardArrowDown as ChevronDown,
    ArrowRight,
    ArrowForward,
} from "@mui/icons-material";

export default function ContractServiceItem({ contractService }) {
    const [isSelected, setIsSelected] = useState(false);

    const handleClick = () => {
        setIsSelected(!isSelected);
    };
    return (
        <div className="flex flex-col text-gray-400 dark:text-gray-200 hover:opacity-90 mb-5">
            <button onClick={handleClick}>
                <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200">
                    <div className="flex flex-row ">
                        {isSelected ? <ChevronDown /> : <ChevronRight />}
                        <Text className="dark:text-white text-primary">
                            {contractService.name}
                        </Text>
                    </div>
                    {/* @todo add here rest of contractService types redirects */}
                    {contractService.type === "server" &&
                        contractService.server_uuid && (
                            <Link
                                to={`/servers/` + contractService.server_uuid}
                            >
                                <ArrowForward className="pr-1" />
                            </Link>
                        )}
                    {contractService.type === "cloud_vps" &&
                        contractService.vm_uuid && (
                            <Link to={`/vps/` + contractService.vm_uuid}>
                                <ArrowForward className="pr-1" />
                            </Link>
                        )}
                    {contractService.type === "backup_ftp" &&
                        contractService.backupftp_uuid && (
                            <Link
                                to={
                                    `/backupftp/` +
                                    contractService.backupftp_uuid
                                }
                            >
                                <ArrowForward className="pr-1" />
                            </Link>
                        )}
                </div>
            </button>
            {isSelected && (
                <>
                    <div className="flex flex-row justify-between">
                        <Text translate="Name" />
                        <span className="font-medium text-black dark:text-white">
                            {contractService.name}
                        </span>
                    </div>

                    <div className="flex flex-row justify-between">
                        <Text translate="Brutto" />
                        <span className="font-medium text-black dark:text-white">
                            {contractService.brutto
                                ? parseFloat(contractService.brutto).toFixed(2)
                                : "0"}{" "}
                            zł
                        </span>
                    </div>

                    <div className="flex flex-row justify-between">
                        <Text translate="Vat" />
                        <span className="font-medium text-black dark:text-white">
                            {contractService.vat
                                ? parseFloat(contractService.vat).toFixed(2) *
                                      100 +
                                  "%"
                                : "0"}
                        </span>
                    </div>

                    <div className="flex flex-row justify-between">
                        <Text translate="Tax" />
                        <span className="font-medium text-black dark:text-white">
                            {contractService.tax
                                ? parseFloat(contractService.tax).toFixed(2)
                                : "0"}{" "}
                            zł
                        </span>
                    </div>

                    <div className="flex flex-row justify-between">
                        <Text translate="Count" />
                        <span className="font-medium text-black dark:text-white">
                            {contractService.count} {contractService.unit}
                        </span>
                    </div>
                </>
            )}
            {contractService.children && isSelected && (
                <div className="pl-4">
                    <Text translate="Service children:" />
                    {contractService.children.map((child) => {
                        return (
                            <ContractServiceItem
                                key={child.uuid}
                                contractService={child}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
}
