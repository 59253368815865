import React from "react";
import Card from "../../../components/Card";
import Table from "../../../components/Table";

export default function ServerTransfers({ transferUsages }) {

    const columns = [
        { field: "type", text: "Type" },
        { field: "translations.sum", text: "Sum", formatter: (field, row) => { return row.translations.sum; } },
        { field: "translations.limit", text: "Limit", formatter: (field, row) => { return row.translations.limit; } },
        { field: "translations.bytes_in", text: "Bytes In", formatter: (field, row) => { return row.translations.bytes_in; } },
        { field: "translations.bytes_out", text: "Bytes Out", formatter: (field, row) => { return row.translations.bytes_out; } },
        { field: "packets_in", text: "Packets In" },
        { field: "packets_out", text: "Packets Out" },
        { field: "start_date", text: "Started At" }
    ];

    return (
        <div className="grid gap-5 grid-cols-1">
            <Card title="Transfer Usage" variant="small">
                <Table
                        isFetching={false}
                        columns={columns}
                        rows={transferUsages}
                        keyName="type"
                    />
            </Card>
        </div>
    );
}