import React, { useState } from "react";
import i18n from "../i18n";
import { Loader } from "./Loader/Loader";
import Text from "./Text";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function Table({
    columns,
    rows,
    renderItem,
    keyName,
    isFetching,
    renderMobileItem,
    setSort,
    sort,
    order,
    setOrder,
    noDataText,
    barColor,
}) {
    const handleSortChange = (filter_name) => {
        if (sort == filter_name) {
            if (order == "asc") setOrder("desc");
            else if (order == "desc") setOrder("asc");
        } else {
            setSort(filter_name);
        }
    };

    return (
        <>
            {isFetching ? (
                <Loader />
            ) : (
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="py-2 align-middle inline-block min-w-full">
                            <div className="overflow-hidden rounded-t-lg hidden lg:block">
                                <table className="min-w-full">
                                    <thead
                                        className={
                                            barColor
                                                ? barColor
                                                : "bg-gray-50 dark:bg-gray-800"
                                        }
                                    >
                                        <tr>
                                            {columns.map((column, index) => {
                                                return column.filterable ? (
                                                    <th
                                                        key={column.field}
                                                        scope="col"
                                                        className={
                                                            "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-gray-200 cursor-pointer " +
                                                            (sort ==
                                                            column.filter_name
                                                                ? "bg-gray-100 dark:bg-gray-900 "
                                                                : "")
                                                        }
                                                        onClick={() =>
                                                            handleSortChange(
                                                                column.filter_name
                                                            )
                                                        }
                                                    >
                                                        <div className="flex flex-row items-center">
                                                            {i18n.t(
                                                                column.text
                                                            )}
                                                            {order == "desc" &&
                                                            sort ==
                                                                column.filter_name ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                        </div>
                                                    </th>
                                                ) : (
                                                    <th
                                                        key={column.field}
                                                        scope="col"
                                                        className={
                                                            (index === 0
                                                                ? "w-1/3 "
                                                                : "") +
                                                            "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-gray-200"
                                                        }
                                                    >
                                                        {i18n.t(column.text)}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {!rows.length && (
                                            <tr className="text-center text-sm font-medium dark:bg-authenticated-dark">
                                                <td
                                                    colSpan={columns.length}
                                                    className="px-6 py-4 text-center dark:text-gray-200"
                                                >
                                                    {noDataText ? (
                                                        <Text
                                                            translate={
                                                                noDataText
                                                            }
                                                        />
                                                    ) : (
                                                        <Text translate="No records" />
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {rows.map((row, rowIndex) => {
                                            return (
                                                <tr
                                                    key={row[keyName]}
                                                    className={
                                                        rowIndex % 2 === 0
                                                            ? "bg-authenticated dark:bg-authenticated-dark"
                                                            : "bg-white dark:bg-navbarDark"
                                                    }
                                                >
                                                    {renderItem &&
                                                        renderItem(row)}
                                                    {!renderItem &&
                                                        columns.map(
                                                            (
                                                                column,
                                                                colIndex
                                                            ) => {
                                                                if (
                                                                    column.formatter
                                                                ) {
                                                                    return (
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="flex items-center">
                                                                                <div>
                                                                                    <div className="text-sm font-medium text-black dark:text-white">
                                                                                        {column.formatter(
                                                                                            row[
                                                                                                column
                                                                                                    .field
                                                                                            ],
                                                                                            row,
                                                                                            colIndex,
                                                                                            rowIndex
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    );
                                                                }
                                                                return (
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            <div>
                                                                                <div className="text-sm font-medium text-black dark:text-white">
                                                                                    {
                                                                                        row[
                                                                                            column
                                                                                                .field
                                                                                        ]
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                );
                                                            }
                                                        )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="lg:hidden flex flex-col">
                                {!rows.length && (
                                    <div className="text-center text-sm font-medium text-black">
                                        <div className="px-6 py-4 text-center dark:text-white">
                                            <Text translate="No records" />
                                        </div>
                                    </div>
                                )}
                                {rows.map((row, rowIndex) => {
                                    return (
                                        <div key={rowIndex}>
                                            {renderMobileItem &&
                                                renderMobileItem(row, columns)}

                                            {!renderMobileItem &&
                                                columns.map(
                                                    (column, colIndex) => {
                                                        return (
                                                            <div
                                                                className="flex flex-row justify-between items-center"
                                                                key={colIndex}
                                                            >
                                                                <div>
                                                                    {
                                                                        column.text
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {column.formatter
                                                                        ? column.formatter(
                                                                              row[
                                                                                  column
                                                                                      .field
                                                                              ],
                                                                              row,
                                                                              colIndex,
                                                                              rowIndex
                                                                          )
                                                                        : row[
                                                                              column
                                                                                  .field
                                                                          ]}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
