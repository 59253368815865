import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import Authenticated from "../../components/layout/Authenticated";
import ServerListItem from "./ServerListItem";
import Pagination from "../../components/Pagination/Pagination";
import Text from "../../components/Text";
import TableSearch from "../../components/TableSearch";
import i18n from "../../i18n";
import useApi from "../../hooks/useApi";
import ServerListMobileItem from "./ServerListMobileItem";
import { api } from "../../api";
import Card from "../../components/Card";

export default function ServerList() {
    const [servers, setServers] = useState([])
    const [search, setSearch] = useState("");
    const [isFetching, setIsFetching] = useState(true);
    const [meta, setMeta] = useState([]);
    const [sort, setSort] = useState("name_external");
    const [order, setOrder] = useState("asc");
    const [page, setPage] = useState(1);
    const firstUpdate = useRef(true);
    // const {
    //     data: servers,
    //     meta,
    //     isFetching,
    //     errors,
    // } = useApi({
    //     url: "server",
    //     params: { page, search, sort, order },
    //     dependencyList: [search, page, sort, order],
    // });

    const getServers = async () => {
        setIsFetching(true)
        await api
            .get("/server", {
                params: {
                    search: search,
                    page: page,
                    limit: 10,
                    order,
                    sort
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setServers(response.data.data);
                    setMeta(response.data.meta);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    useEffect(() => {
        getServers();
    }, [page, sort, order])

    //search
    useEffect(() => {
        if(firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            setPage(1);
            getServers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const columns = [
        { field: "name", text: "Name", filterable: true, filter_name: "name_external"},
        { field: "power_status", text: "Power status", filterable: true, filter_name: "power_status" },
        { field: "remote", text: "Remote" },
        { field: "contract_uuid", text: "Contract" },
        { field: "details", text: "Details" },
    ];

    return (
        <Authenticated>
            <div>
                <div className="text-2xl font-medium flex items-center">
                    <Link to="/servers">
                        <h2 className="p-1 dark:text-white">
                            {i18n.t("Servers")}
                        </h2>
                    </Link>
                </div>
                <Card>
                    <TableSearch
                        search={search}
                        setSearch={setSearch}
                        Button={
                            <a
                                href={process.env.REACT_APP_PANEL_V1_URL +"/checkout/select"}
                                target="_blank"
                            >
                                <Button color="primary" variant="contained">
                                    {i18n.t("Buy server")}
                                </Button>
                            </a>
                        }
                    />
                    <Table
                        isFetching={isFetching}
                        columns={columns}
                        rows={servers}
                        keyName="uuid"
                        renderItem={(item) => {
                            return <ServerListItem server={item} />;
                        }}
                        renderMobileItem={(item) => {
                            return <ServerListMobileItem server={item} columns={columns}/>
                        }}
                        setSort={setSort}
                        sort={sort}
                        order={order}
                        setOrder={setOrder}
                    />
                    {servers && <Pagination meta={meta} setPage={setPage} />}
                </Card>
            </div>
        </Authenticated>
    );
}
