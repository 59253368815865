import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";
import { useHistory } from "react-router-dom";
import { api } from "../api";
import i18n from "../i18n";
import { CurrentTheme } from "../context/CurrentUser";
import LanguageSelect from "../components/layout/Navbar/LanguageSelect";
import ThemeToggle from "../components/layout/Navbar/ThemeToggle";
import { toast } from "react-toastify";

export default function RodoAcceptance() {
    const [information, setInformation] = useState(false);

    const themeContext = useContext(CurrentTheme);
    const history = useHistory();

    const handleRodoAccepted = (e) => {
        e.preventDefault();
        if(information) {
            api.post("/client/rodo/accept", {})
                .then((response) => {
                    if (response.status === 200) {
                        history.push("/dashboard");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }else{
            toast.error(() => {
                return (<><Text translate="You need to accept information obligation to continue using our site!" /></>);
            });
        }
    };

    return (
        <Guest>
            <div className="flex flex-col items-center justify-between 2xl:w-1/4">
                <img
                    src={themeContext.theme == "dark" ? LogoDark : Logo}
                    className="mb-6 w-1/2"
                    alt="logo"
                />
                <div className="p-8 w-full shadow-lg dark:bg-authenticated-dark bg-white">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex flex-row justify-between">
                            <Text
                                sx={{ fontWeight: 500, fontSize: 28 }}
                                className="text-secondary dark:text-gray-200"
                                translate="Rodo Acceptance"
                            />
                        </div>
                        <form
                            method="POST"
                            onSubmit={handleRodoAccepted}
                            className="flex flex-col w-full gap-5 mt-10"
                        >
                            <div>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={information}
                                                onChange={() =>
                                                    setInformation(!information)
                                                }
                                            />
                                        }
                                        label={
                                            <div className="dark:text-gray-200">
                                                <span>
                                                    {i18n.t(
                                                        "I declare that I have read"
                                                    )}{" "}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    href="https://api.dataspace.pl/rodo/information_obligation?mode=view"
                                                    className="text-lightBlue"
                                                >
                                                    {" "}
                                                    {i18n.t(
                                                        "information obligation"
                                                    )}
                                                </a>
                                                <span>
                                                    {" "}
                                                    {i18n.t(
                                                        "regarding the processing of personal data"
                                                    )}
                                                </span>
                                            </div>
                                        }
                                    />
                                </FormGroup>
                            </div>
                            <div className="flex flex-row justify-between items-center mt-5">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    <Text translate="Send" />
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-4 shadow-lg dark:bg-authenticated-dark">
                    <LanguageSelect color="lightBlue"/>
                    <ThemeToggle color="lightBlue"/>
                </div>
            </div>
        </Guest>
    );
}
