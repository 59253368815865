import { ChevronRight } from "@mui/icons-material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../api";
import Text from "../../components/Text";
import Download from "../../components/Download";
import DownloadIcon from '@mui/icons-material/Download';

export default function InvoiceListItem({ invoice }) {
    const handlePay = () => {
        api.get(`/payment/${invoice.access_token}`)
            .then((response) => {
                if (response.status === 200) {
                    window.location.href = response.data.redirect;
                }
            })
            .catch((errors) => console.error(errors));
    };

    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <Download
                        url={
                            "/invoice/" +
                            invoice.uuid +
                            "/download"
                        }
                    >
                        <div className="text-sm font-medium text-lightBlue dark:text-white hover:opacity-90">
                            <DownloadIcon className="pr-2"/>
                            {invoice.number}
                        </div>
                    </Download>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {invoice.type == 1 && 'Proforma'}
                            {invoice.type == 2 && 'VAT'}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {parseFloat(invoice.brutto).toFixed(2)}{" "}
                            {invoice.currency.short_currency}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {invoice.period_start} - {invoice.period_end}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {invoice.issue_date}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {invoice.contract_name}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap text-left text-sm justify-between font-medium flex items-center">
                <Link
                    to={"/contracts/" + invoice.contract_uuid}
                    className="text-lightBlue dark:text-white hover:opacity-90"
                >
                    <Text translate="Go to contract details" variant="h7" />
                    <ChevronRight />
                </Link>
                {invoice.status == 2 && !invoice.blocked_payment && (
                    <div
                        onClick={handlePay}
                        className="text-lightBlue dark:text-white hover:opacity-90 cursor-pointer"
                    >
                        <Text translate="Pay" variant="h7" />
                        <ChevronRight />
                    </div>
                )}
            </td>
        </>
    );
}
