import React from 'react';
import { useContext } from 'react';
import InnerCard from '../../../components/InnerCard';
import Text from '../../../components/Text';
import { CurrentTheme } from '../../../context/CurrentUser';

export default function BackupFTPSpecification({backupftp}) {

    const themeContext = useContext(CurrentTheme)

    return (
        <>
            <InnerCard title="Information">
                <div className="dark:text-gray-200">
                    <div className="flex justify-between">
                        <Text translate="Server"/>
                        {backupftp.server}
                    </div>
                    <div className="flex justify-between">
                        <Text translate="Domain"/>
                        {backupftp.domain}
                    </div>
                    <div className="flex justify-between">
                        <Text translate="Size"/>
                        {backupftp.hd_quota + 'GB'}
                    </div>
                </div>
            </InnerCard>
        </>
    )
}
