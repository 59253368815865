import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../api";
import Authenticated from "../../../components/layout/Authenticated";
import { Loader } from "../../../components/Loader/Loader";
import VmBreadcrumbs from "./VmBreadcrumbs";
import VmManagement from "./VmManagement/VmManagement";
import VmNetworks from "./VmNetworks";
import VmRRD from "./VmRRD";

export default function VmDetails() {
    
    const { vmUuid } = useParams();
    const [vm, setVm] = useState({});
    const [isFetching, setIsFetching] = useState(true);
    const [ipv4, setIpv4] = useState([]);
    const [ipv6, setIpv6] = useState([]);

    const getVm = async () => {
        await api
            .get(`/vm/${vmUuid}`)
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setVm(response.data.data);
                    setIpv4(response.data.data.network.ipv4);
                    setIpv6(response.data.data.network.ipv6);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getVm();
    }, []);

    return (
        <Authenticated>
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <VmBreadcrumbs vm={vm} getVm={getVm} />
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                        <div>
                            <VmManagement vm={vm} getVm={getVm}/>
                        </div>
                        <VmNetworks ipv4={ipv4} ipv6={ipv6}/>
                    </div>
                    <VmRRD vm={vm}/>
                </>
            )}
        </Authenticated>
    );
}
