import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";
import { useHistory } from "react-router-dom";
import { api } from "../api";
import i18n from "../i18n";
import { CurrentTheme, CurrentUser } from "../context/CurrentUser";
import LanguageSelect from "../components/layout/Navbar/LanguageSelect";
import ThemeToggle from "../components/layout/Navbar/ThemeToggle";
import { toast } from "react-toastify";
import { colors } from "../colors";
import { Loader } from "../components/Loader/Loader";
import Download from "../components/Download";
import { accountInfo } from "../auth";

export default function RegulationsAcceptance() {
    const themeContext = useContext(CurrentTheme);
    const userContext = useContext(CurrentUser);

    const [contractRegulations, setContractRegulations] = useState([]);
    const [clientRegulations, setClientRegulations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const loadRegulations = async () => {
        setIsLoading(true);
        /** @todo this can be done with Promise.all() */
        await api.get("/contract/regulation").then(async (response) => {
            setContractRegulations(response.data.data);
            await api.get("/client/regulation").then((clientResponse) => {
                setClientRegulations(clientResponse.data.data);
                setIsLoading(false);

                if (
                    response.data.data.length === 0 &&
                    clientResponse.data.data.length === 0
                ) {
                    toast.success(i18n.t("All regulations accepted!"));
                    history.push("/");
                }
            });
        });
    };

    useEffect(async () => {
        await loadRegulations();
    }, []);

    const handleClientRegulationAcceptance = (regulation) => {
        setIsLoading(true);
        api.put(`/client/regulation/${regulation.uuid}`).then(
            async (response) => {
                if (response.status === 200) {
                    toast.success(i18n.t("Regulation accepted"));
                    loadRegulations();
                }
                setIsLoading(false);
            }
        );
    };

    const handleContractRegulationAcceptance = (regulation) => {
        setIsLoading(true);
        api.put(
            `/contract/${regulation.contract_uuid}/regulation/${regulation.uuid}?company_id=${regulation.company_uuid}`,
        ).then(async (response) => {
            if (response.status === 200) {
                toast.success(i18n.t("Regulation accepted"));
                loadRegulations();
            }
            setIsLoading(false);
        });
    };

    return (
        <Guest>
            <div className="flex flex-col items-center justify-between 2xl:w-1/4">
                <img
                    src={themeContext.theme == "dark" ? LogoDark : Logo}
                    className="mb-6 w-1/2"
                    alt="logo"
                />
                <div className="p-8 w-full shadow-lg dark:bg-authenticated-dark bg-white">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex flex-row justify-between mb-6">
                            <Text
                                sx={{ fontWeight: 500, fontSize: 28 }}
                                className="text-secondary darK:text-gray-200"
                                translate="Regulations acceptance"
                            />
                        </div>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <div className="flex flex-col justify-center">
                                    {contractRegulations.length > 0 && (
                                        <Text
                                            className="dark:text-white pb-4"
                                            variant="h5"
                                            translate="List of contract regulations:"
                                        />
                                    )}
                                    {contractRegulations.map((regulation) => (
                                        <div
                                            key={regulation.uuid}
                                            className="flex flex-row mb-4 justify-between"
                                        >
                                            <Text className="dark:text-white">
                                                {regulation.contract}
                                                {": "}
                                                {regulation.name}
                                            </Text>
                                            <div className="flex flex-row gap-3 items-center">
                                                <Button
                                                    variant="contained"
                                                    onClick={() =>
                                                        handleContractRegulationAcceptance(
                                                            regulation
                                                        )
                                                    }
                                                >
                                                    {i18n.t("I accept")}
                                                </Button>
                                                <Download
                                                    url={`/contract/${regulation.contract_uuid}/regulation/${regulation.uuid}/download`}
                                                >
                                                    <Text
                                                        color={colors.lightBlue}
                                                        translate="Download"
                                                    />
                                                </Download>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex flex-col justify-center">
                                    {clientRegulations.length > 0 && (
                                        <Text
                                            className="dark:text-white pb-4"
                                            variant="h5"
                                            translate="List of client regulations:"
                                        />
                                    )}
                                    {clientRegulations.map((regulation) => (
                                        <div
                                            key={regulation.uuid}
                                            className="flex flex-row mb-4 justify-between"
                                        >
                                            <Text className="dark:text-white">
                                                {regulation.name}
                                            </Text>
                                            <div className="flex flex-row gap-3 items-center">
                                                <Button
                                                    variant="contained"
                                                    onClick={() =>
                                                        handleClientRegulationAcceptance(
                                                            regulation
                                                        )
                                                    }
                                                >
                                                    {i18n.t("I accept")}
                                                </Button>
                                                <Download
                                                    url={`/client/regulation/${regulation.uuid}/download`}
                                                >
                                                    <Text
                                                        color={colors.lightBlue}
                                                        translate="Download"
                                                    />
                                                </Download>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="mt-4 shadow-lg dark:bg-authenticated-dark">
                    <LanguageSelect color="lightBlue" />
                    <ThemeToggle color="lightBlue" />
                </div>
            </div>
        </Guest>
    );
}
