import React, { useEffect, useState } from "react";
import Authenticated from "../components/layout/Authenticated";
import Text from "../components/Text";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckIcon from "@mui/icons-material/Check";
import { api } from "../api";

export default function PaymentStatus(props) {
    const access_token = new URLSearchParams(props.location.search).get(
        "access_token"
    );
    const [status, setStatus] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!status) {
                api.get(`/payment/${access_token}/status`)
                    .then((response) => {
                        if (response.data.paid == true) {
                            setStatus(true);
                        }
                    })
                    .catch((errors) => console.error(errors));
            }
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Authenticated>
            <div className="flex w-full h-full justify-center align-middle items-center">
                <div className="bg-white dark:bg-navbarDark shadow-md dark:text-gray-200 text-secondary p-4">
                    <Text variant="h5" translate={status ? 'Thanks for the payment!' : 'Payment is being verified!'} />
                    <div className="p-4 flex justify-center">
                        <Text
                            translate="Current transaction status:"
                            className="pr-4"
                        />
                        {status ? (
                            <CheckIcon className="animate-pulse" />
                        ) : (
                            <AutorenewIcon className="animate-spin" />
                        )}
                    </div>
                </div>
            </div>
        </Authenticated>
    );
}
