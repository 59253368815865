import React, { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import Text from "../../../../components/Text";
import { Button } from "@mui/material";
import Input from "../../../../components/Input";
import { useParams } from "react-router-dom";
import i18n from "../../../../i18n";
import { api } from "../../../../api";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";

export default function ServerPTRListMobileItem({ ptr, updateTable, columns }) {
    const { serverUuid } = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const [value, setValue] = useState(ptr.value);
    const [isFetching, setIsFetching] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleUpdateRecord = async () => {
        if (value != ptr.value) {
            setIsFetching(true);
            await api
                .put(`/server/${serverUuid}/ptr/${ptr.uuid}`, { value })
                .then((response) => {
                    if (response.status === 200) {
                        updateTable();
                    }
                })
                .catch((error) => {
                    setErrors(error?.response?.data?.errors ?? []);
                });
        }
        setIsFetching(false);
        setIsEditMode(false);
    };

    return (
        <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md rounded-md text-secondary dark:bg-authenticated-dark dark:text-white font-medium">
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[0].text}/>
                </div>
                <div className="text-right mb-1">
                    {ptr.name}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[1].text}/>
                </div>
                <div className="text-right mb-1">
                {isEditMode ? (
                    <Input
                        name="value"
                        label={i18n.t("Value")}
                        variant="outlined"
                        value={value}
                        color="primary"
                        onChange={(e) => setValue(e.target.value)}
                        error={errors.value ?? false}
                        helperText={errors.value ?? false}
                    />
                ) : (
                    ptr.value
                )}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[2].text}/>
                </div>
                <div className="text-right mb-1">
                    {ptr.ttl}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[3].text}/>
                </div>
                {isEditMode === false ? (
                    <Button
                        className="text-lightBlue font-medium dark:text-white hover:opacity-90 flex items-center"
                        onClick={() => setIsEditMode(true)}
                    >
                        <CreateIcon />
                        <Text translate="Edit" />
                    </Button>
                ) : (
                    <LoadingButton
                        startIcon={<CheckIcon />}
                        loading={isFetching}
                        loadingPosition="start"
                        className="text-lightBlue font-medium dark:text-white hover:opacity-90 flex items-center"
                        onClick={() => handleUpdateRecord()}
                    >
                        <Text translate="Save" />
                    </LoadingButton>
                )}
            </div>
        </div>
    );
}
