import React from "react";
import Card from "../../../components/Card";
import IPTable from "../../../components/IPTable";
import Text from "../../../components/Text";

export default function ServerNetworks({ ipv4, ipv6 }) {
    return (
        <div className="grid lg:grid-cols-2 gap-5 grid-cols-1">
            <Card title="IPv4 Addressing" variant="small">
                <IPTable rows={ipv4} />
            </Card>
            <Card title="IPv6 Addressing" variant="small">
                <IPTable rows={ipv6} />
            </Card>
        </div>
    );
}
