import React, { useEffect, useState } from "react";
import { api } from "../../../api";
import Text from "../../../components/Text";
import DownloadIcon from "@mui/icons-material/Download";
import { Loader } from "../../../components/Loader/Loader";
import Download from "../../../components/Download";
import Card from "../../../components/Card";
import InnerCard from "../../../components/InnerCard";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import i18n from "../../../i18n";
import ContractEntrustedPersonalDataForm from "./ContractEntrustedPersonalDataForm";

export default function ContractEntrustedPersonalData({ contract }) {
    const [rodoDocuments, setRodoDocuments] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [meta, setMeta] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const [newCategory, setNewCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [subcategory, setSubcategory] = useState("");

    const getDocuments = async () => {
        await api
            .get(`/contract/${contract.uuid}/rodo`, {
                params: {
                    search: search,
                    page: page,
                    limit: 10,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setRodoDocuments(response.data.data);
                    setMeta(response.data.meta);
                    setIsFetching(false);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    useEffect(() => {
        if (contract.uuid) {
            getDocuments();
        }
    }, [contract, modalIsOpen]);


    return (
        <Card
            variant="small"
            title="Contract Entrusted Personal Data Documents"
            icon={
                <>
                    <Button
                        onClick={() => {
                            setModalIsOpen(true);
                        }}
                        className="dark:text-white"
                    >
                        <EditIcon />
                        <Text translate="Entrust Personal Data" />
                    </Button>
                    <ContractEntrustedPersonalDataForm
                        contract={contract}
                        isOpen={modalIsOpen}
                        setIsOpen={setModalIsOpen}
                    />
                </>
            }
        >
            {isFetching ? (
                <Loader />
            ) : (
                <InnerCard>
                    {rodoDocuments.length == 0 && (
                        <Text
                            align="center"
                            className="dark:text-white"
                            translate="No records"
                        />
                    )}
                    {rodoDocuments.map((row) => {
                        return (
                            <div
                                key={row.id}
                                className="flex flex-col text-gray-400 dark:text-gray-200 gap-3"
                            >
                                <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200">
                                    <Text className="w-full">
                                        {row.name}
                                        {Object.entries(row.options).map(
                                            ([key, values]) => {
                                                return (
                                                    <div key={key}>
                                                        <Text>
                                                            {key}:
                                                            {values.map(
                                                                (value) => {
                                                                    return (
                                                                        <Text>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            {
                                                                                value
                                                                            }
                                                                        </Text>
                                                                    );
                                                                }
                                                            )}
                                                        </Text>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Text>
                                    {row.document_uuid && <Download
                                        className="flex text-lightBlue dark:text-white hover:opacity-90"
                                        url={
                                            "/contract/" +
                                            contract.uuid +
                                            "/document/" +
                                            row.document_uuid +
                                            "/download"
                                        }
                                    >
                                        <DownloadIcon />
                                        <Text translate="Download" />
                                    </Download>}
                                </div>
                            </div>
                        );
                    })}
                </InnerCard>
            )}
        </Card>
    );
}
