import React from "react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import i18n from "../i18n";

export default function PowerIcon({ powerStatus }) {
    return (
        <>
            {powerStatus == "unknown" && (
                <div className="flex items-center">
                    <PowerSettingsNewIcon
                        color="powerOff"
                        data-testid="power-off-icon"
                    />
                    <span className="pl-1 font-medium text-powerOff text-lg">
                        {i18n.t("Power unknown")}
                    </span>
                </div>
            )}
            {(powerStatus == "1" || powerStatus == "running") && (
                <div className="flex items-center">
                    <PowerSettingsNewIcon
                        color="powerOn"
                        data-testid="power-on-icon"
                    />
                    <span className="pl-1 font-medium text-powerOn text-lg">
                        {i18n.t("Power on")}
                    </span>
                </div>
            )}
            {(powerStatus != "1" && powerStatus != "running" && powerStatus != "unknown") && (
                <div className="flex items-center">
                    <PowerSettingsNewIcon
                        color="powerOff"
                        data-testid="power-off-icon"
                    />
                    <span className="pl-1 font-medium text-powerOff text-lg">
                        {i18n.t("Power off")}
                    </span>
                </div>
            )}
        </>
    );
}
