import React, { useContext, useEffect, useState } from "react";
import Authenticated from "../../components/layout/Authenticated";
import Text from "../../components/Text";
import MessageBreadcrumbs from "./MessageDetails/MessageBreadcrumbs";
import { api } from "../../api";
import Input from "../../components/Input";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";
import { Autocomplete, TextField } from "@mui/material";
import i18n from "../../i18n";
import { useHistory } from "react-router-dom";
import { CurrentTheme } from "../../context/CurrentUser";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Cancel as CancelIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

export default function MessageCreate() {
    const [message, setMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errors, setErrors] = useState([]);
    const [subject, setSubject] = useState(3);
    const [service, setService] = useState("");
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const themeContext = useContext(CurrentTheme)

    const subjects = [
        {
            value: 3,
            label: "Technical question",
        },
        {
            value: 2,
            label: "Business question",
        },
        {
            value: 1,
            label: "Server malfunction",
        },
    ];
    const getServices = () => {
        api.get("service")
            .then((response) => {
                if (response.status === 200) {
                    setServices(response.data.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getServices();
    }, []);

    const handleCreate = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const data = new FormData();
        data.append('message', message)
        data.append('service_id', service)
        data.append('subject_id', subject)
        for (const key of Object.keys(uploadedFiles)) {
            data.append('uploaded_files[]', uploadedFiles[key])
        }
        await api
            .post("/message", data)
            .then((response) => {
                if (response.status === 201) {
                    setIsLoading(false)
                    history.push("/messages/" + response.data.TicketID);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <Authenticated>
            <div>
                <MessageBreadcrumbs create={true} />
                <div className="p-8 shadow-md rounded-md w-full lg:w-1/2 mt-5 bg-white dark:bg-navbarDark">
                    <form
                        onSubmit={handleCreate}
                        className="flex flex-col gap-4"
                    >
                        <Input
                            name="subject"
                            label={i18n.t("Subject")}
                            variant="outlined"
                            select
                            value={subject}
                            color="primary"
                            onChange={(e) => setSubject(e.target.value)}
                            error={errors.subject_id ?? false}
                            helperText={errors.subject_id ?? false}
                        >
                            {subjects.map((subject) => (
                                <MenuItem
                                    key={subject.value}
                                    value={subject.value}
                                >
                                    {i18n.t(subject.label)}
                                </MenuItem>
                            ))}
                        </Input>
                        <Autocomplete
                            openOnFocus
                            options={services.map((service) => {
                                return {
                                    key: service.uuid,
                                    value: service.uuid,
                                    label: service.name + (service.service_name ? ' ( ' + service.service_name + ' )' : ''),
                                };
                            })}
                            onChange={(event, newValue) => {
                                setService(newValue.value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={i18n.t("Service")}
                                    value={service}
                                    InputProps={{...params.InputProps,
                                        style: {
                                            color: themeContext.theme == 'dark' ? 'white' : ''
                                        },
                                    }}
                                    InputLabelProps={{...params.InputLabelProps,
                                        style: {
                                            color: themeContext.theme == 'dark' ? 'white' : ''
                                        },
                                    }}
                                    inputProps={{
                                        ...params.inputProps
                                    }}
                                    error={errors.service_id ?? false}
                                    helperText={errors.service_id ?? false}
                                />
                            )}
                        />
                        <Input
                            name="message"
                            label={i18n.t("Message")}
                            variant="outlined"
                            multiline
                            value={message}
                            rows={5}
                            color="primary"
                            onChange={(e) => setMessage(e.target.value)}
                            error={errors.message ?? false}
                            helperText={errors.message ?? false}
                        />
                        <label
                            className="w-100 flex flex-col items-center px-4 py-3 bg-white dark:bg-navbarDark dark:border-gray-200 rounded-md shadow-md dark:text-gray-200 dark:hover:bg-gray-200 dark:hover:text-navbarDark tracking-wide uppercase border cursor-pointer hover:bg-secondary hover:text-white text-secondary ease-linear transition-all duration-150">
                            <CloudUploadIcon/>
                            <span className="mt-2 text-base leading-normal">{i18n.t('Include pictures')}</span>
                            <input type="file" 
                                className="hidden"
                                multiple
                                onChange={(e) => setUploadedFiles(e.target.files)}
                            />
                        </label>
                        <div className="flex flex-wrap">
                            {
                                uploadedFiles.length ? Array.from(uploadedFiles).map((file, i) => {
                                    const url = URL.createObjectURL(file)
                                    return <img key={i} src={url} className="w-1/4 p-2" alt="uploaded file"/>
                                }) : null
                            }
                        </div>
                        {
                            uploadedFiles.length ? 
                            <button className="flex items-center gap-1 text-powerOff" onClick={() => setUploadedFiles([])}>
                                <CancelIcon/>
                                <Text variant="h7" translate="Clear" className="hover:opacity-90"/>
                            </button> 
                            : null
                        }
                        <div className="flex flex-row justify-center items-center mt-5">
                            <LoadingButton
                                startIcon={<MailOutlineRoundedIcon />}
                                loading={isLoading}
                                loadingPosition="start"
                                variant="contained"
                                type="submit"
                                size="large"
                                sx={{
                                    ':hover': {
                                        'opacity': '0.9'
                                    },
                                }}
                            >
                                <Text translate="Send" className="py-2"/>
                            </LoadingButton>
                        </div>
                    </form>
                </div>
            </div>
        </Authenticated>
    );
}
