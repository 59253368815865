import React, { useEffect, useState } from 'react'
import { api } from '../../api';
import Authenticated from '../../components/layout/Authenticated'
import Pagination from '../../components/Pagination/Pagination';
import Table from '../../components/Table';
import i18n from '../../i18n'
import LoginLogListItem from './LoginLogListItem';
import LoginLogListMobileItem from './LoginLogListMobileItem';

export default function LoginLogList() {

    const [logons, setLogons] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [meta, setMeta] = useState([]);
    const [page, setPage] = useState(1);

    const getLogons = async () => {
        setIsFetching(true)
        await api
            .get("/logons", {
                params: {
                    page: page,
                    limit: 10,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setLogons(response.data.data);
                    setMeta(response.data.meta);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };

    //pagination
    useEffect(() => {
        getLogons();
    }, [page]);

    const columns = [
        { field: "date", text: "When" },
        { field: "device", text: "Device" },
        { field: "ip", text: "IP" },
        { field: "browser", text: "Browser" },
        { field: "version", text: "Browser version" }
    ];

    return (
        <Authenticated>
            <div>
                <div className="text-2xl font-medium flex items-center">
                    <h2 className="p-1 dark:text-white">{i18n.t('Login history')}</h2>
                </div>
                <div className="p-8 shadow-md mt-5 bg-white dark:bg-navbarDark">
                    <Table
                        isFetching={isFetching}
                        columns={columns}
                        rows={logons}
                        keyName="uuid"
                        renderItem={(item) => {
                            return <LoginLogListItem logon={item} />;
                        }}
                        renderMobileItem={(item) => {
                            return <LoginLogListMobileItem columns={columns} logon={item} />;
                        }}
                    />
                    <Pagination meta={meta} setPage={setPage} />
                </div>
            </div>
        </Authenticated>
    )
}
