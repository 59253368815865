import React, { useCallback, useContext, useEffect, useState } from "react";
import i18n from "../../../i18n";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Loader } from "../../../components/Loader/Loader";
import { api } from "../../../api";
import {
    cpuOptions,
    diskOptions,
    ramOptions,
    networkOptions,
} from "./RRDOptions";
import Text from "../../../components/Text";
import Card from "../../../components/Card";
import { CurrentTheme } from "../../../context/CurrentUser";
import { colors } from "../../../colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((themeContext) => ({
    icon: { color: "white" },
}));

export default function VmRRD({ vm }) {
    const themeContext = useContext(CurrentTheme);
    const classes = useStyles(themeContext);

    const [cpuData, setCpuData] = useState({
        labels: [],
        datasets: [
            {
                label: i18n.t("CPU"),
                data: [],
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Max CPU"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 30, 192)",
                tension: 0.1,
            },
        ],
    });

    const [diskData, setDiskData] = useState({
        labels: [],
        datasets: [
            {
                label: i18n.t("Disk"),
                data: [],
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Disk read"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 30, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Disk write"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 00, 00)",
                tension: 0.1,
            },
        ],
    });

    const [ramData, setRamData] = useState({
        labels: [],
        datasets: [
            {
                label: i18n.t("vRAM Memory"),
                data: [],
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Max vRAM Memory"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 30, 192)",
                tension: 0.1,
            },
        ],
    });

    const [networkData, setNetworkData] = useState({
        labels: [],
        datasets: [
            {
                label: i18n.t("Network in"),
                data: [],
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
            },
            {
                label: i18n.t("Network out"),
                data: [],
                fill: false,
                borderColor: "rgb(00, 30, 192)",
                tension: 0.1,
            },
        ],
    });

    const [interval, setInterval] = useState("day");
    const [consolidationFunction, setConsolidationFunction] =
        useState("AVERAGE");
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [cpu, setCpu] = useState([]);
    const [maxCpu, setMaxCpu] = useState([]);
    const [memory, setMemory] = useState([]);
    const [maxMemory, setMaxMemory] = useState([]);
    const [disk, setDisk] = useState([]);
    const [diskRead, setDiskRead] = useState([]);
    const [diskWrite, setDiskWrite] = useState([]);
    const [networkIn, setNetworkIn] = useState([]);
    const [networkOut, setNetworkOut] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data) {
            setCpu(data.map((d) => d.cpu));
            setMaxCpu(data.map((d) => d.maxcpu));

            setMemory(data.map((d) => d.mem));
            setMaxMemory(data.map((d) => d.maxmem));

            setDisk(data.map((d) => d.disk));
            setDiskRead(data.map((d) => d.diskread));
            setDiskWrite(data.map((d) => d.diskwrite));

            setNetworkIn(data.map((d) => d.netin));
            setNetworkOut(data.map((d) => d.netout));

            setLabels(
                data.map((d) =>
                    dayjs.unix(d.time).format("YYYY-MM-DD HH:mm:ss")
                )
            );
        }
        setLoading(false);
    }, [data]);

    useEffect(() => {
        setCpuData({
            labels: labels,
            datasets: [
                {
                    ...cpuData.datasets[0],
                    data: cpu,
                },
                {
                    ...cpuData.datasets[1],
                    data: maxCpu,
                },
            ],
        });

        setDiskData({
            labels: labels,
            datasets: [
                {
                    ...diskData.datasets[0],
                    data: disk,
                },
                {
                    ...diskData.datasets[1],
                    data: diskRead,
                },
                {
                    ...diskData.datasets[2],
                    data: diskWrite,
                },
            ],
        });

        setRamData({
            labels: labels,
            datasets: [
                {
                    ...ramData.datasets[0],
                    data: memory,
                },
                {
                    ...ramData.datasets[1],
                    data: maxMemory,
                },
            ],
        });

        setNetworkData({
            labels: labels,
            datasets: [
                {
                    ...networkData.datasets[0],
                    data: networkIn,
                },
                {
                    ...networkData.datasets[1],
                    data: networkOut,
                },
            ],
        });
    }, [labels]);

    useEffect(() => {
        if (vm.uuid) {
            setLoading(true);
            api.get(`/vm/${vm.uuid}/rrd_data`, {
                params: {
                    interval: interval,
                    consolidation_function: consolidationFunction,
                },
            }).then((res) => {
                if (res.status === 200) {
                    setData(res.data.data);
                }
            });
        }
    }, [vm, interval, consolidationFunction]);

    return (
        <Card variant="small" title="Statistics">
            <div className="p-4 w-100">
                <div className="flex gap-4">
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="select-interval-label">
                            <p className="dark:text-white">
                                {i18n.t("Interval")}
                            </p>
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{
                                style: {
                                    color:
                                        themeContext.theme == "dark"
                                            ? colors.white
                                            : "",
                                },
                            }}
                            classes={{
                                icon:
                                    themeContext.theme == "dark"
                                        ? classes.icon
                                        : null,
                            }}
                            labelId="select-interval-label"
                            id="select-interval"
                            value={interval}
                            label="Interval"
                            onChange={(e) => setInterval(e.target.value)}
                        >
                            <MenuItem value={"hour"}>{i18n.t("Hour")}</MenuItem>
                            <MenuItem value={"day"}>{i18n.t("Day")}</MenuItem>
                            <MenuItem value={"week"}>{i18n.t("Week")}</MenuItem>
                            <MenuItem value={"month"}>
                                {i18n.t("Month")}
                            </MenuItem>
                            <MenuItem value={"year"}>{i18n.t("Year")}</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="select-interval-label">
                            <p className="dark:text-white">
                                {i18n.t("Consolidation function")}
                            </p>
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{
                                style: {
                                    color:
                                        themeContext.theme == "dark"
                                            ? colors.white
                                            : "",
                                },
                            }}
                            classes={{
                                icon:
                                    themeContext.theme == "dark"
                                        ? classes.icon
                                        : null,
                            }}
                            labelId="select-interval-label"
                            id="select-interval"
                            value={consolidationFunction}
                            label="Interval"
                            onChange={(e) =>
                                setConsolidationFunction(e.target.value)
                            }
                        >
                            <MenuItem value={"AVERAGE"}>
                                {i18n.t("Average")}
                            </MenuItem>
                            <MenuItem value={"MAX"}>{i18n.t("Max")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="md:flex pt-6">
                    <div className="h-96 w-100 md:w-1/2">
                        {loading ? (
                            <Loader />
                        ) : (
                            <Line data={cpuData} options={cpuOptions} />
                        )}
                    </div>

                    <div className="h-96 w-100 md:w-1/2">
                        {loading ? (
                            <Loader />
                        ) : (
                            <Line data={diskData} options={diskOptions} />
                        )}
                    </div>
                </div>
                <div className="md:flex pt-6">
                    <div className="h-96 w-100 md:w-1/2">
                        {loading ? (
                            <Loader />
                        ) : (
                            <Line data={ramData} options={ramOptions} />
                        )}
                    </div>
                    <div className="h-96 w-100 md:w-1/2">
                        {loading ? (
                            <Loader />
                        ) : (
                            <Line data={networkData} options={networkOptions} />
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
}
