import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../api'
import Authenticated from '../components/layout/Authenticated'
import Text from '../components/Text'
import { CurrentCompany } from '../context/CurrentUser'

export default function CompanySelect() {
    
    const [companies, setCompanies] = useState([])
    const companyContext = useContext(CurrentCompany)
    const history = useHistory()

    useEffect(() => {
        api.get('/company').then(
            response => {
                if(response.status === 200) {
                    setCompanies(response.data.data)
                }
            }
        ).catch(
            error => {
                console.error(error)
            }
        )
    }, [])

    const handleClick = (company) => {
        companyContext.setCurrentCompany(company);
        localStorage.setItem("company_uuid", company?.uuid ?? "");
        history.push("/dashboard");
    };

    return (
        <Authenticated>
            <div className="w-full h-full flex justify-center items-center">
                <div className="bg-white dark:bg-navbarDark shadow-md py-8 px-16 text-center">
                    <Text translate="Select company" variant="h4" className="pb-5 text-secondary dark:text-gray-200"/>
                    <div className="w-100 text-center p-3 cursor-pointer bg-gray-300 rounded mb-4" onClick={() => handleClick(null)}>
                        <Text translate="Individual client"/>
                    </div>
                    <div className="flex flex-col items-center">
                        {companies.map(company => {
                            return <div 
                                className="w-100 text-center p-3 cursor-pointer bg-gray-200 rounded mb-4 hover:opacity-90"
                                onClick={() => handleClick(company)}
                                key={company.uuid}
                            >
                                {company.name}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </Authenticated>
    )
}
