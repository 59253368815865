import {
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { CurrentCompany } from "../../../context/CurrentUser";
import NavbarItem from "./NavbarItem";
import { api } from "../../../api";
import Text from "../../Text";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

export default function CompanyDropdown() {
    const companyContext = useContext(CurrentCompany);

    const [anchorEl, setAnchorEl] = useState(null);
    const [companies, setCompanies] = useState([]);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleClick = (event) => {
        api.get("/company")
            .then((response) => {
                setCompanies(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
        setAnchorEl(event.currentTarget);
    };

    const handleSelectCompany = (company) => {
        setAnchorEl(null);
        companyContext.setCurrentCompany(company);
        localStorage.setItem("company_uuid", company?.uuid ?? "");
        history.push("/dashboard");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <NavbarItem
                text={
                    companyContext?.currentCompany?.name ?? "Individual client"
                }
                Icon={<WorkOutlineOutlinedIcon color="white" />}
                endIcon={<KeyboardArrowDown color="white"/>}
                onClick={handleClick}
            ></NavbarItem>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <Link to="/company/create">
                    <MenuItem key="add-company">
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <Text translate="Add company" />
                    </MenuItem>
                </Link>

                <Divider />

                {companyContext?.currentCompany && (
                    <MenuItem
                        key="select-individual"
                        onClick={() => handleSelectCompany(null)}
                    >
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <Text translate="Individual client" />
                    </MenuItem>
                )}

                {/* List all companies */}
                {companies.map((company) => (
                    <MenuItem key={company.uuid}>
                        <ListItemIcon
                            onClick={() => handleSelectCompany(company)}
                        >
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText
                            onClick={() => handleSelectCompany(company)}
                        >
                            {company?.name}
                        </ListItemText>
                        {company.uuid === companyContext.currentCompany?.uuid && <Link to={`/companies/${company.uuid}`}>
                            <ListItemIcon className="pl-4">
                                <SettingsIcon />
                            </ListItemIcon>
                        </Link>}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
