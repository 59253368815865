import React, { useContext } from "react";
import Text from "../../../components/Text";
import {
    CheckCircle as CheckCircleIcon,
    Loop as LoopIcon,
} from "@mui/icons-material";
import { CurrentTheme } from "../../../context/CurrentUser";
import Card from "../../../components/Card";
import InnerCard from "../../../components/InnerCard";

export default function ContractGeneralInformation({ contract }) {

    const themeContext = useContext(CurrentTheme)

    return (
        <Card variant="small" title="Contract details">
            <div className="flex flex-col gap-3">
                <InnerCard>
                    <div className="flex flex-col text-gray-400 dark:text-gray-200 gap-3">
                        <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200 pb-2">
                            <Text translate="Contract name" className="w-1/3" />
                            <span className="font-medium text-black dark:text-white flex-grow">
                                {contract.name}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col text-gray-400 dark:text-gray-200 gap-3 my-5">
                        <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200 pb-2">
                            <Text translate="Start date" className="w-1/3" />
                            <span className="font-medium text-black dark:text-white flex-grow">
                                {contract.agreement_start}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col text-gray-400 dark:text-gray-200 gap-3 my-5">
                        <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200 pb-2">
                            <Text
                                translate="Expiration date"
                                className="w-1/3"
                            />
                            <span className="font-medium text-black dark:text-white flex-grow">
                                {contract.agreement_end}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col text-gray-400 dark:text-gray-200 gap-3 my-5">
                        <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200 pb-2">
                            <Text
                                translate="Billing period"
                                className="w-1/3"
                            />
                            <span className="font-medium text-black dark:text-white flex-grow">
                                {contract.settlement_type == 1 && (
                                    <Text translate="Daily" />
                                )}
                                {contract.settlement_type == 2 && (
                                    <Text translate="Monthly" />
                                )}
                                {contract.settlement_type == 3 && (
                                    <Text translate="Quaterly" />
                                )}
                                {contract.settlement_type == 4 && (
                                    <Text translate="Semiannually" />
                                )}
                                {contract.settlement_type == 5 && (
                                    <Text translate="Yearly" />
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col text-gray-400 dark:text-gray-200 gap-3 mt-5">
                        <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200 pb-2">
                            <Text translate="Status" className="w-1/3" />
                            <span className="font-medium text-black dark:text-white flex-grow">
                                {contract.status == 1 && (
                                    <div className="flex flex-row">
                                        <LoopIcon color="primary" />
                                        <Text
                                            className="pl-2"
                                            translate="In progress"
                                        />
                                    </div>
                                )}
                                {contract.status == 2 && (
                                    <div className="flex flex-row">
                                        <CheckCircleIcon color="warning" />
                                        <Text
                                            className="pl-2"
                                            translate="Payment required"
                                        />
                                    </div>
                                )}
                                {contract.status == 3 && (
                                    <div className="flex flex-row">
                                        <CheckCircleIcon color={themeContext.theme == 'dark' ? 'white' : "powerOn"} />
                                        <Text
                                            className="pl-2"
                                            translate="Active"
                                        />
                                    </div>
                                )}
                                {contract.status == 4 && (
                                    <div className="flex flex-row">
                                        <CheckCircleIcon color={themeContext.theme == 'dark' ? 'cardDark' : "disabled"} />
                                        <Text
                                            className="pl-2"
                                            translate="Inactive"
                                        />
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                </InnerCard>
            </div>
        </Card>
    );
}
