import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18n";
import i18next from "i18next";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ProximaNovaReg from './assets/fonts/Proxima-Nova-Regular.woff';

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: { main: colors.secondary, contrastText: "#fff" },
    white: {main: colors.white, contrastText: '#fff'},
    secondary: { main: colors.primary, contrastText: "#fff" },
    lightBlue: { main: colors.lightBlue, contrastText: "#fff" },
    powerOn: {main: colors.powerOn},
    powerOff: {main: colors.powerOff},
    cardDark: {main: colors.cardDark},
    gray: {main: colors.gray},
  },
  typography: {
    fontFamily: 'ProximaNovaReg, Roboto',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'ProximaNovaReg';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('ProximaNovaReg'), local('ProximaNovaReg'), url(${ProximaNovaReg}) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

const lang = localStorage.getItem("lang") || "en";
i18next.changeLanguage(lang);
document.documentElement.lang = lang;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
