import React from 'react';
import Text from '../../../../components/Text';
import MemoryIcon from '@mui/icons-material/Memory';
import StorageIcon from '@mui/icons-material/Storage';
import SpeedIcon from '@mui/icons-material/Speed';
import AutoAwesomeMotionSharpIcon from '@mui/icons-material/AutoAwesomeMotionSharp';
import { useContext } from 'react';
import { CurrentTheme } from '../../../../context/CurrentUser';
import InnerCard from '../../../../components/InnerCard';

export default function VmSpecification({vm}) {

    const themeContext = useContext(CurrentTheme)

    return (
        <>
            <InnerCard>
                <div className="flex flex-row justify-evenly">
                    <div className="flex flex-col gap-2 items-center">
                        <MemoryIcon color={themeContext.theme == 'dark' ? 'gray' : "primary"} />
                        <Text variant="h7" className="font-medium dark:text-white">
                            {vm.processors + ' vCPU'}
                        </Text>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                        <AutoAwesomeMotionSharpIcon color={themeContext.theme == 'dark' ? 'gray' : "primary"} />
                        <Text variant="h7" className="font-medium dark:text-white">
                            {vm.ram_size + 'GB RAM'}
                        </Text>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                        <StorageIcon color={themeContext.theme == 'dark' ? 'gray' : "primary"} />
                        <Text variant="h7" className="font-medium dark:text-white">
                            {(vm.disk_size?.size + vm.additional_disks) + ' GB'}
                        </Text>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                        <SpeedIcon color={themeContext.theme == 'dark' ? 'gray' : "primary"} />
                        <Text variant="h7" className="font-medium dark:text-white">
                            {vm.public_network_speed + ' Mb/s'}
                        </Text>
                    </div>
                </div>
            </InnerCard>
            {vm.main_subnet_ipv4?.ipv4 || vm.main_subnet_ipv6?.ipv6 ? 
                <div className="p-5 bg-authenticated dark:bg-authenticated-dark rounded-lg flex items-center justify-between">
                    <Text translate="Main IP address" variant="h7" className="font-medium dark:text-gray-200"/>
                    <div>
                        {vm.main_subnet_ipv4?.ipv4 ? 
                            <div className="flex justify-between">
                                <Text variant="h7" className="font-medium dark:text-white">
                                    {vm.main_subnet_ipv4?.ipv4?.address + ' / ' + vm.main_subnet_ipv4?.ipv4?.mask}
                                </Text>
                            </div>
                            : ''
                        }
                        {vm.main_subnet_ipv6?.ipv6 ? (
                                <div className="flex justify-end">
                                    <Text variant="h7" className="font-medium dark:text-white">
                                        {vm.main_subnet_ipv6?.ipv6?.address + ' / ' + vm.main_subnet_ipv6?.ipv6?.mask}
                                    </Text>
                                </div>
                            )
                            : ''
                        }
                    </div>
                </div>
                : ''
            }
        </>
    )
}
