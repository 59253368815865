import React from "react";
import Text from "../../components/Text";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function MessageListMobileItem({message, columns}) {
    return (
        <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md rounded-md text-primary dark:bg-gray-700 dark:text-white font-medium">
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-lightBlue mb-1">
                    <Text translate={columns[0].text}/>
                </div>
                <div className="text-right mb-1">
                {message.Title}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-lightBlue mb-1">
                    <Text translate={columns[1].text}/>
                </div>
                <div className="text-right mb-1">
                {message.StateID == 1 ? (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-50 text-yellow-400">
                        <Text translate="Open" variant="h8"/>
                    </span>
                ) : (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-400">
                        <Text translate="Closed" variant="h8"/>
                    </span>
                )}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-lightBlue mb-1">
                    <Text translate={columns[2].text}/>
                </div>
                <div className="text-right mb-1">
                    {message.TicketNumber}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-lightBlue mb-1">
                    <Text translate={columns[3].text}/>
                </div>
                <div className="text-right mb-1">
                    <Link to={'/messages/'+message.TicketID} className="text-lightBlue dark:text-secondary hover:text-black">
                        <Text translate="Details" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </div>
        </div>
    )
}
