import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "./api";
import { toast } from "react-toastify";
import i18n from "./i18n";
import Text from "./components/Text";
import { CurrentUser } from "./context/CurrentUser";
import { Redirect } from "react-router-dom";
const ApiInterceptors = () => {
    const history = useHistory();
    const currentUser = useContext(CurrentUser);

    useEffect(() => {
        api.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if(error.response !== undefined){
                    if (error.response.status === 500) {
                        toast.error(() => {
                            return (<><Text translate="Ups! Something went wrong!" /><Text translate="Please let us know about this via bok@dataspace.pl" /></>);
                        });
                    }
                    if (error.response.status === 503) {
                        toast.error(() => {
                            return (<><Text translate="This service is currently unavailable!" /></>);
                        });
                    }
                    if (error.response.status === 403) {
                        if(error.response.data.status !== undefined){
                            if (
                                error.response.data.status.message ===
                                "user.not_validated"
                            ) {
                                history.push("/verification");
                            }
                        }
                        if (
                            error.response.data.message ===
                            "rodo.client.acceptance_required"
                        ) {
                            toast.error(() => {
                                return (<><Text translate="Your account doesn't have accepted rodo!" /></>);
                            });
                            history.push("/rodo/acceptance");
                        }

                        if (
                            error.response.data.message ===
                            "regulation.acceptance"
                        ) {
                            toast.error(() => {
                                return (<><Text translate="You need to accept client or contract regulations!" /></>);
                            });
                            history.push("/regulations/acceptance");
                        }
                    }
                    if (error.response.status === 401) {
                        currentUser.setCurrentUser(null);
                        localStorage.removeItem("token");
                        history.push("/");
                    }
                }
                return Promise.reject(error);
            }
        );
    }, []);

    return null;
};

export default ApiInterceptors;
