import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom';
import { CurrentUser } from '../../context/CurrentUser';

export default function Guest({children, ...otherProps}) {
    const userContext = useContext(CurrentUser);
    if(userContext.currentUser) {
        return <Redirect to="/dashboard"/>
    }
    return (
        <div className="flex w-full h-screen justify-center items-center bg-white dark:bg-navbarDark" {...otherProps}>
            {children}
        </div>
    )
}
