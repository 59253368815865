import formatBytes from "../../../utils/formatBytes";

const cpuOptions = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (value, index) {
                    return value * 100 + "%";
                },
            },
        },
    },

    plugins: {
        tooltip: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return (
                        (parseFloat(tooltipItem.formattedValue.replace(/\s/g, "").replace(',', '.')* 100)).toFixed(1)  + "%"
                    );
                },
            },
        },
    },
};

const diskOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (value, index) {
                    return formatBytes(parseInt(value), 2, 1000);
                },
            },
        },
    },

    plugins: {
        tooltip: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return (
                        formatBytes(
                            parseInt(
                                tooltipItem.formattedValue.replace(/\s/g, "")
                            ),
                            2,
                            1000
                        ) +
                        " (" +
                        tooltipItem.formattedValue +
                        " B)"
                    );
                },
            },
        },
    },
};

const ramOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (value, index) {
                    return formatBytes(parseInt(value), 2, 1000);
                },
            },
        },
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return (
                        formatBytes(
                            parseInt(
                                tooltipItem.formattedValue.replace(/\s/g, "")
                            ),
                            2,
                            1000
                        ) +
                        " (" +
                        tooltipItem.formattedValue +
                        " B)"
                    );
                },
            },
        },
    },
};

const networkOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (value, index) {
                    return formatBytes(parseInt(value), 2, 1000);
                },
            },
        },
    },

    plugins: {
        tooltip: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return (
                        formatBytes(
                            parseInt(
                                tooltipItem.formattedValue.replace(/\s/g, "")
                            ),
                            2,
                            1000
                        ) +
                        " (" +
                        tooltipItem.formattedValue +
                        " B)"
                    );
                },
            },
        },
    },
};

export { cpuOptions, diskOptions, ramOptions, networkOptions };
