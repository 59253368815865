import React, { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import Text from "../../../../components/Text";
import { Button } from "@mui/material";
import Input from "../../../../components/Input";
import { useParams } from "react-router-dom";
import i18n from "../../../../i18n";
import { api } from "../../../../api";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";

export default function ServerPTRListItem({ ptr, updateTable }) {
    const { serverUuid } = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const [value, setValue] = useState(ptr.value);
    const [isFetching, setIsFetching] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleUpdateRecord = async () => {
        if (value != ptr.value) {
            setIsFetching(true);
            await api
                .put(`/server/${serverUuid}/ptr/${ptr.uuid}`, { value })
                .then((response) => {
                    if (response.status === 200) {
                        updateTable();
                    }
                })
                .catch((error) => {
                    setErrors(error?.response?.data?.errors ?? []);
                });
        }
        setIsFetching(false);
        setIsEditMode(false);
    };

    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap text-sm dark:text-gray-200">
                {ptr.name}
            </td>
            {isEditMode ? (
                <Input
                    name="value"
                    label={i18n.t("Value")}
                    variant="outlined"
                    value={value}
                    color="primary"
                    onChange={(e) => setValue(e.target.value)}
                    error={errors.value ?? false}
                    helperText={errors.value ?? false}
                />
            ) : (
                <td className="px-6 py-4 whitespace-nowrap text-sm dark:text-gray-200">
                    {ptr.value}
                </td>
            )}
            <td className="px-6 py-4 whitespace-nowrap text-sm dark:text-gray-200">
                {ptr.ttl}
            </td>
            <td className="px-6 py-4 whitespace-nowrap dark:text-gray-200">
                <div className="text-sm text-gray-900 flex justify-end">
                    {isEditMode === false ? (
                        <Button
                            className="text-lightBlue font-medium dark:text-white hover:opacity-90 flex items-center"
                            onClick={() => setIsEditMode(true)}
                        >
                            <CreateIcon />
                            <Text translate="Edit" />
                        </Button>
                    ) : (
                        <LoadingButton
                            startIcon={<CheckIcon />}
                            loading={isFetching}
                            loadingPosition="start"
                            className="text-lightBlue font-medium dark:text-white hover:opacity-90 flex items-center"
                            onClick={() => handleUpdateRecord()}
                        >
                            <Text translate="Save" />
                        </LoadingButton>
                    )}
                </div>
            </td>
        </>
    );
}
