import React from "react";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import {Link} from 'react-router-dom'
import Text from "../../components/Text";
import { useContext } from "react";
import { CurrentTheme } from "../../context/CurrentUser";

export default function ServerListItem({ server }) {
    const themeContext = useContext(CurrentTheme)

    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {server.name}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                {
                    server.power_status === "1" && (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            <Text translate="Power on" variant="h8"/>
                        </span>
                    )
                }
                {
                    server.power_status === "0" && (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            <Text translate="Power off" variant="h8"/>
                        </span>
                    )
                }
                {
                    server.power_status === "unknown" && (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-600">
                            <Text translate="Unknown" variant="h8"/>
                        </span>
                    )
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm">
                {server.remote ? (
                    <div className="flex items-center">
                        <CheckCircleIcon color={themeContext.theme == 'dark' ? "gray" : 'lightBlue'} />
                        <span className="ml-1 font-medium text-black dark:text-white">
                            <Text translate="Remote access configured" variant="h7"/>
                        </span>
                    </div>
                ) : (
                    <>
                        <CancelIcon color="powerOff" />
                        <span className="ml-1 font-medium text-black dark:text-white">
                            <Text translate="Remote access is not configured" variant="h7"/>
                        </span>
                    </>
                )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                    <Link
                        to={'contracts/'+server.contract_uuid}
                        className="text-lightBlue font-medium dark:text-white hover:opacity-90"
                    >
                            <Text translate="Contract details" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <Link to={'/servers/'+server.uuid} className="text-lightBlue dark:text-white hover:opacity-90">
                    <Text translate="Details" variant="h7"/>
                    <ChevronRight />
                </Link>
            </td>
        </>
    );
}
