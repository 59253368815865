import React, { useContext, useEffect } from "react";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import { CurrentTheme } from "../context/CurrentUser";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";

export default function CheckoutRedirect() {
    const themeContext = useContext(CurrentTheme);

    useEffect(() => {
        window.location.href = process.env.REACT_APP_PANEL_V1_URL + window.location.pathname;
    }, []);

    return (
        <Guest>
            <div className="flex flex-col items-center justify-between lg:w-1/4 w-full">
                <img
                    src={themeContext.theme == "dark" ? LogoDark : Logo}
                    className="mb-6 w-1/2"
                    alt="logo"
                />
                <div className="p-8 w-full shadow-lg dark:bg-authenticated-dark bg-white">
                    <Text
                        className="dark:text-white"
                        translate="Redirecting ..."
                    />
                </div>
            </div>
        </Guest>
    );
}
