import React, { useContext } from "react";
import NavbarItem from "./NavbarItem";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { CurrentTheme } from "../../../context/CurrentUser";
import Text from "../../Text";

export default function ThemeToggle({color, disableShadow}) {
    const themeContext = useContext(CurrentTheme);

    const handleClick = () => {
        const selectedTheme = themeContext.theme == 'dark' ? 'light' : 'dark';
        themeContext.setTheme(selectedTheme);
        localStorage.setItem('theme', selectedTheme);

        if(selectedTheme == 'light'){
            document.documentElement.classList.remove("dark");
        }else{
            document.documentElement.classList.add("dark");
        }
    }
    return (
        <NavbarItem
            disableShadow={disableShadow}
            Icon={ 
                themeContext.theme == 'light' ? 
                <>
                    <NightsStayIcon color={color ? color : "white"}/>
                    <ToggleOffIcon color={color ? color : "white"}/>
                </> :
                <>
                    <NightsStayIcon color={color ? color : "white"}/>
                    <ToggleOnIcon color={color ? color : "white"} />
                </>
            }
            onClick={handleClick}
        >
            <Text className={(color ? `text-${color}` : "text-white text-shadow") + " flex dark:text-white"} translate="Dark theme"/>
        </NavbarItem>
    );
}
