import React, { useContext, useState } from "react";
import Logo from "../../../assets/svg/logo.svg";
import LogoDark from "../../../assets/svg/logo_dark.svg";
import NavbarItem from "./NavbarItem";
import { CurrentCompany, CurrentTheme, CurrentUser } from "../../../context/CurrentUser";
import { logout } from "../../../auth";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageSelect from "./LanguageSelect";
import ThemeToggle from "./ThemeToggle";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CompanyDropdown from "./CompanyDropdown";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { toast } from "react-toastify";
import i18n from "../../../i18n";

export default function Navbar({isSidebarVisible, setIsSidebarVisible}) {
    const userContext = useContext(CurrentUser);
    const companyContext = useContext(CurrentCompany);
    const [menuVisible, setMenuVisible] = useState(false);
    const themeContext = useContext(CurrentTheme);
    const history = useHistory();
    const handleLogout = async () => {
        logout()
            .then(response => {
                if (response.status === 200) {
                    companyContext.setCurrentCompany(null);
                    userContext.setCurrentUser(null);
                    localStorage.removeItem("token");
                    toast.success(i18n.t('Logged out!'))
                    return history.push('/');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const toggleSideBar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    }

    const toggleNavBar = () => {
        setMenuVisible(!menuVisible);
    }

    return (
        <div className="w-full h-20 p-5 bg-topbar text-white dark:text-white flex items-center justify-between">
            <div className="flex flex-row md:hidden">
                <button
                    id="menuBtn"
                    className="hamburger block md:hidden focus:outline-none"
                    type="button"
                    onClick={toggleSideBar}
                >
                    <DashboardIcon color="white" />
                </button>
            </div>
            <div>
                <img src={LogoDark} className="h-8 ml-2" alt="Logo" />
            </div>
            <div className="flex flex-row">
                <button
                    id="menuBtn"
                    className="hamburger block md:hidden focus:outline-none"
                    type="button"
                    onClick={toggleNavBar}
                >
                    <MenuIcon color="white"/>
                </button>
            </div>
            <div className={menuVisible ? `z-10 flex flex-col items-start fixed top-20 right-4 bg-topbar` : `md:flex hidden`}>
                <ThemeToggle />
                <LanguageSelect />
                <CompanyDropdown />
                <Link to="/account">
                    <NavbarItem
                        text="Your account"
                        Icon={<WorkOutlineOutlinedIcon color="white" />}
                    />
                </Link>
                <NavbarItem
                    onClick={() => handleLogout()}
                    text="Logout"
                    Icon={<LockOutlinedIcon color="white" />}
                />
            </div>
        </div>
    );
}
