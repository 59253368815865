import { Button } from '@mui/material'
import React from 'react'
import InnerCard from '../../../components/InnerCard'
import i18n from '../../../i18n'
import { toast } from 'react-toastify';

export default function BackupFTPAccount({username, password}) {

    const showPassword = () => {
        navigator.clipboard.writeText(password)
        toast.success(i18n.t('Copied to clipboard'))
    }

    return (
        <InnerCard>
            <div className="flex justify-between">
                <div className="dark:text-gray-200">{username}</div>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={showPassword}
                >
                    {i18n.t("Password")}
                </Button>
            </div>
        </InnerCard>
    )
}
