import React from "react";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import {Link} from 'react-router-dom'
import Text from "../../components/Text";
import { useContext } from "react";
import { CurrentTheme } from "../../context/CurrentUser";

export default function BackupFTPListItem({ backupftp }) {
    const themeContext = useContext(CurrentTheme)

    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {backupftp.name}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                {
                    backupftp.active == true ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            <Text translate="Active" variant="h8"/>
                        </span>
                    ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            <Text translate="Inactive" variant="h8"/>
                        </span>
                    )
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {backupftp.domain}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                    <Link
                        to={'contracts/'+backupftp.contract_uuid}
                        className="text-lightBlue font-medium dark:text-white hover:opacity-90"
                    >
                            <Text translate="Contract details" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <Link to={'/backupftp/'+backupftp.uuid} className="text-lightBlue dark:text-white hover:opacity-90">
                    <Text translate="Details" variant="h7"/>
                    <ChevronRight />
                </Link>
            </td>
        </>
    );
}
