import React from "react";
import Text from "../../components/Text";
import { ChevronRight, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function VmListMobileItem({vm, columns}) {
    return (
        <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md rounded-md dark:bg-authenticated-dark dark:text-white font-medium">
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[0].text}/>
                </div>
                <div className="text-right mb-1">
                    {vm.name_external ? (vm.name_external + ' (' + vm.name_internal + ')') : vm.name_internal}
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
            <div className="text-secondary dark:text-gray-200 mb-1">
                <Text translate={columns[1].text}/>
            </div>
            <div className="text-right mb-1">
            {vm.power_status === "running" && (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    <Text translate="Power on" variant="h8"/>
                </span>
            )}
            {vm.power_status === "unknown" && (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                    <Text translate="Power unknown" variant="h8"/>
                </span>
            )}
            {(vm.power_status != "running" && vm.power_status != 'unknown' ) && (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    <Text translate="Power off" variant="h8"/>
                </span>
            )}
            </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[2].text}/>
                </div>
                <div className="text-right mb-1">
                    <Link
                        to={'contracts/'+vm.contract_uuid}
                        className="text-lightBlue dark:text-white"
                    >
                            <Text translate="Contract" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </div>
            <div className="flex w-full flex-row justify-between items-center dark:border-gray-400">
                <div className="text-secondary dark:text-gray-200 mb-1">
                    <Text translate={columns[3].text}/>
                </div>
                <div className="text-right mb-1">
                    <Link to={'/vps/'+vm.uuid} className="text-lightBlue dark:text-white">
                        <Text translate="Details" variant="h7"/>
                        <ChevronRight />
                    </Link>
                </div>
            </div>
        </div>
    )
}
