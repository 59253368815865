import React from 'react'
import VmRemoteAccess from "./VmRemoteAccess";
import VmPower from "./VmPower";
import VmOperatingSystems from "./VmOperatingSystems";
import VmSpecification from './VmSpecification';
import Card from '../../../../components/Card';
import PowerIcon from '../../../../components/PowerIcon';

export default function VmManagement({vm, getVm}) {
    return (
        <Card variant="small" title="Management" icon={<PowerIcon powerStatus={vm.power_status}/>}>
            {/* Vm info */}
            <VmSpecification vm={vm}/>
            {/* Remote access */}
            <VmRemoteAccess vmUuid={vm.uuid}/>
            {/* Power management */}
            <VmPower vm={vm} getVm={getVm}/>
            {/* Operating systems */}
            <VmOperatingSystems vm={vm}/>
        </Card>
    )
}
