import React, { useContext } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { authCheck } from '../auth'

export default function ProtectedRoute({component: Component, ...rest}) {
    const history = useHistory()
    return (
        <Route 
            {...rest} 
            render={props => {
                if(localStorage.getItem('token') && authCheck()) {
                    return <Component {...props}></Component>
                } else {
                    history.push("/");
                }
            }}
        />
    )
}