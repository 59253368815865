import { Button } from "@mui/material";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../api";
import Input from "../components/Input";
import Authenticated from "../components/layout/Authenticated";
import Guest from "../components/layout/Guest";
import Text from "../components/Text";
import i18n from "../i18n";

export default function Verification() {
    const [code, setCode] = useState();
    const history = useHistory();
    const [errors, setErrors] = useState([]);

    const handleSendCode = () => {
        api.get("/verification/send")
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t('Code has been sent'))
                }
            })
            .catch((errors) => {
                // check if too many requests
                console.error(errors);
            });
    };

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        return await api
            .post("/verification/check", { code })
            .then((response) => {
                if (response.status === 200) {
                    history.push("/dashboard");
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <Authenticated>
            <div className="w-full h-full flex justify-center items-center align-middle">
                <div className="bg-white dark:bg-navbarDark shadow-md">
                    <form
                        method="POST"
                        onSubmit={handleVerifyCode}
                        className="p-8"
                    >
                        <Text
                            variant="h4"
                            className="text-secondary dark:text-gray-200 pb-4"
                            translate="Multifactor verification"
                        />
                        <Input
                            name="code"
                            label={i18n.t("Code")}
                            variant="outlined"
                            color="primary"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className="w-full"
                            error={errors.code ?? false}
                            helperText={errors.code ?? false}
                        />
                        <div className="flex flex-row justify-between items-center mt-5">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                <Text translate="Check code" />
                            </Button>
                            <span
                                onClick={handleSendCode}
                                className="text-small text-lightBlue dark:text-white cursor-pointer hover:opacity-90"
                            >
                                Send code
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </Authenticated>
    );
}
