import React, { useContext, useEffect } from "react";
import Guest from "../components/layout/Guest";
import { CurrentTheme } from "../context/CurrentUser";
import Logo from "../assets/svg/logo.svg";
import LogoDark from "../assets/svg/logo_dark.svg";
import Text from "../components/Text";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../api";
import i18n from "../i18n";

export default function ActivateAccount() {
    const themeContext = useContext(CurrentTheme);
    const history = useHistory();
    const { token } = useParams();

    const handleActivate = async () => {
        await api.get(`/account/activate/${token}`)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t("Account activated successfully!"));
                    history.push("/");
                }
            })
            .catch((error) => {
                toast.error(i18n.t("Account activation failed. Contact support!"));
                history.push("/");
            });
    };

    useEffect(() => {
        handleActivate();
    }, []);


    return (
        <Guest>
            <div className="flex flex-col items-center justify-between lg:w-1/4 w-full">
                <img
                    src={themeContext.theme == "dark" ? LogoDark : Logo}
                    className="mb-6 w-1/2"
                    alt="logo"
                />
                <div className="p-8 w-full shadow-lg dark:bg-authenticated-dark bg-white">
                    <Text className="dark:text-white" translate="Account activation in progress ..." />
                </div>
            </div>
        </Guest>
    );
}
