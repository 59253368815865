import React, { useState } from 'react'
import Text from '../../../../components/Text';
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from 'react-toastify';
import i18n from '../../../../i18n';
import { useContext } from 'react';
import { CurrentTheme } from '../../../../context/CurrentUser';
import InnerCard from '../../../../components/InnerCard';

export default function ServerOperatingSystems({server}) {

    const [showServerUsername, setShowServerUsername] = useState(false);
    const [showServerPassword, setShowServerPassword] = useState(false);
    const themeContext = useContext(CurrentTheme)

    

    const handleCopy = (copy_text) => {
        navigator.clipboard.writeText(copy_text)
        toast.success(i18n.t('Copied'))
    };

    return (
        <InnerCard>
            <Text
                variant="h7"
                className="dark:text-gray-200"
                translate="Operating systems"
            />
            {server.operating_systems?.map(
                (system) => {
                    return (
                        <div key={system.id} className="flex flex-col text-gray-400 dark:text-gray-200 gap-3 my-5">
                            <div className="flex flex-row justify-between border-b-2 dark:border-gray-700 border-gray-200">
                                <Text
                                    translate="System"
                                    color="authenticated"
                                    className="w-1/3"
                                />
                                <span className="font-medium text-black dark:text-white flex-grow">
                                    {
                                        system.operating_system_name
                                    }
                                </span>
                                <span></span>
                            </div>
                            <div className="flex flex-row justify-between border-b-2 border-gray-200 dark:border-gray-700">
                                <Text
                                    translate="Login"
                                    className="w-1/3"
                                />
                                <span className="font-medium flex items-center text-black dark:text-white flex-grow">
                                    {showServerUsername
                                        ? system.admin_name
                                        : "********"}
                                </span>
                                <div className="flex flex-row">
                                    <span className="flex flex-row gap-3">
                                        <span
                                            className="flex items-center gap-1 cursor-pointer hover:opacity-90"
                                            onClick={() =>
                                                setShowServerUsername(
                                                    !showServerUsername
                                                )
                                            }
                                        >
                                            <Text
                                                className="text-secondary dark:text-white"
                                                variant="body2"
                                                translate="Show"
                                            />
                                            <LockOpenIcon color={themeContext.theme == 'dark' ? "white" : "lightBlue"} />
                                        </span>
                                        <span
                                            className="flex items-center gap-1 cursor-pointer hover:opacity-90"
                                            onClick={() => handleCopy(system.admin_name)}
                                        >
                                            <Text
                                                translate="Copy"
                                                className="text-secondary dark:text-white"
                                                variant="body2"
                                            />
                                            <ContentCopyIcon color={themeContext.theme == 'dark' ? "white" : "lightBlue"} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-row justify-between truncate border-b-2 border-gray-200 dark:border-gray-700">
                                <Text
                                    translate="Password"
                                    className="w-1/3"
                                />
                                <span className="font-medium truncate dark:text-white text-black  flex-grow">
                                    {showServerPassword
                                        ? system.password
                                        : "********"}
                                </span>
                                <div className="flex flex-row">
                                    <span className="flex flex-row gap-3">
                                        <span
                                            className="flex items-center gap-1 cursor-pointer hover:opacity-90"
                                            onClick={() =>
                                                setShowServerPassword(
                                                    !showServerPassword
                                                )
                                            }
                                        >
                                            <Text
                                                className="text-secondary dark:text-white"
                                                variant="body2"
                                                translate="Show"
                                            />
                                            <LockOpenIcon color={themeContext.theme == 'dark' ? "white" : "lightBlue"}/>
                                        </span>
                                        <span
                                            className="flex items-center gap-1 cursor-pointer hover:opacity-90"
                                            onClick={() => handleCopy(system.password)}
                                        >
                                            <Text
                                                translate="Copy"
                                                className="text-secondary dark:text-white"
                                                variant="body2"
                                            />
                                            <ContentCopyIcon color={themeContext.theme == 'dark' ? "white" : "lightBlue"} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                }
            )}
        </InnerCard>
    )
}
