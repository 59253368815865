import React, { useEffect, useState } from 'react'
import i18n from '../i18n'
import Text from './Text'
import Input from './Input'
import { Button, MenuItem, FormGroup, FormControlLabel, Checkbox, Switch } from '@mui/material'
import { api } from '../api'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Card from './Card'

export default function AddCompanyUser() {

    const [dialCodes, setDialCodes] = useState([]);
    const [verificationCode, setVerificationCode] = useState("PL");
    const [notificationCode, setNotificationCode] = useState("PL");
    const [email, setEmail] = useState('');
    const [notificationPhone, setNotificationPhone] = useState('');
    const [verificationPhone, setVerificationPhone] = useState('');
    const [errors, setErrors] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [clients, setClients] = useState([]);
    const { companyUuid } = useParams();

    const loadClients = () => {
        api.get(`/company/${companyUuid}/clients`)
        .then(
            response => {
                if(response.status === 200) {
                    setClients(response.data.data)
                }
            }
        ).catch(
            error => {
                console.error(error)
            }
        )
    }

    const handleChange = (type, uuid) => {
        api.put(`/company/${companyUuid}/clients/${uuid}`, {
            notification_type: type
        })
        .then(
            response => {
                if(response.status === 200) {
                    setClients(response.data.data)
                }
            }
        ).catch(
            error => {
                console.error(error)
            }
        )
    }

    useEffect(() => {
        api
          .get("/dial-code")
          .then((response) => {
            setDialCodes(response.data.data);
          })
          .catch((error) => {
            console.error(error);
          });
        loadClients();
    }, []);

    const handleCreate = e => {
        e.preventDefault()
        api.post(`/company/${companyUuid}/clients`, {
            email,
            is_owner: isOwner,
            can_edit: canEdit,
            phone_notification: notificationPhone,
            phone_verification: verificationPhone,
            cc_phone_verification: verificationCode,
            cc_phone_notification: notificationCode,
        }).then(
            response => {
                if(response.status === 200) {
                    setClients(response.data.data)
                    toast.success(i18n.t('User added'))
                }
            }
        ).catch(
            error => {
                toast.error(i18n.t('Cannot add user'))
                console.error(error)
            }
        )
        return true;
    }
    
    return (
        <Card>
            <Text
                translate="Add company user"
                variant="h5"
                className="dark:text-white"
            />
            <form
                method="PUT"
                onSubmit={handleCreate}
                className="flex flex-col w-full gap-5 mt-5 mb-8"
            >
                <Input
                    name="email"
                    label={i18n.t('Email')}
                    variant="outlined"
                    value={email}
                    color="primary"
                    onChange={(e) => setEmail(e.target.value)}
                    error={errors.nip ?? false}
                    helperText={errors.nip ?? false}
                />
                <div className="flex justify-between flex-row gap-3">
                    <Input
                        name="phoneCode"
                        variant="outlined"
                        value={verificationCode}
                        select
                        color="primary"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="w-1/3"
                    >
                    {dialCodes.map((dialCode) => (
                        <MenuItem key={dialCode.code} value={dialCode.code}>
                        {dialCode.code + " " + dialCode.dial_code}
                        </MenuItem>
                    ))}
                    </Input>
                    <Input
                        name="phone_notification"
                        label={i18n.t('Phone number for notifications')}
                        variant="outlined"
                        color="primary"
                        className="w-2/3"
                        value={notificationPhone}
                        onChange={(e) => setNotificationPhone(e.target.value)}
                        error={errors.phone_notification ?? false}
                        helperText={errors.phone_notification ?? false}
                    />
                </div>
                <div className="flex justify-between flex-row gap-3">
                    <Input
                        name="phoneCode"
                        variant="outlined"
                        value={notificationCode}
                        select
                        color="primary"
                        onChange={(e) => setNotificationCode(e.target.value)}
                        className="w-1/3"
                    >
                    {dialCodes.map((dialCode) => (
                        <MenuItem key={dialCode.code} value={dialCode.code}>
                        {dialCode.code + " " + dialCode.dial_code}
                        </MenuItem>
                    ))}
                    </Input>
                    <Input
                        name="phone_verification"
                        label={i18n.t('Phone number for verification')}
                        variant="outlined"
                        color="primary"
                        className="w-2/3"
                        value={verificationPhone}
                        onChange={(e) => setVerificationPhone(e.target.value)}
                        error={errors.phone_verification ?? false}
                        helperText={errors.phone_verification ?? false}
                    />
                </div>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={isOwner}
                                onChange={() => setIsOwner(!isOwner)}
                            />
                        }
                        label={<Text className="dark:text-white" translate="Company owner" />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={canEdit}
                                onChange={() => setCanEdit(!canEdit)}
                            />
                        }
                        label={<Text className="dark:text-white" translate="Can edit company information" />}
                    />
                </FormGroup>
                <div className="flex flex-row justify-between items-center">
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        {i18n.t("Add user")}
                    </Button>
                </div>
            </form>
            <Text
                translate="Company users"
                variant="h5"
                className="dark:text-white"
            />
            <div className="flex flex-col gap-3 mt-5 dark:text-white">
                <div className="w-100 flex justify-between items-center text-gray-500">
                    <div className="w-2/5">
                        {i18n.t('User')}
                    </div>
                    <div className="w-1/5">
                        {i18n.t('Marketing notifications')}
                    </div>
                    <div className="w-1/5">
                        {i18n.t('Technical notifications')}
                    </div>
                    <div className="w-1/5">
                        {i18n.t('Bookkeeping and payment notifications')}
                    </div>
                </div>
                {clients.map(client => {
                    return (
                        <div key={client.uuid} className="w-100 flex justify-between">
                                <div className="w-2/5">
                                    {client.email}
                                </div>
                                <div className="w-1/5">
                                    <Switch 
                                        checked={client.pivot.marketing}
                                        onChange={(e) => handleChange("marketing", client.uuid)}
                                    />
                                </div>
                                <div className="w-1/5">
                                    <Switch 
                                        checked={client.pivot.technical}
                                        onChange={(e) => handleChange("technical", client.uuid)}
                                    />
                                </div>
                                <div className="w-1/5">
                                    <Switch 
                                        checked={client.pivot.payments}
                                        onChange={(e) => handleChange("payments", client.uuid)}
                                    />
                                </div>
                        </div>
                    )
                })} 
            </div>
        </Card>
    )
}
