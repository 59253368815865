import React, { useState } from 'react'
import i18n from '../i18n';
import Text from './Text';

export default function IPTable({rows}) {

    const MAX_RECORDS = 5;

    const prefixToMask = (prefix) => {
        const mask = [];
        
        for (let i = 0; i < 4; i++) {
            const n = Math.min(prefix, 8);
            mask.push(256 - Math.pow(2, 8 - n));
            prefix -= n;
        }
        return mask.join(".");
    }

    const [maxRecords, setMaxRecords] = useState(MAX_RECORDS)
    const slice = rows.slice(0, maxRecords)

    return (
        <div>
            <div className="flex flex-col">
                <div className="align-middle inline-block w-full overflow-x-auto">
                    <div className="overflow-hidden sm:rounded-t-lg">
                        <table className="w-full">
                        {!rows.length ? (
                            <tbody>
                                <tr className="text-center text-sm font-medium text-black">
                                    <td
                                        colSpan={4}
                                        className="px-6 py-4 text-center rounded-lg bg-authenticated dark:text-white dark:bg-authenticated-dark"
                                    >
                                        <Text translate="No networks" variant="h7" className="font-medium"/>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <>
                                <thead className="bg-authenticated dark:bg-authenticated-dark hidden lg:table-header-group">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-4 py-2 w-1/4 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                            <Text translate="Address / Mask"/>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 w-1/4 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                            <Text translate="Mask"/>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 w-1/4 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                            <Text translate="Gateway"/>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 w-1/4 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                            <Text translate="Type"/>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white hidden lg:table-row-group">
                                    {slice.map((row, rowIndex) => {
                                        return (
                                            <tr
                                                key={row.uuid}
                                                className={rowIndex%2 == 0 ? 'dark:bg-navbarDark bg-white ' : 'dark:bg-authenticated-dark bg-authenticated ' + "dark:text-white"}
                                            >
                                                <td className="whitespace-nowrap py-3 px-4">
                                                    <div className="flex items-center">
                                                        <div className="text-sm font-medium text-black dark:text-white">
                                                            {row.family == 'IPv4' ? (
                                                                row.address + ' / ' + row.mask
                                                            ) : (
                                                                row.address
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-4">
                                                    <div className="flex items-center">
                                                        <div className="text-sm font-medium text-black dark:text-white">
                                                            {prefixToMask(row.mask)}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-4">
                                                    <div className="flex items-center">
                                                        <div className="text-sm font-medium text-black dark:text-white">
                                                            {row.gateway[0]?.ip_address}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-4">
                                                    <div className="flex items-center">
                                                        <div className="text-sm font-medium capitalize text-black dark:text-white">
                                                            {i18n.t(row.type)}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <div className="block lg:hidden w-full">
                                    {slice.map((row, index) => {
                                        return (
                                            <div
                                                key={index}
                                            >
                                                <div className="flex mb-5 flex-col gap-2 p-4 bg-authenticated shadow-md text-primary dark:bg-gray-700 dark:text-white font-medium">
                                                    <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                                                        <div className="text-lightBlue mb-1">
                                                            <Text translate="Address / Mask"/>
                                                        </div>
                                                        <div className="text-right mb-1">
                                                            {row.address}
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                                                        <div className="text-lightBlue mb-1">
                                                            <Text translate="Mask"/>
                                                        </div>
                                                        <div className="text-right mb-1">
                                                            {prefixToMask(row.mask)}
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                                                        <div className="text-lightBlue mb-1">
                                                            <Text translate="Gateway"/>
                                                        </div>
                                                        <div className="text-right mb-1">
                                                            {row.gateway[0]?.ip_address}
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full flex-row justify-between items-center border-b-2 border-gray-200 dark:border-gray-400">
                                                        <div className="text-lightBlue mb-1">
                                                            <Text translate="Type"/>
                                                        </div>
                                                        <div className="text-right mb-1">
                                                            {i18n.t(row.type)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        )}    
                        </table>
                        <div className="flex w-100 justify-center mt-3 items-center">
                            {rows.length > maxRecords && (
                                    <button className="text-white text-xs bg-secondary px-5 py-2 rounded-full font-medium uppercase" onClick={() => setMaxRecords(rows.length)}>
                                        {i18n.t('Show more') + ' (' + parseInt(rows.length - maxRecords) + ')'}
                                    </button>
                                
                            )}
                            {rows.length > MAX_RECORDS && rows.length == maxRecords && (
                                <button className="text-white text-xs bg-secondary px-5 py-2 rounded-full font-medium uppercase" onClick={() => setMaxRecords(5)}>
                                    {i18n.t('Show less')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
