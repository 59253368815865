import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { api } from "../../api";
import Card from "../../components/Card";
import Authenticated from "../../components/layout/Authenticated";
import Pagination from "../../components/Pagination/Pagination";
import Table from "../../components/Table";
import TableSearch from "../../components/TableSearch";
import i18n from "../../i18n";
import ContractListItem from "./ContractListItem";
import ContractListMobileItem from "./ContractListMobileItem";

export default function ContractList() {
    const [contracts, setContracts] = useState([]);
    const [search, setSearch] = useState("");
    const [isFetching, setIsFetching] = useState(true);
    const [meta, setMeta] = useState([]);
    const [sort, setSort] = useState("name_external");
    const [order, setOrder] = useState("asc");
    const [page, setPage] = useState(1);
    const firstUpdate = useRef(true);

    const columns = [
        { field: "name", text: "Name", filterable: true, filter_name: 'name_external' },
        { field: "agreement_end", text: "Expiration date", filterable: true, filter_name: 'agreement_end' },
        { field: "settlement_type", text: "Billing period", filterable: true, filter_name: 'settlement_type' },
        { field: "status", text: "Status", filterable: true, filter_name: 'status' },
        { field: "details", text: "Details" }
    ];

    const getContracts = async () => {
        setIsFetching(true)
        await api
            .get("/contract", {
                params: {
                    search: search,
                    page: page,
                    limit: 10,
                    order,
                    sort
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsFetching(false);
                    setContracts(response.data.data);
                    setMeta(response.data.meta);
                }
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };
    //search
    useEffect(() => {
        if(firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            setPage(1);
            getContracts();
        }, 500);
        setPage(1);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    //pagination
    useEffect(() => {
        getContracts();
    }, [page, order, sort]);

    return (
        <Authenticated>
            <div className="text-2xl font-medium flex items-center">
                <Link to="/contracts">
                    <h2 className="p-1 dark:text-white">{i18n.t('Contracts')}</h2>
                </Link>
            </div>

            <Card>
                <TableSearch
                    search={search}
                    setSearch={setSearch}
                />
                <Table
                    isFetching={isFetching}
                    columns={columns}
                    rows={contracts}
                    keyName="uuid"
                    renderItem={(item) => <ContractListItem contract={item}/>}
                    renderMobileItem={(item) => <ContractListMobileItem columns={columns} contract={item}/>}
                    setSort={setSort}
                    sort={sort}
                    order={order}
                    setOrder={setOrder}
                />
                {contracts && <Pagination meta={meta} setPage={setPage} />}
            </Card>
        </Authenticated>
    );
}
