import axios from "axios";

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: parseInt(process.env.REACT_APP_API_TIMEOUT, 10),
    headers: {
        Accept: "application/json",
        "Accept-Language": localStorage.getItem("lang") || "en",
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token") || "";
    const companyUuid = localStorage.getItem("company_uuid") || "";
    const language = localStorage.getItem("lang") || "en";
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        "Accept-Language": language,
    };
    if (companyUuid !== "") {
        config.params = { ...config.params, company_id: companyUuid };
    }
    return config;
});


