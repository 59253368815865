import React, { useContext } from 'react'
import { ChevronRight } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { CurrentTheme } from '../../../context/CurrentUser';

export default function BackupFTPBreadcrumbs({backupftp}) {

    const themeContext = useContext(CurrentTheme);
    //@todo add change name option
    
    return (
        <div className="flex flex-row items-center">
            <div className="text-2xl font-medium flex items-center dark:text-white">
                <Link to="/backupftp">
                    <h2 className="p-1">{i18n.t('BackupFTP')}</h2>
                </Link>
                <ChevronRight color={themeContext.theme == 'dark' ? 'gray' : "primary"} />
                <h2 className="p-1">
                    {backupftp.name}
                </h2>
            </div>
        </div>
    )
}