import React from 'react'
import { ChevronRight } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { api } from '../../../api';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { CurrentTheme } from '../../../context/CurrentUser';
import Input from '../../../components/Input';
export default function ServerBreadcrumbs({serverName, setServerName, server, getServer}) {

    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState([]);
    const themeContext = useContext(CurrentTheme);

    const handleChangeName = async () => {
        setErrors([]);
        await api
            .put(`/server/${server.uuid}`, { name: serverName })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t('Name changed successfully'))
                    setIsEditMode(false);
                    getServer();
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <div className="flex flex-row items-center">
            {isEditMode ? (
                <div className="flex gap-3">
                    <Input
                            name="name"
                            label={i18n.t("Name")}
                            variant="outlined"
                            type="text"
                            value={serverName}
                            color="primary"
                            onChange={(e) => setServerName(e.target.value)}
                            error={errors.name ?? false}
                            helperText={errors.name ?? false}
                        />
                    <button
                        onClick={handleChangeName}
                        type="submit"
                    >
                        <CheckIcon color="powerOn" />
                    </button>
                    <button
                        onClick={() => setIsEditMode(false)}
                    >
                        <ClearIcon color="powerOff" />
                    </button>
                </div>
            ) : (
                <>
                    <div className="text-md lg:text-2xl font-medium flex items-center dark:text-white">
                        <Link to="/servers">
                            <h2 className="p-1">{i18n.t('Servers')}</h2>
                        </Link>
                        <ChevronRight color={themeContext.theme == 'dark' ? 'gray' : "primary"} />
                        <h2 className="p-1">
                            {server.name}
                        </h2>
                    </div>
                    <span
                        className="hover:opacity-90 text-md lg:text-lg pl-2 text-secondary dark:text-white flex items-center font-medium cursor-pointer"
                        onClick={() => setIsEditMode(true)}
                    >
                        <CreateIcon />
                        <span className="pl-1">
                            {i18n.t("Change name")}    
                        </span>
                    </span>
                </>
            )}
        </div>
    )
}