import { Button, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { toast } from "react-toastify";
import { api } from "../../api";
import Input from "../../components/Input";
import Text from "../../components/Text";
import { CurrentUser } from "../../context/CurrentUser";
import i18n from "../../i18n";

export default function Change2FA() {
    const userContext = useContext(CurrentUser);

    const [verificationMethods, setVerificationMethods] = useState([
        {value: 'none', name: 'None'},
        {value: 'sms', name: 'SMS'},
        {value: '2fa', name: '2FA'},
    ]);
    const [image, setImage] = useState(null)
    const [imageHtml, setImageHtml] = useState()
    const [secret, setSecret] = useState(null)
    const [email, setEmail] = useState("");
    const [verification_method, setVerificationMethod] = useState('none');
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setImageHtml(image)
    }, [image])

    useEffect(() => {
        setVerificationMethod(userContext.currentUser?.verification_method ?? 'none');
    }, [userContext.currentUser]);

    

    const handleChange2FA = (e) => {
        e.preventDefault();
        setErrors([])
        api.post("/verification/change", {
            password,
            verification_method: verification_method == 'none' ? null : verification_method
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t('Verification method changed'))
                    setPassword('')
                    setImage(response.data.image)
                    setSecret(response.data.secret)
                    userContext.setCurrentUser({...userContext.currentUser, verification_method});
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <form
            method="POST"
            onSubmit={handleChange2FA}
            className="flex flex-col w-full gap-5 mt-10"
        >
            {/*
                * This is hidden for user, but auto-filled by web browser,
                * without this the Phone field is autofilled to be email address by a browser
                */}
            <div className="hidden">
                <Input
                    className="hide"
                    name="email"
                    label={i18n.t('Email')}
                    variant="outlined"
                    value={email}
                    color="primary"
                    onChange={(e) => setEmail(e.target.value)}
                    error={errors.email ? true : false}
                    helperText={errors.email ?? false}
                />
            </div>
            <div className="flex justify-between flex-col md:flex-row gap-3">
                <Input
                    name="verificationMethod"
                    variant="outlined"
                    label={i18n.t("Verification method")}
                    value={verification_method}
                    select
                    color="primary"
                    onChange={(e) => setVerificationMethod(e.target.value)}
                    className="w-full"
                >
                    {verificationMethods.map((verificationMethod) => (
                        <MenuItem
                            key={verificationMethod.value}
                            value={verificationMethod.value}
                        >
                            {verificationMethod.name}
                        </MenuItem>
                    ))}
                </Input>
            </div>
            <Input
                name="password"
                label={i18n.t("Password")}
                variant="outlined"
                type="password"
                value={password}
                color="primary"
                onChange={(e) => setPassword(e.target.value)}
                error={errors.password ? true : false}
                helperText={errors.password ?? false}
            />
            {image ? (
                <div className="flex flex-col justify-center items-center">
                    <div dangerouslySetInnerHTML={{ __html: imageHtml }}></div>
                    <p className="dark:text-white mt-2">{secret}</p>
                </div>
            ) : null}
            <div className="flex flex-row justify-between items-center mt-5">
                <Button color="primary" size="large" variant="contained" type="submit">
                    <Text translate="Change verification method"/>
                </Button>
            </div>
        </form>
    );
}
