import React from "react";

export default function Modal({isOpen, setIsOpen, children}) {
    return (
        <div
            className={!isOpen ? 'hidden' : '' + " fixed z-10 inset-0 overflow-y-auto"}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
                    aria-hidden="true"
                    onClick={() => setIsOpen(false)}
                ></div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    {children}
                </div>
            </div>
        </div>
    );
}
