import { Button } from "@mui/material";
import React, { useState } from "react";
import Input from "../../../components/Input";
import Text from "../../../components/Text";
import i18n from "../../../i18n";

export default function ContractEntrustedPersonalDataFormRow({
    categories,
    setCategories,
    category,
    entries,
}) {
    const [subcategory, setSubcategory] = useState("");
    return (
        <>
            <Text variant="h6" className="dark:text-white">{i18n.t('Category')} {category}:</Text>
            {entries.map((entry) => {
                return <Text variant="h7" className="dark:text-white">- {entry}</Text>;
            })}

            <Input
                className="w-full"
                label={i18n.t("Subcategory like: name, address, email, phone number")}
                value={subcategory}
                onChange={(e) => setSubcategory(e.target.value)}
            />
            <Button
                onClick={() => {
                    if (subcategory.length > 0) {
                        setCategories({
                            ...categories,
                            [category]: [...entries, subcategory],
                        });
                        setSubcategory("");
                    }
                }}
            >
                {i18n.t("Add subcategory")}
            </Button>
        </>
    );
}
