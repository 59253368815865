import { toast } from "react-toastify"
import { api } from "./api"

const login = async (email, password) => {
    return await api.post('/auth/login', {
        email: email,
        password: password
    })
}

const authCheck = async () => {
    return await api.get('/auth/check').then(response => {
        if(response === 200) {
            return true
        }
    }).catch(error => {
        return false
    })
}

const accountInfo = async () => {
    return await api.get('/account')
}

const companyInfo = async (company_uuid) => {
    return await api.get(`/company/${company_uuid}`)
}

const logout = async () => {
    return await api.post('/auth/logout')
}

const refresh = () => {
    api.post('/auth/refresh').then(
        response => {
            if(response.status === 200) {
                localStorage.setItem('token', response.data.access_token)
            }
        }
    ).catch(
        error => {
            toast.error(error.response.data.message)
        }
    )
}

export {login, logout, authCheck, refresh, accountInfo, companyInfo}