import React, { useContext } from 'react'
import { ChevronRight } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { CurrentTheme } from '../../../context/CurrentUser';

export default function MessageBreadcrumbs({message, create=false}) {

    const themeContext = useContext(CurrentTheme)

    return (
        <div className="text-md lg:text-2xl font-medium flex items-center dark:text-white">
            <Link to="/messages">
                <h2 className="p-1">{i18n.t('Messages')}</h2>
            </Link>
            <ChevronRight color={themeContext.theme == 'dark' ? 'gray' : "primary"} />
            <h2 className="p-1">
                {create == true ? i18n.t('Create new message') : message[0]?.Title + ' (' + message[0]?.TicketNumber + ')'}
            </h2>
        </div>
    )
}
