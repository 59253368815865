import { ChevronRight, CheckCircle as CheckCircleIcon, Loop as LoopIcon } from "@mui/icons-material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Text from "../../components/Text";
import { CurrentTheme } from "../../context/CurrentUser";

export default function ContractListItem({ contract }) {

    const themeContext = useContext(CurrentTheme);

    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {contract.name}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {contract.agreement_end}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {contract.settlement_type == 1 && <Text translate="Daily" />}
                            {contract.settlement_type == 2 && <Text translate="Monthly" />}
                            {contract.settlement_type == 3 && <Text translate="Quaterly" />}
                            {contract.settlement_type == 4 && <Text translate="Semiannually" />}
                            {contract.settlement_type == 5 && <Text translate="Yearly" />}
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-gray-200">
                            {contract.status == 1 && 
                                <div className="flex flex-row">
                                    <LoopIcon color="primary"/>
                                    <Text className="pl-2" translate="In progress" />
                                </div>
                            }
                            {contract.status == 2 && 
                                <div className="flex flex-row">
                                    <CheckCircleIcon color="warning" />
                                    <Text className="pl-2" translate="Payment required" />
                                </div>
                            }
                            {contract.status == 3 && 
                                <div className="flex flex-row">
                                    <CheckCircleIcon color={themeContext.theme == 'dark' ? 'white' : "powerOn"} />
                                    <Text className="pl-2" translate="Active" />
                                </div>
                            }
                            {contract.status == 4 && 
                                <div className="flex flex-row">
                                    <CheckCircleIcon color={themeContext.theme == 'dark' ? 'cardDark' : "disabled"}/>
                                    <Text className="pl-2" translate="Inactive" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <Link to={'/contracts/'+contract.uuid} className="text-lightBlue dark:text-white hover:opacity-90">
                    <Text translate="Details" variant="h7"/>
                    <ChevronRight />
                </Link>
            </td>
        </>
    );
}
