import React, { useState, useEffect } from "react";
import Text from "../../Text";
import { Link } from "react-router-dom";

export default function SidebarItem({
    Icon,
    children,
    text,
    url = "/",
    redirect,
    ...otherProps
}) {
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        setVisible(window.location.pathname.search(url) === 0)
    }, [])

    return redirect ? (
        <a
            href={url}
            className="w-100 h-16 flex flex-row p-4 justify-between items-center"
            {...otherProps}
        >
            <div className="flex flex-row">
                {Icon && Icon}
                <Text
                    color="white"
                    sx={{ fontWeight: 500 }}
                    className="px-4 text-shadow"
                    translate={text}
                />
            </div>
        </a>
    ) : (
        <Link
            to={url}
            className={"w-100 h-16 flex flex-row p-4 hover:bg-white hover:bg-opacity-30 justify-between items-center " + (visible ? 'bg-white bg-opacity-30' : '')}
            {...otherProps}
        >
            <div className="flex flex-row text-shadow">
                {Icon && Icon}
                <Text
                    color="white"
                    sx={{ fontWeight: 500 }}
                    className="px-4"
                    translate={text}
                />
            </div>
        </Link>
    );
}
