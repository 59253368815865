import { Button, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../../api";
import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import Text from "../../../../components/Text";
import i18n from "../../../../i18n";
import ImportExportSharpIcon from "@mui/icons-material/ImportExportSharp";
import { toast } from "react-toastify";
import InnerCard from "../../../../components/InnerCard";
import { LoadingButton } from "@mui/lab";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function ServerRemoteAccess({ server }) {
    const [currentIp, setCurrentIp] = useState("");
    const [anyIp, setAnyIp] = useState(false);
    const [ipmi, setIpmi] = useState();
    const [isIpDisabled, setIsIpDisabled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const getCurrentIp = async () => {
        await api
            .get("/ipmi/current_ip")
            .then((response) => {
                if (response.status === 200) {
                    setCurrentIp(response.data.data.ip);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleShowModal = async () => {
        await getCurrentIp();
        await api
            .get(`/server/${server.uuid}/ipmi`)
            .then((response) => {
                if (response.status === 200) {
                    setIpmi(response.data.data.ipmi);
                    if (response.data.data.ipmi.nat_fqdn) {
                        setIsIpDisabled(true);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
        setIsOpen(true);
    };

    const handleGetAccess = async () => {
        setIsLoading(true)
        await api
            .post(`/server/${server.uuid}/ipmi/nat/start`, {
                allowed_ip: currentIp,
            })
            .then(async (response) => {
                await api
                    .get(`/server/${server.uuid}/ipmi`)
                    .then((response) => {
                        if (response.status === 200) {
                            setIpmi(response.data.data.ipmi);
                            setIsIpDisabled(true);
                            setIsLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setIsLoading(false)
                    });
                setIsLoading(false)
            })
            .catch((error) => {
                if(error.response.status === 503){
                    if (error.response.data.message === "company.server.ipmi.nat.response.too_many_open_nat_connections") {
                        toast.error(i18n.t("You have too many open NAT connections. Please close one or more NAT connections before opening a new one. Limit is 10 per company."));
                    }
                }
                console.error(error);
                setIsLoading(false)
            });
        
    };

    const handleDisableAccess = async () => {
        setIsLoading(true)
        await api
            .post(`/server/${server.uuid}/ipmi/nat/stop`)
            .then((response) => {
                setIpmi(null);
                setIsIpDisabled(false);
                setIsLoading(false)
            })
            .catch((errors) => {
                console.error(errors)
                setIsLoading(false)
            });
    };

    const handleChangeAnyIp = () => {
        if (!isIpDisabled) {
            if (!anyIp) {
                setCurrentIp("0.0.0.0");
            } else {
                getCurrentIp();
            }
            setAnyIp(!anyIp);
        } else {
            toast.error(
                "You should disable current remote access, to change IP!"
            );
        }
    };

    return (
        <InnerCard>
            <div className="flex justify-between items-center">
                <Text
                    variant="h7"
                    className="font-medium dark:text-white"
                    translate="Remote access"
                />
                <button
                    className="text-white w-32 text-xs bg-secondary px-5 py-2 rounded-full font-medium uppercase"
                    onClick={handleShowModal}
                >
                    {i18n.t("Get access")}
                </button>
                <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                    <div className="dark:bg-gray-800 bg-gray-50 p-4">
                        <Text
                            className="dark:text-gray-200 text-secondary"
                            variant="h5"
                            translate="Remote access"
                        />
                        <div className="pt-4">
                            <Input
                                className="w-full"
                                label={i18n.t(
                                    "IP address that will connect to remote access:"
                                )}
                                value={currentIp}
                                onChange={(e) => setCurrentIp(e.target.value)}
                                disabled={isIpDisabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            className="dark:text-gray-400"
                                            position="end"
                                        >
                                            <div
                                                className="flex cursor-pointer"
                                                onClick={handleChangeAnyIp}
                                            >
                                                <ImportExportSharpIcon />
                                                <Text
                                                className="dark:text-gray-400"
                                                    translate={
                                                        anyIp
                                                            ? "Anyone"
                                                            : "Yours"
                                                    }
                                                />
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        {(!ipmi || !ipmi.nat_fqdn) && (
                            <div className="pt-4 w-full">
                                <LoadingButton
                                    startIcon={<CheckIcon/>}
                                    loadingPosition="start"
                                    loading={isLoading}
                                    onClick={handleGetAccess}
                                    variant="contained"
                                >
                                    {i18n.t("Enable remote access")}
                                </LoadingButton>
                            </div>
                        )}
                        {ipmi && ipmi.nat_fqdn && (
                            <div className="pt-4 flex flex-col w-full">
                                <div>
                                    <Text
                                        className="dark:text-white text-secondary"
                                        variant="h6"
                                        translate="Remote access information"
                                    />
                                </div>
                                <div className="p-4 flex flex-col gap-2">
                                    <div className="w-full flex flex-row justify-between">
                                        <Text
                                            className="dark:text-secondary text-gray-400"
                                            translate="Remote access IP"
                                        />
                                        <Text className="dark:text-white">
                                            {ipmi.nat_ip}
                                        </Text>
                                    </div>
                                    <div className="w-full flex flex-row justify-between">
                                        <Text
                                            className="dark:text-secondary text-gray-400"
                                            translate="Remote access FQDN"
                                        />
                                        <Text className="dark:text-white">
                                            {ipmi.nat_fqdn}
                                        </Text>
                                    </div>
                                    <div className="w-full flex flex-row justify-between">
                                        <Text
                                            className="dark:text-secondary text-gray-400"
                                            translate="Login"
                                        />
                                        <Text className="dark:text-white">
                                            {ipmi.operator_name}
                                        </Text>
                                    </div>
                                    <div className="w-full flex flex-row justify-between">
                                        <Text
                                            className="dark:text-secondary text-gray-400"
                                            translate="Password"
                                        />
                                        <Text className="dark:text-white">
                                            {ipmi.operator_pass}
                                        </Text>
                                    </div>
                                </div>
                                <div className="w-full flex justify-between">
                                    <LoadingButton
                                        startIcon={<CloseIcon/>}
                                        loadingPosition="start"
                                        loading={isLoading}
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleDisableAccess}
                                    >
                                        {i18n.t("Disable remote access")}
                                    </LoadingButton>

                                    {ipmi &&
                                        ipmi.autologin &&
                                        ipmi.operator_name &&
                                        ipmi.operator_pass &&
                                        ipmi.nat_fqdn && (
                                            <form
                                                action={`https://${ipmi.nat_fqdn}/cgi/login.cgi`}
                                                method="post"
                                                target="_blank"
                                            >
                                                <input
                                                    type="hidden"
                                                    name="name"
                                                    value={ipmi.operator_name}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="pwd"
                                                    value={ipmi.operator_pass}
                                                />
                                                <LoadingButton
                                                    startIcon={<OpenInNewIcon/>}
                                                    loadingPosition="start"
                                                    loading={isLoading}
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    {i18n.t(
                                                        "Connect to remote access"
                                                    )}
                                                </LoadingButton>
                                            </form>
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        </InnerCard>
    );
}
