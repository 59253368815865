import React from 'react'

export default function LoginLogListItem({logon}) {
    return (
        <>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {logon.date}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {logon.device ?? '-'}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {logon.ip}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {logon.browser ?? '-'}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <div className="flex items-center">
                    <div>
                        <div className="text-sm font-medium text-black dark:text-white">
                            {logon.version ?? '-'}
                        </div>
                    </div>
                </div>
            </td>
        </>
    );
}
