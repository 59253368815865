import React, { useContext } from 'react'
import SearchIcon from "@mui/icons-material/Search";
import { colors } from '../colors';
import { CurrentTheme } from '../context/CurrentUser';
import i18n from '../i18n';
import Input from './Input';


export default function TableSearch({search, setSearch, Button}) {
    const themeContext = useContext(CurrentTheme);
    return (
        <div className="flex flex-row justify-between items-center mb-5 px-1">
            <div className="w-1/2 lg:w-2/3 flex-row flex items-center">
                <Input variant="standard" type="search" value={search} onChange={e => {setSearch(e.target.value)}} placeholder={i18n.t('Search')} inputProps={{style: {
                    color: themeContext.theme == 'dark' ? colors.white : '',
                    borderBottom: '1px solid white',
                }}}
                color={themeContext.theme == 'dark' ? "gray" : 'lightBlue'}
                />
                <SearchIcon fontSize="medium" color={themeContext.theme == 'dark' ? 'white' : 'disabled'}/>
            </div>
            {Button}
        </div>
    )
}
