import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "./App.css";
import { accountInfo, companyInfo } from "./auth";
import ProtectedRoute from "./components/ProtectedRoute";
import {
  CurrentCompany,
  CurrentUser,
  CurrentLanguage,
  CurrentTheme,
} from "./context/CurrentUser";
import ServerDetails from "./modules/servers/ServerDetails/ServerDetails";
import ServerList from "./modules/servers/ServerList";
import Account from "./pages/Account";
import CompanyCreate from "./pages/CompanyCreate";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import PasswordReset from "./pages/PasswordReset";
import Register from "./pages/Register";
import { withTranslation } from "react-i18next";
import InvoiceList from "./modules/invoices/InvoiceList";
import ContractList from "./modules/contracts/ContractList";
import ContractDetails from "./modules/contracts/ContractDetails";
import VmDetails from "./modules/vms/VmDetails/VmDetails";
import VmList from "./modules/vms/VmList";
import MessageList from "./modules/messages/MessageList";
import MessageDetails from "./modules/messages/MessageDetails/MessageDetails";
import MessageCreate from "./modules/messages/MessageCreate";
import PaymentStatus from "./pages/PaymentStatus";
import CompanyUpdate from "./pages/CompanyUpdate";
import LoginLogList from "./modules/loginlog/LoginLogList";
import Verification from "./pages/Verification";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ApiInterceptors from "./ApiInterceptors";
import { colors } from "./colors";
import CookieConsent from "./components/CookieConsent";
import CompanySelect from "./pages/CompanySelect";
import AdapterDayJs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import RodoAcceptance from "./pages/RodoAcceptance";
import RegulationsAcceptance from "./pages/RegulationsAcceptance";
import BackupFTPDetails from "./modules/backupftp/BackupFTPDetails/BackupFTPDetails";
import BackupFTPList from "./modules/backupftp/BackupFTPList";
import ActivateAccount from "./pages/ActivateAccount";
import CheckoutRedirect from "./pages/CheckoutRedirect";
import PasswordSet from "./pages/PasswordSet";

function App() {
  const [currentUser, setCurrentUser] = useState();
  const [currentCompany, setCurrentCompany] = useState();
  const [language, setLanguage] = useState();
  const [theme, setTheme] = useState();

  useEffect( async () => {
    if (localStorage.getItem("lang")) {
      setLanguage(localStorage.getItem("lang") ?? "en");
    }
    if (
      localStorage.getItem("theme") === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    setTheme(localStorage.getItem("theme") ?? "light");

    if (localStorage.getItem("token")) {
      accountInfo()
        .then((response) => {
          if (response.status === 200) {
            setCurrentUser(response.data.data);
          }
        })
        .catch((error) => {
          setCurrentUser(null);
          localStorage.removeItem("token");
          toast.error(error.response.data.message)
          return <Redirect to="/" />;
        });
      if (localStorage.getItem("company_uuid") !== "") {
        companyInfo(localStorage.getItem("company_uuid"))
          .then((response) => {
            if (response.status === 200) {
              setCurrentCompany(response.data.data);
            }
          })
          .catch((error) => {
            if(error.response.status === 403) {
              localStorage.removeItem("company_uuid");
              setCurrentCompany(null);
            }
          });
      }
    }
    
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayJs}>
      <CurrentUser.Provider value={{ currentUser, setCurrentUser }}>
        <CurrentCompany.Provider value={{ currentCompany, setCurrentCompany }}>
          <CurrentLanguage.Provider value={{ language, setLanguage }}>
            <CurrentTheme.Provider value={{ theme, setTheme }}>
              <BrowserRouter>
                <ApiInterceptors />
                <CookieConsent />
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route path="/register" component={Register} />
                  <Route path="/checkout" component={CheckoutRedirect} />
                  <Route path="/reset-password" component={PasswordReset} />
                  <Route path="/auth/password/new/:token" component={PasswordSet} />
                  <Route exact path="/auth/activate/account/:token" component={ActivateAccount} />
                  <ProtectedRoute path="/dashboard" component={Dashboard} />
                  <ProtectedRoute exact path="/servers" component={ServerList} />
                  <ProtectedRoute
                    exact
                    path="/servers/:serverUuid"
                    component={ServerDetails}
                  />
                  <ProtectedRoute exact path="/invoices" component={InvoiceList} />
                  <ProtectedRoute exact path="/vps" component={VmList} />
                  <ProtectedRoute
                    exact
                    path="/vps/:vmUuid"
                    component={VmDetails}
                  />
                  <ProtectedRoute exact path="/backupftp" component={BackupFTPList} />
                  <ProtectedRoute
                    exact
                    path="/backupftp/:backupftpUuid"
                    component={BackupFTPDetails}
                  />
                  <ProtectedRoute exact path="/messages" component={MessageList} />
                  <ProtectedRoute exact path="/messages/create" component={MessageCreate} />
                  <ProtectedRoute
                    exact
                    path="/messages/:ticketId"
                    component={MessageDetails}
                  />
                  <ProtectedRoute path="/account" component={Account} />
                  <ProtectedRoute 
                    path="/company/select"
                    component={CompanySelect}
                  />
                  <ProtectedRoute
                    path="/company/create"
                    component={CompanyCreate}
                  />
                  <ProtectedRoute
                    path="/companies/:companyUuid"
                    component={CompanyUpdate}
                  />
                  <ProtectedRoute exact path="/contracts" component={ContractList} />
                  <ProtectedRoute exact path="/payment/status" component={PaymentStatus} />
                  <ProtectedRoute
                    exact
                    path="/contracts/:contractUuid"
                    component={ContractDetails}
                  />
                  <ProtectedRoute exact path="/login-history" component={LoginLogList} />
                  <ProtectedRoute exact path="/verification" component={Verification} />
                  <ProtectedRoute exact path="/rodo/acceptance" component={RodoAcceptance} />
                  <ProtectedRoute exact path="/regulations/acceptance" component={RegulationsAcceptance} />
                  <Route path="*" component={Login} />
                </Switch>
              </BrowserRouter>
              <ToastContainer
                toastStyle={{ backgroundColor: theme == "dark" ? colors.authenticatedDark: "white" }}
                bodyClassName="dark:text-white text-navbarDark"
                position="bottom-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </CurrentTheme.Provider>
          </CurrentLanguage.Provider>
        </CurrentCompany.Provider>
      </CurrentUser.Provider>
    </LocalizationProvider>
  );
}

export default withTranslation()(App);
