import { colors } from "../../../colors";
import React, { useContext } from "react";
import Text from "../../Text";
import { CurrentTheme } from "../../../context/CurrentUser";

export default function NavbarItem({
    icon,
    Icon,
    text,
    onClick,
    endIcon,
    children,
    disableShadow,
    ...otherProps
}) {
	const themeContext = useContext(CurrentTheme);
    return (
        <button onClick={onClick}>
            <div className="p-4 flex flex-row items-center hover:opacity-90">
                {icon && <img className="px-2" src={icon}></img>}
                {Icon && <div className={`px-3 ` + (disableShadow ? '' : 'icon-shadow')}>{Icon}</div>}
                {text ? (<Text className="text-shadow" translate={text} color={colors.white} />) : children }
                {endIcon && endIcon}
            </div>
        </button>
    );
}
