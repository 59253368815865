import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { api } from '../../../../api';
import InnerCard from '../../../../components/InnerCard';
import Text from '../../../../components/Text'
import i18n from '../../../../i18n'

export default function VmPower({vm, getVm}) {

    const [isPowerPrompt, setIsPowerPrompt] = useState(false);
    const [isResetPrompt, setIsResetPrompt] = useState(false);

    const handlePowerChange = async () => {
        const operation = vm.power_status == 'running' ? "shutdown" : "start";
        await api
            .get(`/vm/${vm.uuid}/power/${operation}`)
            .then((response) => {
                if (response.status === 200) {
                    operation == 'off' 
                    ? toast.success(i18n.t('The request to turn off the VPS has been sent')) 
                    : toast.success(i18n.t('The request to turn on the VPS has been sent'))
                    setIsPowerPrompt(false);
                }
                setTimeout(() => getVm(), 20000)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleRestart = async () => {
        await api
            .get(`/vm/${vm.uuid}/power/reboot`)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t('The request to restart the VPS has been sent'))
                    setIsResetPrompt(false);
                    getVm();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };


    return (
        <InnerCard>
            <div className="flex justify-between items-center">
                {!isPowerPrompt &&
                    !isResetPrompt && (
                        <Text
                            variant="h7"
                            className="font-medium dark:text-white"
                            translate="Power"
                        />
                    )}
                {isPowerPrompt && (
                    <Text
                        variant="h7"
                        className="font-medium dark:text-gray-200"
                        translate={
                            "Are you sure you want to turn " +
                            (vm.power_status ==
                            1
                                ? "off"
                                : "on") +
                            " this VPS?"
                        }
                    />
                )}
                {isResetPrompt && (
                    <Text
                        variant="h7"
                        className="font-medium dark:text-gray-200"
                        translate="Are you sure you want to restart this VPS?"
                    />
                )}
                <div className="flex gap-3">
                    {!isPowerPrompt &&
                        !isResetPrompt && (
                            <>
                                <button
                                    className="text-white w-32 text-xs bg-secondary hover:opacity-90 px-5 py-2 rounded-full font-medium uppercase"
                                    onClick={() =>
                                        setIsResetPrompt(
                                            true
                                        )
                                    }
                                >
                                    {i18n.t('Restart')}
                                </button>
                                <button
                                    className="text-white w-32 text-xs bg-secondary hover:opacity-90 px-5 py-2 rounded-full font-medium uppercase"
                                    onClick={() =>
                                        setIsPowerPrompt(
                                            true
                                        )
                                    }
                                >
                                    {i18n.t(vm.power_status == 'running'
                                        ? "Turn off"
                                        : "Turn on")}
                                </button>
                            </>
                        )}
                    {isPowerPrompt && (
                        <>
                            <button
                                className="text-white w-32 text-xs bg-secondary hover:opacity-90 px-5 py-2 rounded-full font-medium uppercase"
                                onClick={
                                    handlePowerChange
                                }
                            >
                                {i18n.t(vm.power_status == 'running'
                                    ? "Turn off"
                                    : "Turn on")}
                            </button>
                            <button
                                className="text-white w-32 text-xs bg-powerOff px-5 py-2 rounded-full font-medium uppercase"
                                onClick={() =>
                                    setIsPowerPrompt(
                                        false
                                    )
                                }
                            >
                                {i18n.t('Cancel')}
                            </button>
                        </>
                    )}
                    {isResetPrompt && (
                        <>
                            <button
                                className="text-white w-32 text-xs bg-powerOff px-5 py-2 rounded-full font-medium uppercase"
                                onClick={() =>
                                    setIsResetPrompt(
                                        false
                                    )
                                }
                            >
                                {i18n.t('Cancel')}
                            </button>
                            <button
                                className="text-white w-32 text-xs bg-secondary hover:opacity-90 px-5 py-2 rounded-full font-medium uppercase"
                                onClick={
                                    handleRestart
                                }
                            >
                                {i18n.t('Restart')}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </InnerCard>
    )
}
