import React from 'react'
import Card from '../../../components/Card'
import IPTable from '../../../components/IPTable'
import Text from '../../../components/Text'

export default function VmNetworks({ipv4, ipv6}) {
    return (
        <div className="grid grid-cols-1">
            <Card variant="small" title="IPv4 Addressing">
                <IPTable rows={ipv4} />
            </Card>
            <Card variant="small" title="IPv6 Addressing">
                <IPTable rows={ipv6} />
            </Card>
        </div>
    )
}