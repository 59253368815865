import React, { useState } from "react";
import { toast } from "react-toastify";
import { api } from "../api";
import i18n from "../i18n";

function getFileNameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;

    const match = contentDisposition.match(/filename="?([^"]+)"?/);

    return match ? match[1] : null;
}

/**
 * https://stackoverflow.com/questions/58630869/download-file-from-express-api-using-react-and-axios
 */
export default function Download({ url, children, className, ...otherProps }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadClick = () => {
        api.get(url, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/octet-stream",
            },
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: response.headers["content-type"],
                    })
                );

                const actualFileName = getFileNameFromContentDisposition(
                    response.headers["content-disposition"]
                );

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", actualFileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                toast.error(i18n.t("Couldn't download this file!"));
            });
    };

    return (
        <div
            className={`cursor-pointer ${className}`}
            onClick={handleDownloadClick}
        >
            {children}
        </div>
    );
}
