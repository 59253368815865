import React from "react";
import Text from "../../../components/Text";
import ReplyIcon from '@mui/icons-material/Reply';
import MessageIcon from '@mui/icons-material/Message';

export default function Message({message}) {
    return (
        <div className={(message.SenderType == 'customer' ? 'items-end' : 'items-start') + " flex flex-col"}>
            <div className="flex flex-row gap-1 items-center justify-between">
                <span className="flex flex-row mb-2 items-center gap-1 font-medium text-black dark:text-white flex-grow">
                {message.SenderType == 'customer' ? (
                    <>
                        <MessageIcon className="text-gray-500 text-sm dark:text-gray-400"/>
                        <Text variant="h7" className="text-gray-400 dark:text-gray-400 text-sm" translate="Message from"/>
                    </>
                ) : (
                    <>
                        <ReplyIcon size="sm" className="text-gray-500 text-sm dark:text-gray-400"/>
                        <Text variant="h7" className="text-gray-400 dark:text-gray-400 text-sm" translate="Reply from"/>
                    </>
                )}
                <Text variant="h7" className="text-gray-500 dark:text-gray-200 text-sm">{message.FromRealname}</Text>
                </span>
            </div>
            <div className="p-5 bg-authenticated dark:bg-authenticated-dark dark:text-white rounded-lg flex flex-col w-1/2">
                <span className="font-medium truncate dark:text-white text-black whitespace-pre-line">
                    {message.Body}
                </span>
            </div>
            <span className="font-medium text-sm mt-2 flex items-center text-gray-400 dark:text-white flex-grow">
                {message.Created}
            </span>
        </div>
    );
}
