import { Button, MenuItem, Paper, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { toast } from "react-toastify";
import { api } from "../../api";
import Input from "../../components/Input";
import Text from "../../components/Text";
import { CurrentUser } from "../../context/CurrentUser";
import i18n from "../../i18n";

export default function ChangePhone() {
    const userContext = useContext(CurrentUser);
    const history = useHistory();
    const [dialCodes, setDialCodes] = useState([]);
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("+48");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        api.get("/dial-code", {
            params: {
                supported: true,
            },
        })
            .then((response) => {
                setDialCodes(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(async () => {
        setPhoneNumber(userContext?.currentUser?.phone_number ?? "");
        setCode(userContext?.currentUser?.phone_prefix ?? "");
    }, [userContext.currentUser]);

    const handleChangePhone = (e) => {
        e.preventDefault();

        api.put("/account/phone", {
            password,
            phone: code + " " + phoneNumber,
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(i18n.t('Phone number changed successfully'))
                    userContext.setCurrentUser({
                        ...userContext.currentUser,
                        phone_number: phoneNumber,
                        phone: code+' '+phoneNumber,
                        phone_prefix: code
                    });
                    history.push("/dashboard");
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
        <form
            method="POST"
            onSubmit={handleChangePhone}
            className="flex flex-col w-full gap-5 mt-10"
        >
            {/*
                * This is hidden for user, but auto-filled by web browser,
                * without this the Phone field is autofilled to be email address by a browser
                */}
            <div className="hidden">
                <Input
                    className="hide"
                    name="email"
                    label={i18n.t("Email")}
                    variant="outlined"
                    value={email}
                    color="primary"
                    onChange={(e) => setEmail(e.target.value)}
                    error={errors.email ? true : false}
                    helperText={errors.email ?? false}
                />
            </div>
            <div className="flex justify-between flex-col md:flex-row gap-3">
                <Input
                    name="phoneCode"
                    variant="outlined"
                    label={i18n.t("Phone prefix")}
                    value={code}
                    select
                    color="primary"
                    onChange={(e) => setCode(e.target.value)}
                    className="w-full md:w-1/3"
                >
                    {dialCodes.map((dialCode) => (
                        <MenuItem
                            key={dialCode.code}
                            value={dialCode.dial_code}
                        >
                            {dialCode.code + " " + dialCode.dial_code}
                        </MenuItem>
                    ))}
                </Input>
                <Input
                    name="phoneNumber"
                    label={i18n.t("Phone")}
                    variant="outlined"
                    color="primary"
                    className="w-full md:w-2/3"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={errors.phone ? true : false}
                    helperText={errors.phone ?? false}
                />
            </div>
            <Input
                name="password"
                label={i18n.t("Password")}
                variant="outlined"
                type="password"
                value={password}
                color="primary"
                onChange={(e) => setPassword(e.target.value)}
                error={errors.password ? true : false}
                helperText={errors.password ?? false}
            />
            <div className="flex flex-row justify-between items-center mt-5">
                <Button color="primary" size="large" variant="contained" type="submit">
                    <Text translate="Change phone number" />
                </Button>
            </div>
        </form>
    );
}
