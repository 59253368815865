import { Button } from "@mui/material";
import React, { useState } from "react";
import Text from "../../../components/Text";
import Input from "../../../components/Input";
import SendIcon from "@mui/icons-material/Send";
import i18n from "../../../i18n";
import { api } from "../../../api";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Cancel as CancelIcon } from "@mui/icons-material";

export default function MessageAnswer({ TicketID, getTicket }) {
    const [message, setMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const data = new FormData();
        data.append('message', message)
        for (const key of Object.keys(uploadedFiles)) {
            data.append('uploaded_files[]', uploadedFiles[key])
        }
        await api.post(`/message/${TicketID}`, data, {
            params: {
                "_method": "PUT" //wiem że pojebane, ale tak musi być :/
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false)
                    setErrors([]);
                    setMessage("");
                    setUploadedFiles([])
                    getTicket()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setErrors(error?.response?.data?.errors ?? []);
            });
    };

    return (
            <form
                method="POST"
                onSubmit={handleCreate}
                className="flex flex-col px-1 pb-3"
            >
                <Input
                    name="message"
                    label={i18n.t("Message")}
                    variant="outlined"
                    multiline
                    value={message}
                    rows={5}
                    color="primary"
                    onChange={(e) => setMessage(e.target.value)}
                    error={errors.message ?? false}
                    helperText={errors.message ?? false}
                />
                <label
                    className="mt-5 w-100 flex flex-col items-center px-4 py-3 bg-white dark:bg-navbarDark dark:border-gray-200 rounded-md shadow-md dark:text-gray-200 dark:hover:bg-gray-200 dark:hover:text-navbarDark tracking-wide uppercase border cursor-pointer hover:bg-secondary hover:text-white text-secondary ease-linear transition-all duration-150">
                    <CloudUploadIcon/>
                    <span className="mt-2 text-base leading-normal">{i18n.t('Include pictures')}</span>
                    <input type="file" 
                        className="hidden"
                        multiple
                        onChange={(e) => setUploadedFiles(e.target.files)}
                    />
                </label>
                <div className="flex flex-wrap">
                    {
                        uploadedFiles.length ? Array.from(uploadedFiles).map((file, i) => {
                            const url = URL.createObjectURL(file)
                            return <img key={i} src={url} className="w-1/4 p-2" alt="uploaded file"/>
                        }) : null
                    }
                </div>
                {
                    uploadedFiles.length ? 
                    <button className="flex items-center gap-1 text-powerOff" onClick={() => setUploadedFiles([])}>
                        <CancelIcon/>
                        <Text variant="h7" translate="Clear" className="hover:opacity-90"/>
                    </button> 
                    : null
                }
                <div className="flex flex-row justify-start items-center mt-5">
                    <LoadingButton
                        endIcon={<SendIcon />}
                        loading={isLoading}
                        loadingPosition="end"
                        variant="contained"
                        type="submit"
                    >
                        <Text translate="Send" />
                    </LoadingButton>
                </div>
            </form>
    );
}
