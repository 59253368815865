import { Button, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../api";
import Card from "../components/Card";
import Input from "../components/Input";
import Authenticated from "../components/layout/Authenticated";
import Text from "../components/Text";
import { CurrentCompany } from "../context/CurrentUser";
import i18n from "../i18n";

export default function CompanyCreate() {
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);
    const [nip, setNip] = useState("");
    const [vat_number, setVatNumber] = useState("");
    const [name, setName] = useState("");
    const [country_id, setCountryId] = useState(518);
    const [postal_code, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [street_hn, setStreetHn] = useState("");
    const [street_ln, setStreetLn] = useState("");
    const history = useHistory();
    const companyContext = useContext(CurrentCompany);

    useEffect(() => {
        api.get("/country", {
            params: {
                supported: true,
            },
        })
            .then((response) => {
                setCountries(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleCompanyCreate = (e) => {
        e.preventDefault();
        api.post("/company", {
            country_id,
            nip,
            vat_number,
            name,
            postal_code,
            city,
            street,
            street_hn,
            street_ln,
        })
            .then((response) => {
                if (response.status === 201) {
                    companyContext.setCurrentCompany(response.data.data);
                    localStorage.setItem(
                        "company_uuid",
                        response.data.data.uuid
                    );
                    return history.push("/dashboard");
                }
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors ?? []);
            });
    };
    return (
        <Authenticated>
            <div className="text-2xl font-medium flex justify-center items-center dark:text-white mt-5 mb-8">
                <h2 className="p-1">{i18n.t('Add company')}</h2>
            </div>
            <div className="flex flex-col lg:flex-row justify-center">
                <Card title="Add new company" className="lg:w-1/2 rounded-lg">
                    <form
                        method="POST"
                        onSubmit={handleCompanyCreate}
                        className="flex flex-col w-full gap-5 mt-5"
                    >
                        <Input
                            name="nip"
                            label={i18n.t("NIP")}
                            variant="outlined"
                            type="text"
                            value={nip}
                            color="primary"
                            onChange={(e) => setNip(e.target.value)}
                            error={errors.nip ?? false}
                            helperText={errors.nip ?? false}
                        />
                        <Input
                            name="vat_number"
                            label={i18n.t("VATUE or VIES")}
                            variant="outlined"
                            type="text"
                            value={vat_number}
                            color="primary"
                            onChange={(e) => setVatNumber(e.target.value)}
                            error={errors.vat_number ?? false}
                            helperText={errors.vat_number ?? false}
                        />
                        <Input
                            name="name"
                            label={i18n.t("Company name")}
                            variant="outlined"
                            type="text"
                            value={name}
                            color="primary"
                            onChange={(e) => setName(e.target.value)}
                            error={errors.name ?? false}
                            helperText={errors.name ?? false}
                        />
                        <Input
                            name="country"
                            label={i18n.t("Country")}
                            variant="outlined"
                            value={country_id}
                            select
                            color="primary"
                            onChange={(e) => setCountryId(e.target.value)}
                        >
                            {countries.map((countryItem) => (
                                <MenuItem
                                    key={countryItem.id}
                                    value={countryItem.id}
                                >
                                    {countryItem.name}
                                </MenuItem>
                            ))}
                        </Input>
                        <div className="flex justify-between flex-row gap-3">
                            <Input
                                name="postal_code"
                                label={i18n.t("Postal code")}
                                variant="outlined"
                                color="primary"
                                className="w-1/3"
                                value={postal_code}
                                onChange={(e) =>
                                    setPostalCode(e.target.value)
                                }
                                error={errors.postal_code ?? false}
                                helperText={errors.postal_code ?? false}
                            />
                            <Input
                                name="city"
                                label={i18n.t("City")}
                                variant="outlined"
                                color="primary"
                                className="w-2/3"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                error={errors.city ?? false}
                                helperText={errors.city ?? false}
                            />
                        </div>
                        <div className="flex justify-between flex-row gap-3">
                            <Input
                                name="street"
                                label={i18n.t("Street name")}
                                variant="outlined"
                                color="primary"
                                className="w-3/5"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                error={errors.street ?? false}
                                helperText={errors.street ?? false}
                            />
                            <Input
                                name="street_hn"
                                label={i18n.t("Building number")}
                                variant="outlined"
                                color="primary"
                                className="w-1/5"
                                value={street_hn}
                                onChange={(e) =>
                                    setStreetHn(e.target.value)
                                }
                                error={errors.street_hn ?? false}
                                helperText={errors.street_hn ?? false}
                            />
                            <Input
                                name="street_ln"
                                label={i18n.t("Apartment number")}
                                variant="outlined"
                                color="primary"
                                className="w-1/5"
                                value={street_ln}
                                onChange={(e) =>
                                    setStreetLn(e.target.value)
                                }
                                error={errors.street_ln ?? false}
                                helperText={errors.street_ln ?? false}
                            />
                        </div>

                        <div className="flex flex-row justify-between items-center mt-5">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                {i18n.t("Create company")}
                            </Button>
                        </div>
                    </form>
                </Card>
            </div>
        </Authenticated>
    );
}
