import { Typography } from "@mui/material";
import i18n from "../i18n";
import React from "react";
export default function Text({ translate, children, ...otherProps }) {
    return (
        <Typography {...otherProps}>
            {children ? children : i18n.t(translate)}
        </Typography>
    );
}
